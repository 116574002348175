import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { IWorkflowCameraTagElement } from 'lib/modules/qualieApi/entities/workflow';
import IWorkflowElementProps from '../../IWorkflowElementProps';
import { useMountEffect } from 'app/hooks';
import { v4 } from 'uuid';
import RecordProgress from './recordProgress';
import { Button, Spin } from 'antd';
import { useIntl } from 'react-intl';
import { CommencedWorkflowContext } from 'app/modules/pux/contexts';

const DEFAULT_MIN_LENGTH = (Number)(process.env.REACT_APP_MIN_RECORD_TIME);

const CameraTagWorkflowElement: React.FunctionComponent<IWorkflowElementProps<IWorkflowCameraTagElement>> = (props) => {
  const { workflowElement, onFinish } = props;
  const camera = workflowElement.cameraTagOptions.camera;
  const waitForProcessing = workflowElement.cameraTagOptions.waitInPuxForVideoProcessingComplete != null
    ? workflowElement.cameraTagOptions.waitInPuxForVideoProcessingComplete
    : true;
  const intl = useIntl();
  const { uiTexts } = useContext(CommencedWorkflowContext);
  const startRecordingLabel = camera?.startRecordingText || ((uiTexts?.WORKFLOW_ELEMENT_CAMERATAG_ACTIONS_RECORD_LABEL) ? intl.formatMessage({
    id:  'NOT_DEFINED',
    defaultMessage: uiTexts?.WORKFLOW_ELEMENT_CAMERATAG_ACTIONS_RECORD_LABEL?.value,
  }) : intl.formatMessage({ id: 'workflowElement.cameraTag.actions.record.label' }));
  const minLength = useMemo(() => camera && camera.minLength != null ? (Number)(camera.minLength) : DEFAULT_MIN_LENGTH, [camera]);
  const maxLength = useMemo(() => (Number)(camera.limit), [camera]);
  const id = useMemo(() => v4(), []);
  const cameratagStartScreenId = `${id}-start-screen`;
  const cameratagMobileStartScreenId = `${id}-mobile-start-screen`;
  const cameratagUploadScreenId = `${id}-upload-screen`;
  const cameratagWaitScreenId = `${id}-wait-screen`;
  const cameratagCompletedScreenId = `${id}-completed-screen`;
  const [publishedPayload, setPublishedPayload] = useState<any>();
  const [processedPayload, setProcessedPayload] = useState<any>();
  const [isRecording, setIsRecording] = useState(false);
  const [recordTime, setRecordTime] = useState(0);
  const [revealProgress, setRevealProgress] = useState(false);
  const { flags } = useContext(CommencedWorkflowContext);

  const onProcessed = useCallback((e) => {
    if (waitForProcessing) {
      setProcessedPayload(e);
    }
  }, [waitForProcessing]);
  const onPublished = useCallback((e) => {
    setPublishedPayload(e);
    if (!waitForProcessing) {
      setProcessedPayload(e);
    }
  }, [waitForProcessing]);
  const onRecordingStarted = useCallback((e) => {
    setIsRecording(true);
    setRecordTime(0);
  }, []);
  const onRecordingStopped = useCallback((e) => {
    setIsRecording(false);
    setRecordTime(maxLength);
  }, [maxLength]);
  const onContainerClick = useCallback(() => {
    if (isRecording && recordTime < minLength) {
      setRevealProgress(true);
    }
  }, [isRecording, recordTime, minLength]);

  useMountEffect(() => {
    window.CameraTag?.setup();
  });

  useEffect(() => {
    window.CameraTag?.observe(id, 'published', onPublished);
    window.CameraTag?.observe(id, 'processed', onProcessed);
    window.CameraTag?.observe(id, 'recordingStarted', onRecordingStarted);
    window.CameraTag?.observe(id, 'recordingStopped', onRecordingStopped);

    return () => {
      window.CameraTag?.stopObserving(id, 'published', onPublished);
      window.CameraTag?.stopObserving(id, 'processed', onProcessed);
      window.CameraTag?.observe(id, 'recordingStarted', onRecordingStarted);
      window.CameraTag?.observe(id, 'recordingStopped', onRecordingStopped);
    };
  }, [
    id,
    onPublished,
    onProcessed,
    onRecordingStarted,
    onRecordingStopped,
  ]);

  useEffect(() => {
    if (onFinish && publishedPayload && processedPayload) {
      onFinish(workflowElement, {
        uuid: publishedPayload.uuid,
        medias: publishedPayload.medias,
        url: publishedPayload.medias.mp4,
      });
    }
  }, [processedPayload, publishedPayload, onFinish, workflowElement]);

  useEffect(() => {
    if (!isRecording) {
      return;
    }

    const interval = setInterval(() => {
      setRecordTime(value => value + 1);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [isRecording]);

  const dataSources = camera.dataSources + ((flags.enableUpload) ? ',upload' : '');
  const dataUploadOnSelect = (flags.enableUpload) ? 'true' : camera.dataUploadOnSelect;

  return (
    <div
      className="camera-tag-container"
      onClick={onContainerClick}
    >
      <camera
        is="div"
        id={id}
        data-app-id={camera.id}
        data-sources={dataSources}
        data-pre-roll-length={camera.preRollLength}
        data-maxlength={maxLength}
        data-minlength={minLength}
        data-upload-on-select={dataUploadOnSelect}
        data-publish-on-upload={camera.dataPublishOnUpload}
        data-aspect-ratio="4:3"
        style={{
          width: '100%',
          height: '100%',
        }}
      />
      {!flags.enableUpload && (
        <div
          id={cameratagStartScreenId}
          className="camera-tag-overlay-actions"
        >
          <div className="camera-tag-overlay-actions-inner">
            <Button
              type="primary"
              className="cameratag_record"
            >{startRecordingLabel}</Button>
          </div>
        </div>
      )}
      <div
        id={cameratagMobileStartScreenId}
        className="camera-tag-overlay-actions"
      >
        <div className="camera-tag-overlay-actions-inner">
          <Button
            type="primary"
            className="cameratag_record"
          >{startRecordingLabel}</Button>
        </div>
      </div>
      <div
        id={cameratagUploadScreenId}
        className="camera-tag-overlay-actions"
      >
        <div className="camera-tag-overlay-actions-inner">
          <Spin size="large" />
        </div>
      </div>
      <div
        id={cameratagWaitScreenId}
        className="camera-tag-overlay-actions"
      >
        <div className="camera-tag-overlay-actions-inner">
          <Spin size="large" />
        </div>
      </div>
      <div
        id={cameratagCompletedScreenId}
        className="camera-tag-overlay-actions"
        style={{ display: 'block' }}
      >
        <div className="camera-tag-overlay-actions-inner">
          <Spin size="large" />
        </div>
      </div>
      {revealProgress && (
        <RecordProgress
          min={minLength}
          max={maxLength}
          value={recordTime}
        />
      )}
    </div>
  );
};

export default CameraTagWorkflowElement;