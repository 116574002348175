import { Dictionary } from 'lib/types';
import { createRequestContext, euc, toUrl } from '../config';
import { IParticipant } from '../entities/participant';
import { ICommencedWorkflow, IReviewImage, IReviewText, IReviewVideo, IWorkflowActivity } from '../entities/workflow';
import Http from '../http';

/**
 * Function to prefix action URLs with /core to ensure they are compatible with Gateway
 * while still making their way to the Core API.
 */
const prefixActionUrl = (actionUrl: string): string => actionUrl.startsWith('/core/') ? actionUrl : `/core${actionUrl}`;

const Workflow = {
  CommenceWorkflow: async (
    workflowId: string,
    options?: { flowName?: string, pp?: string, cookie?: boolean, enableUpload?: boolean, referrer?: string, activityHash?: string }
  ) => createRequestContext<ICommencedWorkflow>(async (config) => Http.GET(toUrl({
    url: `${config.hosts.gateway}/core/api/workflow/commence-workflow/${euc(workflowId)}`,
    query: options,
  }))),

  SubmitFormElement: async (
    actionUrl: string,
    payload: Dictionary
  ) => createRequestContext<{ code: string, data: any, message?: string }>(async (config) => Http.POST(toUrl({ url: `${config.hosts.gateway}${prefixActionUrl(actionUrl)}` }), payload)),

  CreateParticipant: async (
    actionUrl: string,
    payload: Dictionary
  ) => createRequestContext<{ code: string, data: IParticipant, message?: string }>(async (config) => Http.POST(toUrl({ url: `${config.hosts.gateway}${prefixActionUrl(actionUrl)}` }), payload)),

  GetNextWorkflowActivity: async (
    workflowId: string,
    participantId: string
  ) => createRequestContext<{ code: string, data: IWorkflowActivity, message?: string }>(async (config) => Http.GET(toUrl({ url: `${config.hosts.gateway}/core/api/workflow/next-workflow-activity/${workflowId}/${participantId}` }))),

  GetNextReviewVideo: async (
    nextVideoUrl: string
  ) => createRequestContext<{ code: string, data: IReviewVideo | null, message?: string }>(async (config) => Http.GET(toUrl({ url: `${config.hosts.gateway}${prefixActionUrl(nextVideoUrl)}` }))),

  GetNextReviewText: async (
    nextVideoUrl: string
  ) => createRequestContext<{ code: string, data: IReviewText[] | null, message?: string }>(async (config) => Http.GET(toUrl({ url: `${config.hosts.gateway}${prefixActionUrl(nextVideoUrl)}` }))),

  GetNextReviewImage: async (
    nextImageUrl: string
  ) => createRequestContext<{ code: string, data: IReviewImage[] | null, message?: string }>(async (config) => Http.GET(toUrl({ url: `${config.hosts.gateway}${prefixActionUrl(nextImageUrl)}` }))),

  AdvanceParticipant: async (
    workflowId: string,
    participantId: string
  ) => createRequestContext<{ code: string, data: string, message: string }>(async (config) => Http.POST(toUrl({ url: `${config.hosts.gateway}/core/api/workflow/project/${workflowId}/advance/${participantId}` }), {})),

  AdvanceParticipantInTest: async (
    workflowId: string,
    participantId: string
  ) => createRequestContext<{ code: string, data: string, message: string }>(async (config) => Http.POST(toUrl({ url: `${config.hosts.gateway}/core/api/workflow/project/${workflowId}/advance-in-test/${participantId}` }), {})),

  RetreatParticipantInTest: async (
    workflowId: string,
    participantId: string
  ) => createRequestContext<{ code: string, data: string, message: string }>(async (config) => Http.POST(toUrl({ url: `${config.hosts.gateway}/core/api/workflow/project/${workflowId}/retreat-in-test/${participantId}` }), {})),

  UploadParticipantVideo: async (
    projectHash: string,
    participantHash: string,
    video: Blob,
    onProgress?: (progress: number, total: number) => void
  ) => createRequestContext<{ code: string, data: string, message: string }>(async (config) => {
    const form = new FormData();
    form.append('participantHash', participantHash);
    form.append('videoFile', video, 'video.webm');

    return Http.MULTIPART(
      toUrl({
        url: `${config.hosts.gateway}/core/api/workflow/project/${euc(projectHash)}/qualieCam-uploadParticipantVideo`,
      }),
      form,
      onProgress
    );
  }),

  UploadParticipantImage: async (
    projectHash: string,
    participantHash: string,
    image: Blob,
    onProgress?: (progress: number, total: number) => void
  ) => createRequestContext<{ code: string, data: string, message: string }>(async (config) => {
    const form = new FormData();
    form.append('participantHash', participantHash);
    form.append('imageFile', image);

    return Http.MULTIPART(
      toUrl({
        url: `${config.hosts.gateway}/core/api/workflow/project/${euc(projectHash)}/imageUpload`,
      }),
      form,
      onProgress
    );
  }),
};

export default Workflow;
