import React from 'react';
import ReactMarkdown from 'react-markdown';

const WorkflowActivityTitle: React.FunctionComponent = ({ children }) => (
  <h1 className="workflow-activity-title">
    <ReactMarkdown>{children as string}</ReactMarkdown>
  </h1>
);

export default WorkflowActivityTitle;
