import { IWorkflowFormElementElement } from 'lib/modules/qualieApi/entities/workflow';
import React from 'react';
import { WORKFLOW_ELEMENT_FORM_ELEMENT_TYPES } from '../components/workflowElement/types/form/formElement/types';

interface IFormElementContext {
  typeMapper?: (element: IWorkflowFormElementElement) => WORKFLOW_ELEMENT_FORM_ELEMENT_TYPES | undefined;
}

const FormElementContext = React.createContext<IFormElementContext>({});

export type {
  IFormElementContext,
};

export default FormElementContext;
