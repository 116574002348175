import { createSlice } from '@reduxjs/toolkit';

export const reverseModeSlice = createSlice({
  name: 'reverseMode',
  initialState: {reverseMode: false},
  reducers: {
    setReverseMode(state, action) {
      state.reverseMode = action.payload;
    },
  },
});

export const { setReverseMode } = reverseModeSlice.actions;

export const selectReverseMode = (state: any) => state.reverseMode;

export default reverseModeSlice.reducer;
