import { ApiRequest, ApiResponse, REQUEST_METHODS } from './types';

const sendRequest = async (request: ApiRequest): Promise<Response> => {
  const response = await fetch(
    request.url,
    {
      method: request.method,
      headers: request.headers,
      body: request.body,
    });

  return response;
};

const parseResponse = async (response: Response): Promise<ApiResponse> => {
  let body: any;

  try {
    body = await response.json();
  } catch (e) {
    body = await response.text();
  }

  return {
    body: body,
    status: response.status,
    statusText: response.statusText,
    ok: response.ok,
  };
};

const Http = {
  GET: async (url: string) => {
    const response = await sendRequest({
      method: REQUEST_METHODS.GET,
      url: url,
    });

    return parseResponse(response);
  },
  POST: async (url: string, body: any) => {
    const response = await sendRequest({
      method: REQUEST_METHODS.POST,
      url: url,
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return parseResponse(response);
  },
  MULTIPART: (url: string, form: FormData, onProgress?: (progress: number, total: number) => void) => (new Promise<ApiResponse>((res, rej) => {
    const request = new XMLHttpRequest();
    request.upload.addEventListener('progress', (event) => {
      if (event.lengthComputable) {
        onProgress?.(event.loaded, event.total);
      }
    });
    request.addEventListener('load', (event) => {
      let body: any;

      try {
        body = JSON.parse(request.response);
      } catch (e) {
        body = request.responseText;
      }

      res({
        ok: request.status >= 200 && request.status < 400,
        body: body,
        status: request.status,
        statusText: request.statusText,
      });
    });
    request.addEventListener('error', (event) => {
      let body: any;

      try {
        body = JSON.parse(request.response);
      } catch (e) {
        body = request.responseText;
      }

      rej({
        ok: false,
        body: body,
        status: request.status,
        statusText: request.statusText,
      });
    });
    request.open(REQUEST_METHODS.POST, url, true);
    request.send(form);
  })),
};

export default Http;
