import _ from 'lodash/fp';
import { IWorkflowFormElementElement } from 'lib/modules/qualieApi/entities/workflow';
import React, { useMemo } from 'react';
import { IWorkflowFormElementProps } from './types';
import { Form, Radio } from 'antd';
import cx from 'classnames';
import { useIsMobile } from 'app/hooks';

const GenderRadioFormElement: React.FunctionComponent<IWorkflowFormElementProps<IWorkflowFormElementElement>> = (props) => {
  const { workflowElement, path } = props;
  const name = useMemo(() => path ? [...path, workflowElement.name] : [workflowElement.name], [path, workflowElement]);
  const sortedOptions = useMemo(() => _.sortBy('position', workflowElement.options), [workflowElement.options]);
  const isMobile = useIsMobile();
  const className = useMemo(() => cx({
    'gender-radio-form-element': true,
    'mobile-layout': isMobile,
  }), [isMobile]);

  return (
    <Form.Item
      label={workflowElement.label}
      className={className}
      name={name}
      rules={[
        {
          required: false,
        },
      ]}
    >
      <Radio.Group className="gender-radio-form-group">
        {sortedOptions.map(option => (
          <Radio.Button
            key={option.value}
            value={option.key}
          >{option.label}</Radio.Button>
        ))}
      </Radio.Group>
    </Form.Item>
  );
};

export default GenderRadioFormElement;
