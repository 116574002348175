import { Button, Spin } from 'antd';
import { useAppDispatch, useMountEffect } from 'app/hooks';
import { CommencedWorkflowContext } from 'app/modules/pux/contexts';
import { useKeyedWorkflowElements } from 'app/modules/pux/hooks';
import { workflowsActions } from 'app/modules/workflows';
import { IReviewImage, IWorkflowCarouselElement, IWorkflowElement, IWorkflowFormElement, IWorkflowInitiativeElement } from 'lib/modules/qualieApi/entities/workflow';
import Utils from 'lib/utils';
import _ from 'lodash/fp';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { PrerequisiteError, WorkflowElement } from '../../..';
import { WorkflowActivitySubtitle, WorkflowActivityTitle } from '../../../content';
import BasicWorkflowForm from '../../../layout/basicWorkflowForm';
import FormActions from '../../../layout/formActions';
import SplitWorkflowForm from '../../../layout/splitWorkflowForm';
import BannerWorkflowElement from '../../../workflowElement/types/contentBanner';
import IWorkflowActivityProps from '../../IWorkflowActivityProps';
import WorkflowActivityAdvanceRetreat from '../../../content/workflowActivityTestAdvanceRetreat';
import RequiredViewingBlock from './requiredViewingBlock';

type Elements = {
  title: IWorkflowElement;
  subTitle: IWorkflowElement;
  initiatives: IWorkflowInitiativeElement;
  explainer: IWorkflowCarouselElement;
  explainerButtonText: IWorkflowElement;
  nextImageURL: IWorkflowElement;
  reviewImageOpinionForm: IWorkflowFormElement;
  nextButton: IWorkflowElement;
  showAdvanceRetreat: IWorkflowElement;
  showAdvance: IWorkflowElement;
  showRetreat: IWorkflowElement;
};

const NEXT_TIMEOUT = 2000;

const ReviewImageWorkflowActivity: React.FunctionComponent<IWorkflowActivityProps> = (props) => {
  const { workflowActivity } = props;
  const dispatch = useAppDispatch();
  const elements = useKeyedWorkflowElements<Elements>(workflowActivity.workflowElements);
  const { setPrerequisite, uiTexts, checkPrerequisites, advance, prerequisites, onReady, advanceInTest, retreatInTest } = useContext(CommencedWorkflowContext);
  const [explainerValid, setExplainerValid] = useState(!elements.explainer);
  const [reviewImage, setReviewImage] = useState<IReviewImage>();
  const showExplainer = !explainerValid && !!elements.explainer;
  const explainers = useMemo(() => _.sortBy('position', elements.explainer?.carouselHtmlElements || []), [elements]);
  const stimuli = useMemo(() => {
    const initiative = (elements.initiatives?.collection || [])[0] as any;

    return _.sortBy('position', initiative?.stimuli || []);
  }, [elements]);

  /**
   * A modified version of the opinion form where we replace the rating options
   * with those supplied by the review text.
   */
  const reviewImageOpinionForm: IWorkflowFormElement = useMemo(() => ({
    ...elements.reviewImageOpinionForm,
    elements: elements.reviewImageOpinionForm.elements.map(element => {
      switch (element.name) {
        case 'rating':
          return {
            ...element,
            options: reviewImage?.ratings || element.options,
          };
        /**
         * Need to inject the current opinion hash into the activity so the form
         * uses that value in the input for submission.
         */
        case 'opinionHash':
          return {
            ...element,
            value: reviewImage?.opinionActivity.hash,
          };
        default:
          return element;
      }
    }),
  }), [elements.reviewImageOpinionForm, reviewImage]);

  const onExplainerValid = useCallback((element: IWorkflowElement, valid: boolean, errorMessage?: string) => {
    setPrerequisite(element.name, {
      reveal: false,
      valid: valid,
      error: errorMessage || uiTexts?.VIEW_ALL_CONTENT.value,
    });
  }, [setPrerequisite, uiTexts]);

  const onExplainerFinish = useCallback(() => {
    if (checkPrerequisites()) {
      setExplainerValid(true);
    }
  }, [checkPrerequisites]);

  const onViewingValid = useCallback((valid: boolean) => {
    setPrerequisite('requiredViewing', {
      reveal: false,
      valid: valid,
      error: uiTexts?.VIEW_ALL_CONTENT.value,
    });
  }, [setPrerequisite, uiTexts]);

  const onNextImage = useCallback(async (wait?: number) => {
    setReviewImage(undefined);
    if (wait != null) {
      await Utils.wait(wait);
    }
    if (elements?.nextImageURL?.value) {
      const result = await dispatch(workflowsActions.getNextReviewImage({ nextImageUrl: elements.nextImageURL.value })).unwrap();
      if (result.data == null || !result.data?.length) {
        (elements.showAdvanceRetreat?.value === 'true') ? advanceInTest() : advance();
      } else {
        setReviewImage(result.data[0]);
        Utils.scrollToTop();
        onReady();
      }
    }
  }, [elements, dispatch, advance, onReady, advanceInTest]);

  const onNextImageForForm = useCallback(() => {
    onNextImage(NEXT_TIMEOUT);
  }, [onNextImage]);

  useMountEffect(() => {
    onNextImage();
  });

  return (
    <SplitWorkflowForm focus="empty">
      <SplitWorkflowForm.Filled>
        <WorkflowActivityTitle>{elements.title.value}</WorkflowActivityTitle>
        <WorkflowActivitySubtitle>{elements.subTitle.value}</WorkflowActivitySubtitle>
        {elements.showAdvanceRetreat?.value === 'true' &&
          <WorkflowActivityAdvanceRetreat
            onAdvance={advanceInTest}
            onRetreat={retreatInTest}
            allowAdvance={elements.showAdvance?.value === 'true'}
            allowRetreat={elements.showRetreat?.value === 'true'}
          />}
      </SplitWorkflowForm.Filled>
      <SplitWorkflowForm.Empty
        header={!showExplainer && !!elements.explainer && !!reviewImage && (
          <BannerWorkflowElement
            explainers={explainers}
            stimuli={stimuli}
          />
        )}
      >
        {!reviewImage && <Spin size="large" />}
        {showExplainer && !!reviewImage && (
          <React.Fragment>
            <WorkflowElement
              workflowElement={elements.explainer}
              onValid={onExplainerValid}
            />
            <PrerequisiteError target="explainer" />
            <FormActions>
              <Button
                type="primary"
                onClick={onExplainerFinish}
              >{elements.explainerButtonText.value}</Button>
            </FormActions>
          </React.Fragment>
        )}
        {!showExplainer && !!reviewImage && (
          <React.Fragment>
          <BasicWorkflowForm size="large">
            <PrerequisiteError target="requiredViewing" />
            <RequiredViewingBlock
              imageUrl={reviewImage.imageFile.sourceUri}
              title={reviewImage.title}
              onValid={onViewingValid}
              reveal={prerequisites?.requiredViewing?.reveal}
            />
            <WorkflowElement
              workflowElement={reviewImageOpinionForm}
              onFinish={onNextImageForForm}
              onBeforeFinish={checkPrerequisites}
            />
          </BasicWorkflowForm>
        </React.Fragment>
        )}
      </SplitWorkflowForm.Empty>
    </SplitWorkflowForm>
  );
};

export default ReviewImageWorkflowActivity;
