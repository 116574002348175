import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

const DEFAULT_REFRESH_RATE_MS = 1000 / 24;
const DEFAULT_RESOLUTION = 64;
const DEFAULT_SILENCE_SAMPLE_THRESHOLD = 25;
const DEFAULT_SILENCE_SAMPLE_DURATION = 5000;

const useAudioLevels = (mediaStream: MediaStream | null | undefined, refreshRateMs: number = DEFAULT_REFRESH_RATE_MS, resolution: number = DEFAULT_RESOLUTION) => {
  const audioContext = useMemo(() => new AudioContext(), []);
  const mediaStreamSource = useRef<MediaStreamAudioSourceNode>();
  const analyser = useRef<AnalyserNode>();
  const samplesRef = useRef<Uint8Array>();
  const [averageSample, setAverageSample] = useState<number>(0);
  const intervalHandle = useRef<any>();

  const onCleanup = useCallback(() => {
    if (intervalHandle.current != null) {
      clearInterval(intervalHandle.current);
    }

    if (analyser.current != null) {
      analyser.current.disconnect();
    }

    if (mediaStreamSource.current != null) {
      mediaStreamSource.current.disconnect();
    }

    intervalHandle.current = undefined;
    analyser.current = undefined;
    mediaStreamSource.current = undefined;
  }, []);

  const onUpdate = useCallback(() => {
    if (analyser.current != null && samplesRef.current != null) {
      analyser.current.getByteFrequencyData(samplesRef.current);
      setAverageSample(samplesRef.current.reduce((acc, curr) => acc + curr, 0) / samplesRef.current.length);
    }
  }, []);

  const onInit = useCallback(() => {
    if (mediaStream == null) {
      return;
    }

    mediaStreamSource.current = audioContext.createMediaStreamSource(mediaStream);
    analyser.current = audioContext.createAnalyser();

    if (!mediaStreamSource.current || !analyser.current) {
      return;
    }

    mediaStreamSource.current?.connect(analyser.current);
    analyser.current.fftSize = resolution;
    samplesRef.current = new Uint8Array(analyser.current.frequencyBinCount);
    intervalHandle.current = setInterval(onUpdate, refreshRateMs) as any;
  }, [mediaStream, audioContext, onUpdate, refreshRateMs, resolution]);

  useEffect(() => {
    onInit();

    return onCleanup;
  }, [onInit, onCleanup]);

  return [
    averageSample,
  ] as const;
};

const useSilenceMonitor = (mediaStream: MediaStream | null | undefined, minimumSample: number = DEFAULT_SILENCE_SAMPLE_THRESHOLD, minimumDurationMs: number = DEFAULT_SILENCE_SAMPLE_DURATION) => {
  const [averageSample] = useAudioLevels(mediaStream);
  const isBelow = averageSample < minimumSample;
  const [silence, setSilence] = useState(false);

  useEffect(() => {
    if (!isBelow || !mediaStream) {
      setSilence(false);

      return;
    }

    const handle = setTimeout(() => {
      setSilence(true);
    }, minimumDurationMs);

    return () => {
      clearTimeout(handle);
    };
  }, [isBelow, mediaStream, minimumDurationMs]);

  return [
    silence,
  ] as const;
};

export {
  useAudioLevels,
  useSilenceMonitor,
};
