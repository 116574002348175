import _ from 'lodash/fp';
import { IWorkflowFormElementElement, IWorkflowFormElementElementOption } from 'lib/modules/qualieApi/entities/workflow';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Button, Form, Input, Space } from 'antd';
import { IWorkflowFormElementProps } from './types';
import { CommencedWorkflowContext } from 'app/modules/pux/contexts';
import { QuestionLabel } from 'app/modules/pux/components/content';
import ErrorMessage from '../../../errorMessage';
import { RuleObject } from 'antd/lib/form';
import { useIntl } from 'react-intl';
import { WORKFLOW_ELEMENT_FORM_ELEMENT_TYPES } from '.';
import { FullscreenOutlined } from '@ant-design/icons';
import ImageModal from './imageModal';

const ImageCheckboxButton: React.FunctionComponent<{
  active: boolean;
  option: IWorkflowFormElementElementOption;
  onClick: (option: IWorkflowFormElementElementOption) => void;
}> = ({
  children,
  active,
  option,
  onClick,
}) => {
  const [isImageModalOpen, setImageModalOpen] = useState<boolean>(false);
  const [imageModalImageUri, setImageModalImageUri] = useState<string>();

  const onImageClick = useCallback((event: any, imageUri?: string) => {
    event.stopPropagation();

    if (imageUri) {
      setImageModalImageUri(imageUri);
      setImageModalOpen(true);
    }
  }, []);

  const onCancel = useCallback(() => {
    setImageModalImageUri(undefined);
    setImageModalOpen(false);
  }, []);

  return (
    <>
      <Button
        className="image-checkbox-form-element-btn"
        type={active ? 'primary' : 'default'}
        onClick={() => onClick(option)}
      >
        <div
          className="form-element-checkbox-button-image-link"
          style={{ backgroundImage: `url('${option.imageUri}')` }}
          onClick={() => onClick(option)}
        >
          {option.imageUri && (
            <div className="banner-element-view-link">
            <Button
              ghost={true}
              className="form-element-checkbox-button-image-link-fullscreen"
              onClick={(event) => onImageClick(event, option.imageUri)}
            >
              <FullscreenOutlined />
            </Button>
          </div>
          )}
        </div>
        <div className="form-element-checkbox-button-image-label">
          {option.label}
        </div>
      </Button>
      <ImageModal
        imageUri={imageModalImageUri!}
        isOpen={isImageModalOpen}
        onCancel={onCancel}
      />
    </>
  );
};

const ImageCheckboxFormElement: React.FunctionComponent<IWorkflowFormElementProps<IWorkflowFormElementElement>> = (props) => {
  const { workflowElement, path } = props;
  const options = useMemo(() => _.sortBy('position', workflowElement?.options || []), [workflowElement]);
  const name = useMemo(() => path ? [...path, workflowElement.name] : [workflowElement.name], [path, workflowElement]);
  const { uiTexts } = useContext(CommencedWorkflowContext);
  const intl = useIntl();

  const rules = useMemo(() => ([
    {
      validator: async (rule: RuleObject, value: any) => {
        if (workflowElement.required && (!value || (_.isArray(value) && value.length === 0))) {
          throw new Error(uiTexts?.REQUIRED?.value);
        }
      },
      required: workflowElement.required,
    },
    ...(workflowElement.inputType === WORKFLOW_ELEMENT_FORM_ELEMENT_TYPES.CheckboxImage ? [{
      validator: async (rule: RuleObject, value: any) => {
        const selectedCount = _.isArray(value) ? value.length : 0;
        const min = Math.max(workflowElement.min, workflowElement.required ? 1 : 0);
        if (min && selectedCount < min) {
          throw new Error((uiTexts?.WORKFLOW_ELEMENT_FORM_ELEMENT_MATRIX_CHECKBOX_MIN_LIMIT) ? intl.formatMessage({
            id:  'NOT_DEFINED',
            defaultMessage: uiTexts?.WORKFLOW_ELEMENT_FORM_ELEMENT_MATRIX_CHECKBOX_MIN_LIMIT?.value,
          }, {min: min}) : intl.formatMessage({ id: 'workflowElement.formElement.matrix.checkbox.minLimit' }, {
            min: min,
          }));
        } else if (workflowElement.max && selectedCount > workflowElement.max) {
          throw new Error((uiTexts?.WORKFLOW_ELEMENT_FORM_ELEMENT_MATRIX_CHECKBOX_MAX_LIMIT) ? intl.formatMessage({
            id:  'NOT_DEFINED',
            defaultMessage: uiTexts?.WORKFLOW_ELEMENT_FORM_ELEMENT_MATRIX_CHECKBOX_MAX_LIMIT?.value,
          }, {max: workflowElement.max})  : intl.formatMessage({ id: 'workflowElement.formElement.matrix.checkbox.maxLimit' }, {
            max: workflowElement.max,
          }));
        }
      },
    }] : []),
  ]), [intl, workflowElement, uiTexts]);

  return (
    <Form.Item
      label={<QuestionLabel>{workflowElement.label}</QuestionLabel>}
      name={[...name, 'answers']}
      rules={rules}
      getValueFromEvent={(args: string[]) => {
        for (const arg of args.reverse()) {
          // Handle last click being exclusive.
          if (options.find(option => option.key === arg)?.exclusive) {
            return [arg];
          }
          // Handle last click not being exclusive.
          if (!options.find(option => option.key === arg)?.exclusive) {
            return args.filter(arg => !options.find(option => option.key === arg)?.exclusive);
          }
        }

        return args;
      }}
    >
      <Form.Item
        shouldUpdate={true}
        noStyle={true}
      >
        {form => {
          const path = [...name, 'answers'];
          const value = form.getFieldValue(path) as string[];
          const otherOption = options.find(a => a?.type === 'TEXT');
          const otherSelected = value && otherOption ? value.includes(otherOption.key) : false;
          const exclusiveOptions = options.filter(a => a?.exclusive).map(b => b.key);

          const onClick = (option: IWorkflowFormElementElementOption) => {
            let next = null;

            if (exclusiveOptions.length !== 0) {
              if (exclusiveOptions.includes(option.key)) {
                next = (!value?.includes(option.key) ? [...[], option.key] : (value || []).filter(a => a !== option.key));
              } else {
                if (!value?.includes(option.key)) {
                  next = (value || []).filter(a => !exclusiveOptions.includes(a));
                  next = [...(next || []), option.key];
                } else {
                  next = (value || []).filter(a => a !== option.key);
                }
              }
            } else {
              next = (!value?.includes(option.key) ? [...(value || []), option.key] : (value || []).filter(a => a !== option.key));
            }

            form.setFieldsValue(_.set(path, next, {}));
          };

          return (
            <React.Fragment>
              <div className="form-element-checkbox-button-image-group">
                <Space
                  wrap={true}
                  style={{justifyContent: 'center'}}
                >
                  {options.map(option => (
                    <ImageCheckboxButton
                      key={option.key}
                      active={!!value?.includes(option.key)}
                      option={option}
                      onClick={onClick}
                    >{option.label}</ImageCheckboxButton>
                  ))}
                </Space>
                {!!otherSelected && !!otherOption && (
                  <div className="button-checkbox-form-element-other">
                    <Form.Item
                      label={otherOption.label}
                      name={[...name, 'other']}
                      rules={[
                        {
                          required: workflowElement.required,
                          message: <ErrorMessage message={uiTexts?.IS_REQUIRED?.value} />,
                        },
                        {
                          max: Number(otherOption.limit),
                          message: <ErrorMessage message={uiTexts?.MAXIMUM_CHARACTERS_EXCEEDED?.value} />,
                        },
                      ]}
                    >
                      <Input
                        onClick={e => e.stopPropagation()}
                        className="form-element-checkbox-other-input"
                      />
                    </Form.Item>
                  </div>
                )}
              </div>
            </React.Fragment>
          );
        }}
      </Form.Item>
    </Form.Item>
  );
};

export default ImageCheckboxFormElement;