import React, { useContext } from 'react';
import IWorkflowActivityProps from '../IWorkflowActivityProps';
import { IWorkflowElement, IWorkflowFormElement } from 'lib/modules/qualieApi/entities/workflow';
import WorkflowElement from '../../workflowElement';
import { useKeyedWorkflowElements } from 'app/modules/pux/hooks';
import { CommencedWorkflowContext } from 'app/modules/pux/contexts';
import SplitWorkflowForm from '../../layout/splitWorkflowForm';
import { WorkflowActivitySubtitle, WorkflowActivityTitle } from '../../content';

type Elements = {
  title: IWorkflowElement;
  subTitle: IWorkflowElement;
  screeningForm: IWorkflowFormElement;
  showAdvanceRetreat: IWorkflowElement;
};

const DetailsWorkflowActivity: React.FunctionComponent<IWorkflowActivityProps> = (props) => {
  const { workflowActivity } = props;
  const elements = useKeyedWorkflowElements<Elements>(workflowActivity.workflowElements);
  const { toNextStep, checkPrerequisites } = useContext(CommencedWorkflowContext);

  return (
    <SplitWorkflowForm focus="empty">
      <SplitWorkflowForm.Filled>
        <WorkflowActivityTitle>{elements.title.value}</WorkflowActivityTitle>
        <WorkflowActivitySubtitle>{elements.subTitle.value}</WorkflowActivitySubtitle>
      </SplitWorkflowForm.Filled>
      <SplitWorkflowForm.Empty>
        {elements.showAdvanceRetreat?.value === 'true' &&
          <WorkflowActivitySubtitle>(Fields can be left blank in test)</WorkflowActivitySubtitle>
        }
        <WorkflowElement
          workflowElement={elements.screeningForm}
          onFinish={toNextStep}
          onBeforeFinish={checkPrerequisites}
        />
      </SplitWorkflowForm.Empty>
    </SplitWorkflowForm>
  );
};

export default DetailsWorkflowActivity;