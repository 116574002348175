import { Layout } from 'antd';
import appRoutes from 'app/routes';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { RouteComponentProps, StaticContext } from 'react-router';
import { Footer } from '../components';
import Theme from '../components/theme';

interface IProps extends RouteComponentProps<typeof appRoutes['routes']['maintenance']['params'], StaticContext> { }

const MaintenanceRoute: React.FunctionComponent<IProps> = () => {

  return (
    <Layout className="root-layout">
      <Theme />
      <Layout.Content className="centered-layout">
        <div>
          <h1>
            <FormattedMessage id="maintenance.heading" />
          </h1>
          <h3>
            <FormattedMessage id="maintenance.subHeading" />
          </h3>
        </div>
      </Layout.Content>
      <Layout.Footer>
        <Footer />
      </Layout.Footer>
    </Layout>
  );
};

export default MaintenanceRoute;
