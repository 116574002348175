import _ from 'lodash/fp';
import { IWorkflowFormElementElement, IWorkflowFormElementElementOption } from 'lib/modules/qualieApi/entities/workflow';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { IWorkflowFormElementProps } from './types';
import { Form, Radio, Slider } from 'antd';
import { CommencedWorkflowContext } from 'app/modules/pux/contexts';
import cx from 'classnames';
import { useIsMobile } from 'app/hooks';
import { QuestionLabel } from 'app/modules/pux/components/content';
import { StarFilled } from '@ant-design/icons';
import ErrorMessage from '../../../errorMessage';
import { workflowsSelectors } from 'app/modules/workflows/store';
import { useSelector } from 'react-redux';

const OVERSIZE_THRESHOLD = 6;

const RadioLabel: React.FunctionComponent<{ index: number, option: any, selectedIndex: number, starRating: boolean }> = ({
  index,
  option,
  selectedIndex,
  starRating,
}) => {
  const isMobile = useIsMobile();

  let label: any = isMobile ? option.label : index + 1;
  if (starRating) {
    label = <StarFilled className={index <= selectedIndex ? 'star-filled' : 'star-empty'} />;
  }

  return (
    <span>{label}</span>
  );
};

const RadioInput: React.FunctionComponent<{
  workflowElement: IWorkflowFormElementElement;
  rating: boolean;
  options: IWorkflowFormElementElementOption[];
  name: (string | number)[];
}> = ({
  workflowElement,
  rating,
  options,
  name,
}) => {
    const { uiTexts } = useContext(CommencedWorkflowContext);
    const className = useMemo(() => cx({
      'scale-form-element': true,
      'oversized': options.length > OVERSIZE_THRESHOLD,
      'star-rating': rating,
    }), [options, rating]);

    const optionClassName = useMemo(() => cx({
      'scale-form-element-option': true,
      'star-rating-star': rating,
    }), [rating]);

    return (
      <Form.Item
        className={className}
        label={<QuestionLabel>{workflowElement.label}</QuestionLabel>}
        shouldUpdate={true}
      >
        {form => {
          const value = form.getFieldValue(name);
          const selectedIndex = _.findIndex({ key: value?.selected }, options);

          return (
            <Form.Item
              name={[...name, 'selected']}
              rules={[
                {
                  required: workflowElement.required,
                  message: <ErrorMessage message={uiTexts?.IS_REQUIRED?.value} />,
                },
              ]}
            >
              <Radio.Group>
                {options.map((option, index) => (
                  <Radio.Button
                    className={optionClassName}
                    key={option.value}
                    value={option.key}
                  >
                    <RadioLabel
                      index={index}
                      option={option}
                      selectedIndex={selectedIndex}
                      starRating={rating || false}
                    />
                  </Radio.Button>
                ))}
              </Radio.Group>
            </Form.Item>
          );
        }}
      </Form.Item>
    );
  };

const SliderInput: React.FunctionComponent<{
  workflowElement: IWorkflowFormElementElement;
  options: IWorkflowFormElementElementOption[];
  name: (string | number)[];
}> = ({
  workflowElement,
  options,
  name,
}) => {
    const { uiTexts } = useContext(CommencedWorkflowContext);
    const frontLabel = useMemo(() => options[0].label, [options]);
    const endLabel = useMemo(() => options[options.length - 1].label, [options]);
    const [dirty, setDirty] = useState<boolean>(false);
    const theme = useSelector(workflowsSelectors.getTheme);
    const handleStyles = useMemo(() => {
      let styles: React.CSSProperties = {};
      if (!dirty) {
        styles['display'] = 'none';
      }

      return styles;
    }, [dirty]);

    const onAfterChange = useCallback((value: number) => setDirty(true), [setDirty]);

    return (
      <Form.Item
        className="scale-form-element"
        label={<QuestionLabel>{workflowElement.label}</QuestionLabel>}
        name={[...name, 'selected']}
        getValueFromEvent={(value: number) => options[value]?.key}
        getValueProps={(key: string) => {
          const index = options.findIndex(a => a.key === key);

          return { value: index >= 0 ? index : null };
        }}
        rules={[
          {
            required: workflowElement.required,
            message: <ErrorMessage message={uiTexts?.IS_REQUIRED?.value} />,
          },
        ]}
        extra={(
          <div className="scale-form-labels">
            <div className="scale-form-label slider-label-left">{frontLabel}</div>
            <div className="scale-form-label slider-label-right">{endLabel}</div>
          </div>
        )}
      >
        <Slider
          dots={true}
          tooltipVisible={false}
          min={0}
          max={options.length - 1}
          handleStyle={handleStyles}
          onAfterChange={onAfterChange}
          reverse={theme?.direction === 'rtl'}
        />
      </Form.Item>
    );
  };

const ScaleFormElement: React.FunctionComponent<IWorkflowFormElementProps<IWorkflowFormElementElement>> = (props) => {
  const { workflowElement, path } = props;
  const sortedOptions = useMemo(() => _.sortBy('position', workflowElement.options), [workflowElement]);
  const name = useMemo(() => path ? [...path, workflowElement.name] : [workflowElement.name], [path, workflowElement]);
  const hasRatingTag = workflowElement.tag === 'rating';

  return hasRatingTag
    ? (
      <RadioInput
        workflowElement={workflowElement}
        name={name}
        options={sortedOptions}
        rating={hasRatingTag}
      />
    ) : (
      <SliderInput
        workflowElement={workflowElement}
        name={name}
        options={sortedOptions}
      />
    );
};

export default ScaleFormElement;
