import _ from 'lodash/fp';
import { IWorkflowFormElementElement } from 'lib/modules/qualieApi/entities/workflow';
import React, { useMemo } from 'react';
import { IWorkflowFormElementProps } from './types';
import PreferenceOpinionFormElement from './preferenceOpinion';

const PreferenceOpinionMultiFormElement: React.FunctionComponent<IWorkflowFormElementProps<IWorkflowFormElementElement>> = (props) => {
  const { dependencies, workflowElement } = props;
  const groupedInitiatives = useMemo(() => _.groupBy('name', (_.orderBy(['group', 'name'], ['asc', 'asc'], dependencies?.initiatives?.collection) || [])), [dependencies?.initiatives]);
  const preferenceOpinionElement = useMemo(() => {
    let initiativeCollection: IWorkflowFormElementElement[] = [];
    for (const groupKey in groupedInitiatives) {
      const group = groupedInitiatives[groupKey];
      const matchedInitiative: any = _.find((a: any) => a.stimuli.length > 0, group) || _.first(group);
      const match: IWorkflowFormElementElement = _.find({ name: matchedInitiative?.hash }, (workflowElement?.collection || [])) as IWorkflowFormElementElement;
      initiativeCollection.push(match);
    }

    return {
      ...workflowElement,
      collection: initiativeCollection,
    };
  }, [workflowElement, groupedInitiatives]);

  return (
    <PreferenceOpinionFormElement
      dependencies={dependencies}
      workflowElement={preferenceOpinionElement}
    />
  );
};

export default PreferenceOpinionMultiFormElement;
