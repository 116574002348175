import { Button, Spin } from 'antd';
import { useAppDispatch, useMountEffect } from 'app/hooks';
import { CommencedWorkflowContext } from 'app/modules/pux/contexts';
import { useKeyedWorkflowElements } from 'app/modules/pux/hooks';
import { workflowsActions } from 'app/modules/workflows';
import { IReviewText, IWorkflowCarouselElement, IWorkflowElement, IWorkflowFormElement, IWorkflowInitiativeElement } from 'lib/modules/qualieApi/entities/workflow';
import Utils from 'lib/utils';
import _ from 'lodash/fp';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { PrerequisiteError, WorkflowElement } from '../../..';
import { WorkflowActivitySubtitle, WorkflowActivityTitle } from '../../../content';
import BasicWorkflowForm from '../../../layout/basicWorkflowForm';
import FormActions from '../../../layout/formActions';
import SplitWorkflowForm from '../../../layout/splitWorkflowForm';
import BannerWorkflowElement from '../../../workflowElement/types/contentBanner';
import IWorkflowActivityProps from '../../IWorkflowActivityProps';
import RequiredReadingBlock from './requiredReadingBlock';
import WorkflowActivityAdvanceRetreat from '../../../content/workflowActivityTestAdvanceRetreat';

type Elements = {
  title: IWorkflowElement;
  subTitle: IWorkflowElement;
  initiatives: IWorkflowInitiativeElement;
  explainer: IWorkflowCarouselElement;
  explainerButtonText: IWorkflowElement;
  nextVideoURL: IWorkflowElement;
  reviewQuestionOpinionForm: IWorkflowFormElement;
  nextButton: IWorkflowElement;
  showAdvanceRetreat: IWorkflowElement;
  showAdvance: IWorkflowElement;
  showRetreat: IWorkflowElement;
};

const NEXT_TIMEOUT = 2000;

const ReviewQuestionV2WorkflowActivity: React.FunctionComponent<IWorkflowActivityProps> = (props) => {
  const { workflowActivity } = props;
  const dispatch = useAppDispatch();
  const elements = useKeyedWorkflowElements<Elements>(workflowActivity.workflowElements);
  const { setPrerequisite, uiTexts, checkPrerequisites, advance, prerequisites, onReady, advanceInTest, retreatInTest } = useContext(CommencedWorkflowContext);
  const [explainerValid, setExplainerValid] = useState(!elements.explainer);
  const [reviewText, setReviewText] = useState<IReviewText>();
  const showExplainer = !explainerValid && !!elements.explainer;
  const explainers = useMemo(() => _.sortBy('position', elements.explainer?.carouselHtmlElements || []), [elements]);
  const stimuli = useMemo(() => {
    const initiative = (elements.initiatives?.collection || [])[0] as any;

    return _.sortBy('position', initiative?.stimuli || []);
  }, [elements]);

  /**
   * A modified version of the opinion form where we replace the rating options
   * with those supplied by the review text.
   */
  const reviewQuestionOpinionForm: IWorkflowFormElement = useMemo(() => ({
    ...elements.reviewQuestionOpinionForm,
    elements: elements.reviewQuestionOpinionForm.elements.map(element => {
      switch (element.name) {
        case 'rating':
          return {
            ...element,
            options: reviewText?.ratings || element.options,
          };
        /**
         * Need to inject the current opinion hash into the activity so the form
         * uses that value in the input for submission.
         */
        case 'opinionHash':
          return {
            ...element,
            value: reviewText?.opinionActivity.hash,
          };
        default:
          return element;
      }
    }),
  }), [elements.reviewQuestionOpinionForm, reviewText]);

  const onExplainerValid = useCallback((element: IWorkflowElement, valid: boolean, errorMessage?: string) => {
    setPrerequisite(element.name, {
      reveal: false,
      valid: valid,
      error: errorMessage || uiTexts?.VIEW_ALL_CONTENT.value,
    });
  }, [setPrerequisite, uiTexts]);

  const onExplainerFinish = useCallback(() => {
    if (checkPrerequisites()) {
      setExplainerValid(true);
    }
  }, [checkPrerequisites]);

  const onReadingValid = useCallback((valid: boolean) => {
    setPrerequisite('requiredReading', {
      reveal: false,
      valid: valid,
      error: uiTexts?.VIEW_ALL_CONTENT.value,
    });
  }, [setPrerequisite, uiTexts]);

  const onNextText = useCallback(async (wait?: number) => {
    setReviewText(undefined);
    if (wait != null) {
      await Utils.wait(wait);
    }
    if (elements?.nextVideoURL?.value) {
      const result = await dispatch(workflowsActions.getNextReviewText({ nextVideoUrl: elements.nextVideoURL.value })).unwrap();
      if (result.data == null || !result.data?.length) {
        (elements.showAdvanceRetreat?.value === 'true') ? advanceInTest() : advance();
      } else {
        setReviewText(result.data[0]);
        Utils.scrollToTop();
        onReady();
      }
    }
  }, [elements, dispatch, advance, onReady, advanceInTest]);

  const onNextTextForForm = useCallback(() => {
    onNextText(NEXT_TIMEOUT);
  }, [onNextText]);

  useMountEffect(() => {
    onNextText();
  });

  return (
    <SplitWorkflowForm focus="empty">
      <SplitWorkflowForm.Filled>
        <WorkflowActivityTitle>{elements.title.value}</WorkflowActivityTitle>
        <WorkflowActivitySubtitle>{elements.subTitle.value}</WorkflowActivitySubtitle>
        {elements.showAdvanceRetreat?.value === 'true' &&
          <WorkflowActivityAdvanceRetreat
            onAdvance={advanceInTest}
            onRetreat={retreatInTest}
            allowAdvance={elements.showAdvance?.value === 'true'}
            allowRetreat={elements.showRetreat?.value === 'true'}
          />}
      </SplitWorkflowForm.Filled>
      <SplitWorkflowForm.Empty
        header={!showExplainer && !!elements.explainer && !!reviewText && (
          <BannerWorkflowElement
            explainers={explainers}
            stimuli={stimuli}
          />
        )}
      >
        {!reviewText && <Spin size="large" />}
        {showExplainer && !!reviewText && (
          <React.Fragment>
            <WorkflowElement
              workflowElement={elements.explainer}
              onValid={onExplainerValid}
            />
            <PrerequisiteError target="explainer" />
            <FormActions>
              <Button
                type="primary"
                onClick={onExplainerFinish}
              >{elements.explainerButtonText.value}</Button>
            </FormActions>
          </React.Fragment>
        )}
        {!showExplainer && !!reviewText && (
          <React.Fragment>
          <BasicWorkflowForm size="large">
            <PrerequisiteError target="requiredReading" />
            <RequiredReadingBlock
              text={reviewText.questionAnswer}
              onValid={onReadingValid}
              reveal={prerequisites?.requiredReading?.reveal}
            />
            <WorkflowElement
              workflowElement={reviewQuestionOpinionForm}
              onFinish={onNextTextForForm}
              onBeforeFinish={checkPrerequisites}
            />
          </BasicWorkflowForm>
        </React.Fragment>
        )}
      </SplitWorkflowForm.Empty>
    </SplitWorkflowForm>
  );
};

export default ReviewQuestionV2WorkflowActivity;
