import _ from 'lodash';
import { Alert, Button, FormProps } from 'antd';
import Form from 'antd/lib/form/Form';
import { useSortedWorkflowElements } from 'app/modules/pux/hooks';
import { IWorkflowFormElement } from 'lib/modules/qualieApi/entities/workflow';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import IWorkflowElementProps from '../../IWorkflowElementProps';
import FormElement from './formElement';
import { WORKFLOW_ELEMENT_FORM_TYPE_COMPONENTS } from './types';
import { CommencedWorkflowContext } from 'app/modules/pux/contexts';
import { useAppDispatch } from 'app/hooks';
import { workflowsActions } from 'app/modules/workflows';
import { useIntl } from 'react-intl';
import QualieAPI from 'lib/modules/qualieApi';
import FormActions from '../../../layout/formActions';
import { Dictionary } from 'lib/types';

const InvalidTypeFallbackFormWorkflowElement: React.FunctionComponent<IWorkflowElementProps<IWorkflowFormElement>> = (props) => {
  const { workflowElement, onFinish, onBeforeFinish } = props;
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const { uiTexts } = useContext(CommencedWorkflowContext);
  const [error, setError] = useState<string | null>();
  const [fields, setFields] = useState<FormProps['fields']>();
  const { flags } = useContext(CommencedWorkflowContext);
  const elements = useSortedWorkflowElements(workflowElement.elements);
  const initialValues = useMemo(() => workflowElement.elements.reduce<Dictionary>((acc, curr) => {
    switch (curr.name) {
      // reserved field name
      case 'provider':
        acc[curr.name] = flags.pp;
        break;
      default:
        acc[curr.name] = curr.value;
    }

    return acc;
  }, {}), [workflowElement, flags]);

  const onFormFinish = useCallback(async (values) => {
    if (onBeforeFinish && !(await onBeforeFinish(workflowElement))) {
      return;
    }

    setError(null);
    setFields(elements.map(a => ({
      name: a.name,
      errors: [],
    })));

    try {
      const result = await dispatch(workflowsActions.submitWorkflowFormElement({
        actionUrl: workflowElement.actionURL,
        form: values,
      }));
      if (result.meta.requestStatus === 'rejected') {
        switch ((result.payload as any)?.code) {
          case QualieAPI.codes.response.ParticipantExists:
            const errors = _.map((result.payload as any)?.data, (v: string, k: string) => ({
              name: k,
              errors: [v],
            }));
            setFields(errors);
            break;
          default:
            setError((result.payload as any)?.message);
        }
      }
    } catch (e: any) {
      setError((uiTexts?.WORKFLOW_ELEMENT_FORM_GENERIC_SERVER_ERROR) ? intl.formatMessage({
        id:  'NOT_DEFINED',
        defaultMessage: uiTexts?.WORKFLOW_ELEMENT_FORM_GENERIC_SERVER_ERROR?.value,
      }) : intl.formatMessage({ id: 'workflowElement.form.genericServerError' }));

      return;
    }

    if (onFinish) {
      onFinish(workflowElement);
    }
  }, [onBeforeFinish, workflowElement, elements, onFinish, dispatch, uiTexts, intl]);

  return (
    <Form
      layout="vertical"
      onFinish={onFormFinish}
      initialValues={initialValues}
      fields={fields}
    >
      {!!error && (
        <Alert
          type="error"
          message={error}
          showIcon
        />
      )}
      {elements.map(a => (
        <FormElement
          key={a.name}
          workflowElement={a}
        />
      ))}
      <FormActions>
        <Button
          type="primary"
          htmlType="submit"
        >{workflowElement.submitButtonText}</Button>
      </FormActions>
    </Form >
  );
};

const FormWorkflowElement: React.FunctionComponent<IWorkflowElementProps<IWorkflowFormElement>> = (props) => {
  const { workflowElement } = props;
  const WorkflowElementType = useMemo(() => WORKFLOW_ELEMENT_FORM_TYPE_COMPONENTS[workflowElement.formType] || InvalidTypeFallbackFormWorkflowElement, [workflowElement]);

  return <WorkflowElementType {...props} />;
};

export default FormWorkflowElement;
