import { Form, Input } from 'antd';
import { IWorkflowFormElementElement } from 'lib/modules/qualieApi/entities/workflow';
import React, { useMemo } from 'react';
import { IWorkflowFormElementProps } from './types';

const HiddenFormElement: React.FunctionComponent<IWorkflowFormElementProps<IWorkflowFormElementElement>> = (props) => {
  const { workflowElement, path } = props;
  const name = useMemo(() => path ? [...path, workflowElement.name] : [workflowElement.name], [path, workflowElement]);

  return (
    <Form.Item
      name={name}
      hidden={true}
    >
      <Input />
    </Form.Item>

  );
};

export default HiddenFormElement;