import { useKeyedWorkflowElements } from 'app/modules/pux/hooks';
import { IWorkflowElement } from 'lib/modules/qualieApi/entities/workflow';
import React from 'react';
import BasicWorkflowForm from '../../layout/basicWorkflowForm';
import MessageWorkflowElement from '../../workflowElement/types/message';
import IWorkflowActivityProps from '../IWorkflowActivityProps';

type Elements = {
  message: IWorkflowElement;
};

const ClosedWorkflowActivity: React.FunctionComponent<IWorkflowActivityProps> = (props) => {
  const { workflowActivity } = props;
  const elements = useKeyedWorkflowElements<Elements>(workflowActivity.workflowElements);

  return (
    <BasicWorkflowForm size="default">
      <MessageWorkflowElement workflowElement={elements.message} />
    </BasicWorkflowForm>
  );
};

export default ClosedWorkflowActivity;
