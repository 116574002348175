import _ from 'lodash/fp';
import { IWorkflowFormElementElement } from 'lib/modules/qualieApi/entities/workflow';
import React, { useContext, useMemo } from 'react';
import { Form, Input, Radio } from 'antd';
import { IWorkflowFormElementProps } from './types';
import { CommencedWorkflowContext } from 'app/modules/pux/contexts';
import { QuestionLabel } from 'app/modules/pux/components/content';
import ErrorMessage from '../../../errorMessage';

const ButtonRadioFormElement: React.FunctionComponent<IWorkflowFormElementProps<IWorkflowFormElementElement>> = (props) => {
  const { workflowElement, path } = props;
  const options = useMemo(() => _.sortBy('position', workflowElement.options), [workflowElement]);
  const name = useMemo(() => path ? [...path, workflowElement.name] : [workflowElement.name], [path, workflowElement]);
  const { uiTexts } = useContext(CommencedWorkflowContext);

  return (
    <React.Fragment>
      <Form.Item
        label={<QuestionLabel>{workflowElement.label}</QuestionLabel>}
        name={[...name, 'answer']}
        rules={[
          {
            required: workflowElement.required,
            message: <ErrorMessage message={uiTexts?.IS_REQUIRED?.value} />,
          },
        ]}
      >
        <Radio.Group
          buttonStyle="solid"
          className="form-element-radio-button-group"
        >
          {options.map(option => (
            <Radio.Button
              className="form-element-radio-button"
              key={option.value}
              value={option.key}
            >
              <Form.Item
                shouldUpdate={true}
                noStyle={true}
              >
                {form => {
                  if (!(option?.type === 'TEXT')) {
                    return option.label;
                  }

                  const value = form.getFieldValue([...name, 'answer']);
                  if (value !== option.key) {
                    return option.label;
                  }

                  return (
                    <Form.Item
                      name={[...name, 'other']}
                      rules={[
                        {
                          required: workflowElement.required,
                          message: <ErrorMessage message={uiTexts?.IS_REQUIRED?.value} />,
                        },
                        {
                          max: Number(option.limit),
                          message: <ErrorMessage message={uiTexts?.MAXIMUM_CHARACTERS_EXCEEDED?.value} />,
                        },
                      ]}
                      noStyle={true}
                    >
                      <Input className="form-element-radio-other-input" />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </Radio.Button>
          ))}
        </Radio.Group>
      </Form.Item>
    </React.Fragment>
  );
};

export default ButtonRadioFormElement;