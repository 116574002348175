import { CheckCircleOutlined } from '@ant-design/icons';
import { useKeyedWorkflowElements } from 'app/modules/pux/hooks';
import { IWorkflowElement } from 'lib/modules/qualieApi/entities/workflow';
import React from 'react';
import { WorkflowActivitySubtitle, WorkflowActivityTitle } from '../../content';
import BasicWorkflowForm from '../../layout/basicWorkflowForm';
import IWorkflowActivityProps from '../IWorkflowActivityProps';

type Elements = {
  title: IWorkflowElement;
  subTitle: IWorkflowElement;
  qualieLogo: IWorkflowElement;
};

const GoodbyeWorkflowActivity: React.FunctionComponent<IWorkflowActivityProps> = (props) => {
  const { workflowActivity } = props;
  const elements = useKeyedWorkflowElements<Elements>(workflowActivity.workflowElements);

  return (
    <BasicWorkflowForm size="default">
      <WorkflowActivityTitle>{elements.title.value}</WorkflowActivityTitle>
      <WorkflowActivitySubtitle>{elements.subTitle.value}</WorkflowActivitySubtitle>
      <div className="goodbye-icon">
        <CheckCircleOutlined />
      </div>
    </BasicWorkflowForm>
  );
};

export default GoodbyeWorkflowActivity;
