import { useKeyedWorkflowElements } from 'app/modules/pux/hooks';
import { IWorkflowElement } from 'lib/modules/qualieApi/entities/workflow';
import React, { useMemo } from 'react';
import { WorkflowActivityTitle } from '../../content';
import BasicWorkflowForm from '../../layout/basicWorkflowForm';
import IWorkflowActivityProps from '../IWorkflowActivityProps';

type Elements = {
  title: IWorkflowElement;
  content: IWorkflowElement;
};

const ErrorWorkflowActivity: React.FunctionComponent<IWorkflowActivityProps> = (props) => {
  const { workflowActivity } = props;
  const elements = useKeyedWorkflowElements<Elements>(workflowActivity.workflowElements);
  const html = useMemo(() => ({ __html: elements.content.value || '' }), [elements]);

  return (
    <BasicWorkflowForm size="default">
      <WorkflowActivityTitle>{elements.title.value}</WorkflowActivityTitle>
      <p dangerouslySetInnerHTML={html} />
    </BasicWorkflowForm>
  );
};

export default ErrorWorkflowActivity;
