import { Modal, Image } from 'antd';

interface IImageModalProps {
  imageUri: string;
  isOpen: boolean;
  onCancel: () => void;
}

const ImageModal: React.FunctionComponent<IImageModalProps> = (props) => {
  const { imageUri, isOpen, onCancel } = props;

  return (
    <Modal
      className="image-modal-overlay-wrapper"
      centered
      visible={isOpen}
      footer={null}
      onCancel={onCancel}
      width="100%"
    >
      <Image
        src={imageUri}
        preview={false}
      />
    </Modal>
  );
};

export default ImageModal;