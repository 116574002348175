import _ from 'lodash';
import { IWorkflowElement, IWorkflowFormElement, IWorkflowFormElementElement } from 'lib/modules/qualieApi/entities/workflow';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import IWorkflowElementProps from '../../../IWorkflowElementProps';
import { InboxOutlined } from '@ant-design/icons';
import type { UploadProps } from 'antd';
import { Alert, Button, Form, FormProps, message, Upload } from 'antd';
import { useAppDispatch } from 'app/hooks';
import { useIntl } from 'react-intl';
import { useSortedWorkflowElements } from 'app/modules/pux/hooks';
import FormElement from '../formElement';
import { workflowsActions } from 'app/modules/workflows';
import QualieAPI from 'lib/modules/qualieApi';
import FormActions from 'app/modules/pux/components/layout/formActions';
import Utils from 'lib/utils';
import { Dictionary } from 'lib/types';
import { CommencedWorkflowContext } from 'app/modules/pux/contexts';
import { ApiResponse } from 'lib/modules/qualieApi/types';

const { Dragger } = Upload;

const getInitialValues = (elements: IWorkflowElement[]) => elements.reduce<Dictionary>((acc, curr) => {
  switch (curr.name) {
    default:
      acc[curr.name] = curr.collection
        ? getInitialValues(curr.collection)
        : curr.value;
  }

  return acc;
}, {});

const progressStep = 2;
const progressTimeout = 1000;

const UploadVideoOpinionFormWorkflowElement: React.FunctionComponent<IWorkflowElementProps<IWorkflowFormElement>> = (props) => {
  const { workflowElement, onFinish, onBeforeFinish, hide, onCancel } = props;
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const { uiTexts, participant, workflowId } = useContext(CommencedWorkflowContext);
  const [error, setError] = useState<string | null>();
  const [fields, setFields] = useState<FormProps['fields']>();
  const { toNextStep } = useContext(CommencedWorkflowContext);
  const elements = useSortedWorkflowElements<IWorkflowFormElementElement>(workflowElement.elements);
  const initialValues = useMemo(() => getInitialValues(elements), [elements]);
  const videoS3PathRef = useRef<string | null>(null);
  const [videoS3Path, setVideoS3Path] = useState<string | null>(null);
  const [theFileList, setTheFileList] = useState<any[]>([]);
  const processingProgressRef = useRef<number | null>(null);
  const [processingProgress, setProcessingProgress] = useState<number>(0);
  const timeoutRef = useRef<any>(null);

  useEffect(() => {
    videoS3PathRef.current = videoS3Path;
  }, [videoS3Path]);

  useEffect(() => {
    processingProgressRef.current = processingProgress;
  }, [processingProgress]);

  const onProgressTimeout = useCallback((onProgress) => {
    if (videoS3PathRef.current === null) {
      const progress = ((processingProgressRef.current) ? processingProgressRef.current : 0) + progressStep;
      setProcessingProgress(progress);
      onProgress({percent: progress });
      timeoutRef.current = setTimeout(onProgressTimeout, progressTimeout, onProgress);
    }
  }, [processingProgressRef, videoS3PathRef]);

  const setProcessing = useCallback((onProgress) => {
    if (videoS3Path === null) {
      const uploadingDiv = document.querySelector('div.ant-upload-list-item-thumbnail');

      if (uploadingDiv) {
        uploadingDiv.innerHTML = 'Processing...';
        setProcessingProgress(progressStep);
        onProgress({percent: progressStep });
        if (timeoutRef.current === null) {
          timeoutRef.current = setTimeout(onProgressTimeout, progressTimeout, onProgress);
        }
      }
    }
  }, [onProgressTimeout, videoS3Path, setProcessingProgress]);

  const onUploadVideo = useCallback(async (options) => {
    const { onSuccess, onError, file, onProgress } = options;

    let result: ApiResponse;
    setVideoS3Path(null);
    setProcessingProgress(0);
    timeoutRef.current = null;

    try {
      result = await QualieAPI.Workflow.UploadParticipantVideo(workflowId!, participant!.hash, file, (progress, total) => {
        const percent = Math.round(_.clamp((progress || 0) / (total || 1), 0, 1) * 100);
        if (percent === 100 && processingProgress === 0) {
          setProcessing(onProgress);
        } else {
          onProgress({ percent: percent });
        }
      });
      if (!result.ok) {
        onError();
      } else {
        setVideoS3Path(result.body.data);
        onSuccess('OK');
      }
    } catch (e: any) {
      onError();
    }
  }, [participant, processingProgress, setProcessing, workflowId]);

  const handleOnChange = useCallback(({ file, fileList, event }) => {
    setError(null);

    if (fileList.length === 0) {
      setTheFileList([]);
      setVideoS3Path(null);
      setProcessingProgress(0);
      timeoutRef.current = null;
    } else {
      setTheFileList([{
        ...file,
        //thumbUrl: 'https://qualie-au-project-assets.s3.ap-southeast-2.amazonaws.com/general/default-video-thumbnail.jpg',
      }]);
    }
  }, []);

  const uploadProps: UploadProps = useMemo(() => {
    return {
      name: 'file',
      multiple: false,
      accept: 'video/*',
      customRequest: onUploadVideo,
      onChange: handleOnChange,
      onDrop(e) {
        console.log('Dropped files', e.dataTransfer.files);
      },
      listType: 'picture-card',
      fileList: theFileList,
    };
  }, [theFileList, handleOnChange, onUploadVideo]);

  const onFormFinish = useCallback(async (values) => {
    if (onBeforeFinish && !(await onBeforeFinish(workflowElement))) {
      return;
    }

    if (theFileList.length > 1) {
      message.error('Only one video please');

      return;
    }

    const parsedValues = {
      ...values,
      opinionActivities: _.map(values.opinionActivities, (opinionActivity, opinionActivityHash) => {
        const questionAnswers = (elements
          ?.find(element => element.name === 'opinionActivities')?.collection
          ?.find(element => element.name === opinionActivityHash)?.collection
          // Unfortunately need to forcefully type this as something else as the API breaks it's own rules.
          ?.find(element => element.name === 'questionAnswers')?.value as any) as IWorkflowFormElementElement;

        return {
          ...opinionActivity,
          s3Key: videoS3Path,
          questionAnswers: _.map(opinionActivity.questionAnswers, (v, k) => {
            const question = questionAnswers;
            /**
             * This little nightmare is all about mapping the provided form structure to what the API expects.
             */
            let selectedAnswers = question?.options && (_.isArray(v) || _.isString(v))
              ? (_.isArray(v) ? v : [v])
                .reduce<Dictionary<string>>((acc, v) => {
                  const option = question.options?.find(a => a.key === v);
                  acc[v] = option ? option.value : v;

                  return acc;
                }, {})
              : _.isObject(v) ? v : { [question?.name || k]: v };

            return {
              questionHash: k,
              selectedAnswers: selectedAnswers,
            };
            /**
             * Grabbing the first element out as this is a Question (singular) Opinion
             * and so it expects this to be an object not an array.
             */
          })[0],
        };
      }),
    };

    setError(null);
    setFields(fields => fields?.map(a => ({
      name: a.name,
      errors: [],
    })));

    try {
      await dispatch(workflowsActions.submitWorkflowFormElement({
        actionUrl: workflowElement.actionURL,
        form: parsedValues,
      })).unwrap();
    } catch (e: any) {
      switch (e?.code) {
        case QualieAPI.codes.response.InvalidForm:
          setFields(Utils.apiToFormErrors(e?.data || {}, (parsedValues.opinionActivities as any[]).map((a, i) => ({
            from: `opinionActivities[${i}]`,
            to: `opinionActivities.${a.initiativeHash}`,
          }))));
          if (Object.keys(e.data).indexOf('s3Key')) {
            setError(intl.formatMessage({
              id:  'NOT_DEFINED',
              defaultMessage: uiTexts?.WORKFLOW_ELEMENT_FORM_VIDEO_UPLOAD_ERROR?.value,
            }));

            return;
          }
          break;
        case QualieAPI.codes.response.QuotaFull:
        case QualieAPI.codes.response.Terminated:
          toNextStep();

          return;
      }
      setError(e?.message || ((uiTexts?.WORKFLOW_ELEMENT_FORM_GENERIC_SERVER_ERROR) ? intl.formatMessage({
        id:  'NOT_DEFINED',
        defaultMessage: uiTexts?.WORKFLOW_ELEMENT_FORM_GENERIC_SERVER_ERROR?.value,
      }) : intl.formatMessage({ id: 'workflowElement.form.genericServerError' })));

      return;
    }

    if (onFinish) {
      onFinish(workflowElement);
    }
  }, [onBeforeFinish, workflowElement, theFileList.length, onFinish, elements, videoS3Path, dispatch, uiTexts, intl, toNextStep]);

  const modifiedElements = useMemo(() => {
    const returnModifiedElements: IWorkflowFormElementElement[] = [];

    elements.forEach(elem => {
      if (elem.name === 'opinionActivities') {
        const element: IWorkflowFormElementElement = {
          ...elem,
          collection: [
            ...(elem.collection || []).map((innerElem, index) => {
              if (index === 0) {
                return {
                  ...innerElem,
                  collection: [
                    ...(innerElem.collection || []).map(innerInnerElem => {
                      if (innerInnerElem.name === 'title') {
                        return {
                          ...innerInnerElem,
                        };
                      }

                      return innerInnerElem;
                    }),
                  ],
                };
              } else {
                return innerElem;
              }
            }),
          ],
        };
        returnModifiedElements.push(element);
      } else {
        returnModifiedElements.push(elem);
      }
    });

    return returnModifiedElements;
  }, [elements]);

  const canSubmit = useMemo(() => {
    return videoS3Path && theFileList.length !== 0;
  }, [theFileList.length, videoS3Path]);

  const processing = useMemo(() => {
    return theFileList.length !== 0 && !videoS3Path;
  }, [theFileList.length, videoS3Path]);

  const setThumbnail = useCallback(() => {
    const thumbnailSpan = document.querySelector('span.anticon.anticon-file');

    if (thumbnailSpan) {
      thumbnailSpan.innerHTML = '<img src="https://qualie-au-project-assets.s3.ap-southeast-2.amazonaws.com/general/default-video-thumbnail.jpg" />';
    }
  }, []);

  useEffect(() => {
    if (canSubmit) {
      setTimeout(setThumbnail, 100);
    }
  }, [canSubmit, setThumbnail]);

  if (hide) {
    return null;
  }

  return (
    <Form
      layout="vertical"
      onFinish={onFormFinish}
      initialValues={initialValues}
      fields={fields}
    >
      {!!error && (
        <Alert
          type="error"
          message={error}
          showIcon
        />
      )}
      <Dragger {...uploadProps}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          {uiTexts?.WORKFLOW_ELEMENT_FORM_ELEMENT_VIDEO_UPLOAD_INSTRUCTIONS?.value}
        </p>
        <p className="ant-upload-hint">
        </p>
      </Dragger>
      {modifiedElements.map(a => (
        <FormElement
          key={a.name}
          workflowElement={a}
        />
      ))}
      <FormActions>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            disabled={!canSubmit}
            loading={processing}
          >{uiTexts?.WORKFLOW_ELEMENT_FORM_ELEMENT_VIDEO_UPLOAD_BUTTON?.value}</Button>
          {onCancel && (
            <Button
              onClick={onCancel}
              style={{marginLeft: 10}}
            >
              Cancel
            </Button>
          )}
        </Form.Item>
      </FormActions>
    </Form>
  );
};

export default UploadVideoOpinionFormWorkflowElement;
