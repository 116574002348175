import _ from 'lodash/fp';
import { IWorkflowFormElementElement } from 'lib/modules/qualieApi/entities/workflow';
import React, { useContext, useMemo } from 'react';
import { Form, Radio } from 'antd';
import { IWorkflowFormElementProps } from './types';
import { AudioMutedOutlined, CloseSquareOutlined, DislikeOutlined, HeartOutlined, LikeOutlined, MehOutlined } from '@ant-design/icons';
import { CommencedWorkflowContext } from 'app/modules/pux/contexts';
import ErrorMessage from '../../../errorMessage';

const Icon: React.FunctionComponent<{ type: string }> = (props) => {
  const { type } = props;

  switch (type) {
    case 'POOR_QUALITY':
      return <AudioMutedOutlined />;
    case 'NOT_RELEVANT':
      return <CloseSquareOutlined />;
    case 'OKAY':
      return <MehOutlined />;
    case 'AWESOME':
      return <HeartOutlined />;
    case 'DISAGREE':
      return <DislikeOutlined />;
    case 'REALLY_GOOD':
      return <LikeOutlined />;
    default:
      return null;
  }
};

const TextRatingRadioFormElement: React.FunctionComponent<IWorkflowFormElementProps<IWorkflowFormElementElement>> = (props) => {
  const { workflowElement, path } = props;
  const options = useMemo(() => _.sortBy('position', workflowElement.options), [workflowElement]);
  const name = useMemo(() => path ? [...path, workflowElement.name] : [workflowElement.name], [path, workflowElement]);
  const { uiTexts } = useContext(CommencedWorkflowContext);

  return (
    <Form.Item
      name={name}
      rules={[
        {
          required: workflowElement.required,
          message: <ErrorMessage message={uiTexts?.IS_REQUIRED?.value} />,
        },
      ]}
    >
      <Radio.Group className="text-rating-radio-group">
        {options.map(option => (
          <Radio.Button
            key={option.value}
            value={option.key}
          >
            <div className="text-rating-radio-content">
              <div className="text-rating-radio-icon">
                <Icon type={option.key} />
              </div>
              <div className="text-rating-radio-title">{option.label}</div>
            </div>
          </Radio.Button>
        ))}
      </Radio.Group>
    </Form.Item>
  );
};

export default TextRatingRadioFormElement;