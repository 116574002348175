import { Col, Row } from 'antd';
import React, { useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

interface IProps { }

const Footer: React.FunctionComponent<IProps> = (props) => {
  const year = useMemo(() => new Date().getFullYear(), []);
  const useFreshChat = useMemo(() => process.env.NODE_ENV!=='production', []);

  const onHelpClick = useCallback(() => {
    try {
      window.olark('api.box.expand');
    } catch (e: any) {
      console.error(e);
    }
  }, []);

  return (
    <Row className="footer">
      <Col className="footer-powered-by">
        <FormattedMessage id="footer.poweredBy" />
      </Col>
      <Col className="footer-info">
        <div className="footer-copyright">
          <FormattedMessage
            id="footer.copyright"
            values={{ year: year }}
          />
        </div>
        <div className="footer-link-privacy">
          <a
            href="https://qualie.com/privacy"
            target="_blank"
            rel="noreferrer"
          >
            <FormattedMessage id="footer.link.privacy" />
          </a>
        </div>
        <div className="footer-help">
          {(!useFreshChat) && (
            <>
              <FormattedMessage id="footer.help" />
              &nbsp;
              <button onClick={onHelpClick}>
                <FormattedMessage id="footer.help.link" />
              </button>
            </>
          )}
        </div>
      </Col>
    </Row>
  );
};

export default Footer;
