import _ from 'lodash/fp';
import { IWorkflowFormElementElement } from 'lib/modules/qualieApi/entities/workflow';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Button, Form, Input, Radio } from 'antd';
import { IWorkflowFormElementProps } from './types';
import { CommencedWorkflowContext } from 'app/modules/pux/contexts';
import { QuestionLabel } from 'app/modules/pux/components/content';
import ErrorMessage from '../../../errorMessage';
import { FullscreenOutlined } from '@ant-design/icons';
import ImageModal from './imageModal';

const ImageRadioFormElement: React.FunctionComponent<IWorkflowFormElementProps<IWorkflowFormElementElement>> = (props) => {
  const { workflowElement, path } = props;
  const options = useMemo(() => _.sortBy('position', workflowElement.options), [workflowElement]);
  const name = useMemo(() => path ? [...path, workflowElement.name] : [workflowElement.name], [path, workflowElement]);
  const { uiTexts } = useContext(CommencedWorkflowContext);
  const [isImageModalOpen, setImageModalOpen] = useState<boolean>(false);
  const [imageModalImageUri, setImageModalImageUri] = useState<string>();

  const onImageClick = useCallback((imageUri?: string) => {
    if (imageUri) {
      setImageModalImageUri(imageUri);
      setImageModalOpen(true);
    }
  }, []);

  const onCancel = useCallback(() => {
    setImageModalImageUri(undefined);
    setImageModalOpen(false);
  }, []);

  return (
    <React.Fragment>
      <Form.Item
        label={<QuestionLabel>{workflowElement.label}</QuestionLabel>}
        name={[...name, 'answer']}
        rules={[
          {
            required: workflowElement.required,
            message: <ErrorMessage message={uiTexts?.IS_REQUIRED?.value} />,
          },
        ]}
      >
        <Radio.Group
          buttonStyle="solid"
          className="form-element-radio-button-image-group"
        >
          {options.map(option => (
            <Radio.Button
              className="form-element-radio-button-image"
              key={option.value}
              value={option.key}
            >
              <Form.Item
                shouldUpdate={true}
                noStyle={true}
              >
                {form => {
                  if (!(option?.type === 'TEXT')) {
                    return (
                      <>
                        <div
                          className="form-element-radio-button-image-link"
                          style={{ backgroundImage: `url('${option.imageUri}')` }}
                        >
                          {option.imageUri && (
                            <div className="banner-element-view-link">
                            <Button
                              ghost={true}
                              className="form-element-radio-button-image-link-fullscreen"
                              onClick={() => onImageClick(option.imageUri)}
                            >
                              <FullscreenOutlined />
                            </Button>
                          </div>
                          )}
                        </div>
                        <div className="form-element-radio-button-image-label">
                          {option.label}
                        </div>
                      </>
                    );
                  }

                  const value = form.getFieldValue([...name, 'answer']);
                  if (value !== option.key) {
                    return option.label;
                  }

                  return (
                    <Form.Item
                      name={[...name, 'other']}
                      rules={[
                        {
                          required: workflowElement.required,
                          message: <ErrorMessage message={uiTexts?.IS_REQUIRED?.value} />,
                        },
                        {
                          max: Number(option.limit),
                          message: <ErrorMessage message={uiTexts?.MAXIMUM_CHARACTERS_EXCEEDED?.value} />,
                        },
                      ]}
                      noStyle={true}
                    >
                      <Input className="form-element-radio-other-input" />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </Radio.Button>
          ))}
        </Radio.Group>
      </Form.Item>
      <ImageModal
        imageUri={imageModalImageUri!}
        isOpen={isImageModalOpen}
        onCancel={onCancel}
      />
    </React.Fragment>
  );
};

export default ImageRadioFormElement;