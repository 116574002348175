import { ApiConfig, ApiResponse, QualieApiUninitialisedError, RequestContext } from './types';
import qs from 'query-string';

let apiConfig: ApiConfig;

const init = (config: ApiConfig) => {
  apiConfig = config;
};

const createRequestContext: <TBody, >(cb: RequestContext) => Promise<ApiResponse<TBody>> = async (cb: RequestContext) => {
  if (!apiConfig) {
    throw new QualieApiUninitialisedError();
  }

  return await cb(apiConfig);
};

/**
 * Escapes and encodes a string to be used as a URI component.
 * @param value The value to be URI encoded.
 */
const euc = (value: string): string => encodeURIComponent(value);
const toUrl = qs.stringifyUrl;

export {
  init,
  createRequestContext,
  euc,
  toUrl,
};