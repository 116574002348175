import { useIsMobile, useMountEffect } from 'app/hooks';
import { IWorkflowElement, IWorkflowFormElementElement } from 'lib/modules/qualieApi/entities/workflow';
import _ from 'lodash';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import cx from 'classnames';
import { IWorkflowFormElementProps } from './types';
import { DragDropContext, Draggable, DraggableProvidedDragHandleProps, Droppable, DropResult } from 'react-beautiful-dnd';
import { CommencedWorkflowContext } from 'app/modules/pux/contexts';
import { DraggableStyle, FormElementName, QualieFile, StimulusType, imageLinkTypes } from 'lib/types';
import { Alert, Button, Form, Input, Modal, Select } from 'antd';
import InitiativeWorkflowElement from '../../../initiative';
import PrerequisiteError from 'app/modules/pux/components/prerequisiteError';
import { imageStimulusTypes } from 'lib/types';
import { FullscreenOutlined, HolderOutlined } from '@ant-design/icons';
import { RuleObject } from 'antd/lib/form';

type Stimuli = {
  hash: string;
  id: number;
  name: string;
  position: number;
  qualieFile: QualieFile;
  stimulusType: StimulusType;
};

interface IInitiative extends IWorkflowElement {
  group: string;
  hash: string;
  id: number;
  internalName: string;
  name: string;
  stimuli: Stimuli[];
}

interface IStimuliModalProps {
  initiative: IInitiative;
  isOpen: boolean;
  onCancel: () => void;
  onValid: (element: IWorkflowElement, valid: boolean) => void;
  stimulusViewed: boolean;
}

const StimuliModal: React.FunctionComponent<IStimuliModalProps> = (props) => {
  const { initiative, isOpen, onCancel, onValid, stimulusViewed } = props;
  const { activityOptions } = useContext(CommencedWorkflowContext);
  const isMobile = useIsMobile();
  const wrapperClassName = useMemo(() => cx({
    'preference-stimuli-overlay-wrapper': true,
    'mobile-layout': isMobile,
  }), [isMobile]);
  const workflowElement: IWorkflowElement = useMemo(() => ({
    name: `initiatives-${initiative.hash}`,
    visible: true,
    workflowElementType: 'COLLECTION',
    collection: [initiative],
  }), [initiative]);

  useMountEffect(() => {
    if (activityOptions?.must_view_initiatives && !stimulusViewed) {
      onValid(workflowElement, false);
    }
  });

  return (
    <Modal
      className={wrapperClassName}
      centered
      visible={isOpen}
      footer={null}
      onCancel={onCancel}
      width="100%"
    >
      <InitiativeWorkflowElement
        workflowElement={workflowElement}
        onValid={onValid}
      />
    </Modal>
  );
};

interface IPreferenceContainerProps {
  dirty: boolean;
  dragHandleProps: DraggableProvidedDragHandleProps;
  index: number;
  initiative: IInitiative;
  initiatives: IWorkflowElement[];
  name: FormElementName;
  onChange: (value: boolean) => void;
}

const PreferenceContainer: React.FunctionComponent<IPreferenceContainerProps> = (props) => {
  const { dirty, dragHandleProps, index, initiative, initiatives, name, onChange } = props;
  const [isStimuliModalOpen, setStimuliModalOpen] = useState<boolean>(false);
  const [stimulusViewed, setStimulusViewed] = useState<boolean>(false);
  const { setPrerequisite, uiTexts } = useContext(CommencedWorkflowContext);
  const stimuli = _.sortBy(initiative.stimuli, ['position']);

  const onInitiativesValid = useCallback((element: IWorkflowElement, valid: boolean) => {
    setStimulusViewed(valid);
    setPrerequisite(element.name, valid ? null : {
      valid: valid,
      error: (!valid && uiTexts?.VIEW_ALL_CONTENT?.value) || null,
      reveal: false,
    });
  }, [setPrerequisite, uiTexts]);

  const onCancel = () => setStimuliModalOpen(false);
  const onClickStimulus = () => setStimuliModalOpen(true);

  const thumbnailSrc = useMemo(() => {
    if (stimuli.length === 0) {
      return;
    }

    return (imageStimulusTypes.includes(stimuli[0].qualieFile.type) || imageLinkTypes.includes(stimuli[0].qualieFile.type))
    ? stimuli[0].qualieFile.path
    : stimuli[0].qualieFile.thumbnail;
  }, [stimuli]);

  const selectOptions: any[] = useMemo(() => _.filter(initiatives, { group: (_.first(initiatives) as any).group }), [initiatives]);

  return (
    <Form.Item
      name={[...name, index]}
      noStyle
      shouldUpdate={true}
    >
      <div className="preference-container-wrapper">
        <div
          className="preference-container"
          {...dragHandleProps}
        >
          <div className="preference-rating">
            <Form.Item shouldUpdate={true}>
              {form => {
                const value = form.getFieldValue(name);

                const onRatingChange = (position: string) => {
                  const list = Array.from(value);
                  const [removed] = list.splice(index, 1);
                  list.splice(Number(position), 0, removed);

                  form.setFieldsValue(_.set({}, name, list));
                  onChange(true);
                };

                return (
                  <div className="preference-rating-wrapper">
                    <Select
                      onChange={onRatingChange}
                      value={dirty ? `${index}` : '-'}
                    >
                      {selectOptions.map((initiative, idx) => (
                        <Select.Option
                          key={initiative.name}
                          value={`${idx}`}
                        >
                          {idx + 1}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>
                );
              }}
            </Form.Item>
          </div>
          {thumbnailSrc && (
            <div className="preference-stimulus-wrapper">
              <div
                className="preference-stimulus-link"
                onClick={onClickStimulus}
                style={{ backgroundImage: `url('${thumbnailSrc}')` }}
              >
                <div className="preference-view-link">
                  <Button ghost={true}>
                    <FullscreenOutlined />
                  </Button>
                </div>
              </div>
            </div>
          )}
          <div className="preference-initiative-text">
            <p>{initiative.name}</p>
            <Form.Item
              noStyle={true}
              shouldUpdate={true}
            >
              {form => {
                const error = form.getFieldError('opinionActivities');

                return error.length > 0 && (
                  <Alert
                    showIcon
                    type="error"
                    className="prerequisite-error"
                    message={error}
                  />
                );
              }}
            </Form.Item>
            <PrerequisiteError target={`initiatives-${initiative.hash}`} />
          </div>
          <div className="preference-drag-drop-toggle">
            <HolderOutlined />
          </div>
        </div>
        <StimuliModal
          initiative={initiative}
          isOpen={isStimuliModalOpen}
          onCancel={onCancel}
          onValid={onInitiativesValid}
          stimulusViewed={stimulusViewed}
        />
      </div>
    </Form.Item>
  );
};

const PreferenceOpinionFormElement: React.FunctionComponent<IWorkflowFormElementProps<IWorkflowFormElementElement>> = (props) => {
  const { dependencies, workflowElement, path } = props;
  const name = useMemo(() => path ? [...path, workflowElement.name] : [workflowElement.name], [path, workflowElement]);
  const isMobile = useIsMobile();
  const [dirty, setDirty] = useState<boolean>(false);
  const { uiTexts } = useContext(CommencedWorkflowContext);
  const wrapperClassName = useMemo(() => cx({
    'preference-opinion-form-element': true,
    'mobile-layout': isMobile,
  }), [isMobile]);
  const initiatives = _.orderBy((dependencies?.initiatives?.collection || []), ['group', 'name'], ['asc', 'asc']);
  const itemStyle = (isDragging: boolean, draggableStyle: DraggableStyle) => ({
    padding: 8,
    background: isDragging ? '#FFFFFF' : 'none',
    ...draggableStyle,
  });
  const listStyle = (isDraggingOver: boolean) => ({
    padding: 8,
    background: isDraggingOver ? '#E8E8E8' : 'none',
  });

  const rules = useMemo(() => ([
    {
      validator: async (rule: RuleObject, value: boolean) => {
        if (!dirty) {
          throw new Error(uiTexts?.REQUIRED?.value);
        }
      },
    },
  ]), [dirty, uiTexts]);

  const onPreferenceChange = useCallback((value: boolean) => {
    setDirty(value);
  }, []);

  return (
    <Form.Item
      className={wrapperClassName}
      shouldUpdate={true}
    >
      {form => {
        const value = form.getFieldValue(name);

        const onDragEnd = (result: DropResult) => {
          if (!result.destination) {
            return;
          }

          const list = Array.from(value);
          const [removed] = list.splice(result.source.index, 1);
          list.splice(result.destination.index, 0, removed);

          form.setFieldsValue(_.set({}, name, list));
          setDirty(true);
        };

        return (
          <React.Fragment>
            <Form.Item
              noStyle={true}
              name={name}
              rules={rules}
            >
              <Input hidden={true} />
            </Form.Item>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={listStyle(snapshot.isDraggingOver)}
                  >
                    {value.map((item: string, index: number) => {
                      const matchedInitiative = _.find(initiatives, { hash: item }) as IInitiative;

                      return (
                        <Draggable
                          key={item}
                          draggableId={item}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              style={itemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              <PreferenceContainer
                                dirty={dirty}
                                dragHandleProps={provided.dragHandleProps!}
                                index={index}
                                initiative={matchedInitiative}
                                name={name}
                                initiatives={initiatives!}
                                onChange={onPreferenceChange}
                              />
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </React.Fragment>
        );
      }}
    </Form.Item>
  );
};

export default PreferenceOpinionFormElement;
