import _ from 'lodash';
import { Alert, Button, Form, FormProps } from 'antd';
import { useAppDispatch } from 'app/hooks';
import { useSortedWorkflowElements } from 'app/modules/pux/hooks';
import { IWorkflowElement, IWorkflowFormElement } from 'lib/modules/qualieApi/entities/workflow';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import FormElement from '../formElement';
import { IWorkflowFormElementProps } from '../formElement/types/types';
import { workflowsActions } from 'app/modules/workflows';
import QualieAPI from 'lib/modules/qualieApi';
import { useIntl } from 'react-intl';
import FormActions from 'app/modules/pux/components/layout/formActions';
import Utils from 'lib/utils';
import { IncentiveCode } from 'app/modules/pux/components/content';
import { Dictionary } from 'lib/types';
import { CommencedWorkflowContext } from 'app/modules/pux/contexts';

const getInitialValues = (elements: IWorkflowElement[]) => elements.reduce<Dictionary>((acc, curr) => {
  acc[curr.name] = curr.collection
    ? getInitialValues(curr.collection)
    : curr.value;

  return acc;
}, {});


const ThankYouFormWorkflowElement: React.FunctionComponent<IWorkflowFormElementProps<IWorkflowFormElement>> = (props) => {
  const { workflowElement, onFinish, onBeforeFinish } = props;
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const { uiTexts } = useContext(CommencedWorkflowContext);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState<string | null>();
  const [fields, setFields] = useState<FormProps['fields']>();
  const { toNextStep } = useContext(CommencedWorkflowContext);
  const elements = useSortedWorkflowElements(workflowElement.elements);
  const initialValues = useMemo(() => getInitialValues(elements), [elements]);
  const autoSubmit = useMemo(() => elements?.find(a => a.name === 'auto_submit')?.value === 'true', [elements]);
  const hasIncentive = useMemo(() => initialValues.display_code != null, [initialValues]);

  const onFormFinish = useCallback(async (values) => {
    if (onBeforeFinish && !(await onBeforeFinish(workflowElement))) {
      return;
    }

    const parsedValues = {
      ...values,
      questionAnswers: _.map(values.questionAnswers, (v, k) => ({
        questionHash: k,
        selectedAnswers: {
          [k]: v[k],
        },
      })),
    };

    setSubmitted(true);
    setError(null);
    setFields(fields => fields?.map(a => ({
      name: a.name,
      errors: [],
    })));

    try {
      await dispatch(workflowsActions.submitWorkflowFormElement({
        actionUrl: workflowElement.actionURL,
        form: parsedValues,
      })).unwrap();
    } catch (e: any) {
      setSubmitted(false);
      switch (e?.code) {
        case QualieAPI.codes.response.InvalidForm:
          setFields(Utils.apiToFormErrors(e?.data || {}, (parsedValues.questionAnswers as any[]).map((a, i) => ({
            from: `questionAnswers[${i}].selectedAnswers`,
            to: `questionAnswers.${a.questionHash}`,
          }))));
          break;
        case QualieAPI.codes.response.QuotaFull:
        case QualieAPI.codes.response.Terminated:
          toNextStep();

          return;
      }
      setError(e?.message || ((uiTexts?.WORKFLOW_ELEMENT_FORM_GENERIC_SERVER_ERROR) ? intl.formatMessage({
        id:  'NOT_DEFINED',
        defaultMessage: uiTexts?.WORKFLOW_ELEMENT_FORM_GENERIC_SERVER_ERROR?.value,
      }) : intl.formatMessage({ id: 'workflowElement.form.genericServerError' })));

      return;
    }

    if (onFinish) {
      onFinish(workflowElement);
    }
  }, [dispatch, intl, workflowElement, onFinish, onBeforeFinish, toNextStep, uiTexts]);

  useEffect(() => {
    if (!submitted && autoSubmit) {
      onFormFinish(initialValues);
    }
  }, [submitted, onFormFinish, initialValues, autoSubmit]);

  useEffect(() => {
    setSubmitted(false);
  }, [initialValues]);

  return (
    <Form
      layout="vertical"
      onFinish={onFormFinish}
      initialValues={initialValues}
      fields={fields}
    >
      {!!error && (
        <Alert
          type="error"
          message={error}
          showIcon
        />
      )}
      {hasIncentive && (
        <IncentiveCode
          name="incentiveConfirmation"
          incentive={initialValues.display_code}
          title={initialValues.display_code_description}
        />
      )}
      {elements.map(element => (
        <FormElement
          key={element.name}
          workflowElement={element}
        />
      ))}
      {!autoSubmit && <FormActions>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            disabled={submitted}
          >{workflowElement.submitButtonText}</Button>
        </Form.Item>
      </FormActions>}
    </Form>
  );
};

export default ThankYouFormWorkflowElement;
