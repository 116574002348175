import Rollbar from 'rollbar';

const rollbar = new Rollbar({
  accessToken: process.env.REACT_APP_ROLLBAR_CLIENT_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  enabled: process.env.REACT_APP_ROLLBAR_ENABLED === '1',
  payload: {
    environment: process.env.REACT_APP_ROLLBAR_ENVIRONMENT,
  },
});

export default rollbar;