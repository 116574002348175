import { Alert } from 'antd';
import React, { useContext } from 'react';
import { CommencedWorkflowContext } from '../contexts';

interface IProps {
  target: string;
}

const PrerequisiteError: React.FunctionComponent<IProps> = (props) => {
  const { target } = props;
  const commencedWorkflowContext = useContext(CommencedWorkflowContext);
  const prerequisite = target != null && commencedWorkflowContext.prerequisites ? commencedWorkflowContext.prerequisites[target] : null;

  return prerequisite?.reveal
    ? (
      <Alert
        showIcon
        type="error"
        className="prerequisite-error"
        message={prerequisite.error}
      />
    )
    : null;
};

export default PrerequisiteError;
