import {
  Workflow,
} from './endpoints';
import { init } from './config';
import { RESPONSE_CODES } from './types';

const QualieAPI = {
  init: init,
  codes: {
    response: RESPONSE_CODES,
  },
  Workflow: Workflow,
};

export default QualieAPI;
