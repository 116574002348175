import { IWorkflowElement } from 'lib/modules/qualieApi/entities/workflow';
import React from 'react';
import IWorkflowElementProps from '../IWorkflowElementProps';

const KeyValueWorkflowElement: React.FunctionComponent<IWorkflowElementProps<IWorkflowElement>> = () => {

  return (
    <React.Fragment />
  );
};

export default KeyValueWorkflowElement;
