import React from 'react';

const Guidelines: React.FunctionComponent = () => {

  return (
    <div className="qualie-camera-guidelines">
      <div className="qualie-camera-guideline" />
      <div className="qualie-camera-guideline" />
      <div className="qualie-camera-guideline" />
      <div className="qualie-camera-guideline" />
    </div>
  );
};

export default Guidelines;