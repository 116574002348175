import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import type { RootState, AppDispatch } from '../store';
import { useLocation } from 'react-router';
import qs from 'query-string';
import { useWindowWidth } from '@react-hook/window-size';

const useAppDispatch = () => useDispatch<AppDispatch>();

const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

/** Allows an effect to be run once on mount. Does not run again while the component is mounted. */
// eslint-disable-next-line react-hooks/exhaustive-deps
const useMountEffect = (cb: React.EffectCallback) => useEffect(cb, []);

const useQueryParams = <TQuery,>() => {
  const location = useLocation();
  const queryParams: TQuery = useMemo(() => qs.parse(location.search, { parseBooleans: true }) as any, [location]);

  return queryParams;
};

/**
 * Tracks the provided URL and adds it as a script tag to the body if it isn't already set.
 * @param url
 */
const useExternalScript = (url: string, destroyOnUnmount?: boolean, onLoad?: () => void) => {
  const [scriptElement, setScriptElement] = useState<HTMLScriptElement>();

  useEffect(() => {
    if (!url) {
      return;
    }

    const id = `external-script-${btoa(url)}`;
    if (document.getElementById(id)) {
      return;
    }

    const script = document.createElement('script');
    script.id = id;
    script.type = 'text/javascript';
    script.src = url;
    script.async = true;

    document.body.appendChild(script);

    setScriptElement(script);
  }, [url]);

  useEffect(() => {
    if (scriptElement) {
      scriptElement.onload = onLoad || (() => { });
    }
  }, [scriptElement, onLoad]);

  useEffect(() => () => {
    if (destroyOnUnmount) {
      scriptElement?.remove();
    }
  }, [scriptElement, destroyOnUnmount]);
};

const VERTICAL_THRESHOLD = 1200;
const MOBILE_THRESHOLD = 600;

const useIsMobile = () => {
  const width = useWindowWidth();

  return width < MOBILE_THRESHOLD;
};

const useIsVerticalLayout = () => {
  const width = useWindowWidth();

  return width < VERTICAL_THRESHOLD;
};

const useCountdown = () => {
  const [value, setValue] = useState<number | null>(null);
  const onSetCountdown = useCallback((value: number) => {
    setValue((Number.isNaN(value) || value == null) ? null : value);
  }, []);

  useEffect(() => {
    if (value == null) {
      return;
    }

    const next = value > 0 ? value - 1 : null;
    const handle = setTimeout(() => {
      setValue(next);
    }, 1000);

    return () => {
      clearTimeout(handle);
    };
  }, [value]);

  return [
    value,
    onSetCountdown,
  ] as const;
};

export {
  useAppDispatch,
  useAppSelector,
  useMountEffect,
  useQueryParams,
  useExternalScript,
  useIsMobile,
  useIsVerticalLayout,
  useCountdown,
};
