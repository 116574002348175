import { IWorkflowImageElement } from 'lib/modules/qualieApi/entities/workflow';
import React, { useEffect } from 'react';
import IWorkflowElementProps from '../IWorkflowElementProps';

interface IProps extends IWorkflowElementProps<IWorkflowImageElement> {
  active?: boolean;
}

const ImageWorkflowElement: React.FunctionComponent<IProps> = (props) => {
  const { workflowElement, onValid, active } = props;

  useEffect(() => {
    if (onValid && (active || active === undefined)) {
      onValid(workflowElement, true);
    }
  }, [active, onValid, workflowElement]);

  return (
    <div className="workflow-element-image">
      <div className="workflow-element-image-content">
        <img
          src={workflowElement.sourceUri || workflowElement.path}
          alt={workflowElement.name}
        />
      </div>
    </div>
  );
};

export default ImageWorkflowElement;
