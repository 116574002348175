import { Button } from 'antd';
import { CommencedWorkflowContext } from 'app/modules/pux/contexts';
import { useKeyedWorkflowElements } from 'app/modules/pux/hooks';
import { IWorkflowCarouselElement, IWorkflowElement, IWorkflowFormElement, IWorkflowInitiativeElement } from 'lib/modules/qualieApi/entities/workflow';
import _ from 'lodash/fp';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { WorkflowActivitySubtitle, WorkflowActivityTitle } from '../../content';
import DragConfirmOverlay from '../../dragConfirmOverlay';
import BasicWorkflowForm from '../../layout/basicWorkflowForm';
import FormActions from '../../layout/formActions';
import SplitWorkflowForm from '../../layout/splitWorkflowForm';
import PrerequisiteError from '../../prerequisiteError';
import WorkflowElement from '../../workflowElement';
import BannerWorkflowElement from '../../workflowElement/types/contentBanner';
import PreferenceOpinionDragMultiFormWorkflowElement from '../../workflowElement/types/form/types/preferenceOpinionDragMulti';
import IWorkflowActivityProps from '../IWorkflowActivityProps';
import WorkflowActivityAdvanceRetreat from '../../content/workflowActivityTestAdvanceRetreat';

type Elements = {
  explainer: IWorkflowCarouselElement;
  explainerButtonText: IWorkflowElement;
  title: IWorkflowElement;
  subTitle: IWorkflowElement;
  preferenceOpinionForm: IWorkflowFormElement;
  initiatives: IWorkflowInitiativeElement;
  showAdvanceRetreat: IWorkflowElement;
  showAdvance: IWorkflowElement;
  showRetreat: IWorkflowElement;
};

const PreferenceOpinionDragMultiWorkflowActivity: React.FunctionComponent<IWorkflowActivityProps> = (props) => {
  const { workflowActivity } = props;
  const elements = useKeyedWorkflowElements<Elements>(workflowActivity.workflowElements);
  const { setPrerequisite, uiTexts, checkPrerequisites, toNextStep, advanceInTest, retreatInTest, toNextStepInTest } = useContext(CommencedWorkflowContext);
  const [explainerComplete, setExplainerComplete] = useState(false);
  const showExplainer = !explainerComplete && !!elements.explainer;
  const explainers = useMemo(() => _.sortBy('position', elements.explainer?.carouselHtmlElements || []), [elements]);
  const dependencies = useMemo(() => ({
    initiatives: elements.initiatives,
  }), [elements]);

  const onExplainerValid = useCallback((element: IWorkflowElement, valid: boolean, errorMessage?: string) => {
    setPrerequisite(element.name, valid ? null : {
      valid: valid,
      error: (!valid && (errorMessage || uiTexts?.VIEW_ALL_EXPLAINER_SLIDES?.value)) || null,
      reveal: false,
    });
  }, [setPrerequisite, uiTexts]);

  const onExplainerFinish = useCallback(() => {
    if (elements.showAdvanceRetreat?.value === 'true' || checkPrerequisites()) {
      setExplainerComplete(true);
    }
  }, [checkPrerequisites, elements]);

  return (
    <SplitWorkflowForm focus="empty">
      <SplitWorkflowForm.Filled>
        <WorkflowActivityTitle>{elements.title.value}</WorkflowActivityTitle>
        <WorkflowActivitySubtitle>{elements.subTitle.value}</WorkflowActivitySubtitle>
        {elements.showAdvanceRetreat?.value === 'true' &&
          <WorkflowActivityAdvanceRetreat
            onAdvance={advanceInTest}
            onRetreat={retreatInTest}
            allowAdvance={elements.showAdvance?.value === 'true'}
            allowRetreat={elements.showRetreat?.value === 'true'}
          />}
      </SplitWorkflowForm.Filled>
      <SplitWorkflowForm.Empty header={!showExplainer && !!elements.explainer && <BannerWorkflowElement explainers={explainers} />}>
        {showExplainer && (
          <React.Fragment>
            <WorkflowElement
              workflowElement={elements.explainer}
              onValid={onExplainerValid}
            />
            <PrerequisiteError target="explainer" />
            <FormActions>
              <Button
                type="primary"
                onClick={onExplainerFinish}
              >{elements.explainerButtonText.value}</Button>
            </FormActions>
          </React.Fragment>
        )}
        {!showExplainer && (
          <DragConfirmOverlay>
            <BasicWorkflowForm size="default">
              <PreferenceOpinionDragMultiFormWorkflowElement
                dependencies={dependencies}
                workflowElement={elements.preferenceOpinionForm}
                onBeforeFinish={checkPrerequisites}
                onFinish={(elements.showAdvanceRetreat?.value === 'true') ? () => toNextStepInTest(false) : toNextStep}
                skipOnBeforeFinish={elements.showAdvanceRetreat?.value === 'true'}
              />
            </BasicWorkflowForm>
          </DragConfirmOverlay>
        )}
      </SplitWorkflowForm.Empty>
    </SplitWorkflowForm>
  );
};

export default PreferenceOpinionDragMultiWorkflowActivity;
