import { IFlags } from 'app/modules/workflows/store/types';
import { IParticipant } from 'lib/modules/qualieApi/entities/participant';
import { IWorkflowActivityOptions, IWorkflowElement } from 'lib/modules/qualieApi/entities/workflow';
import { Dictionary } from 'lib/types';
import React from 'react';

interface ICommencedWorkflowContextPrerequisite {
  valid: boolean;
  reveal: boolean;
  error?: string | null;
}

interface ICommencedWorkflowContextPrerequisites extends Dictionary<ICommencedWorkflowContextPrerequisite> { }

interface ICommencedWorkflowContext {
  toNextStep: () => void;
  toNextStepInTest: (reverse: any) => void;
  advance: () => void;
  advanceInTest: () => void;
  retreatInTest: () => void;
  workflowId?: string;
  flags: IFlags;
  participant?: IParticipant;
  uiTexts?: Dictionary<IWorkflowElement>;
  showVideoControls: boolean;
  prerequisites?: ICommencedWorkflowContextPrerequisites;
  setPrerequisite: (key: string, prerequisite?: ICommencedWorkflowContextPrerequisite | null) => void;
  checkPrerequisites: () => boolean;
  activityOptions: IWorkflowActivityOptions;
  ready: boolean;
  /** Called by blocking activities when they are ready to be displayed */
  onReady: () => void;
}

const CommencedWorkflowContext = React.createContext<ICommencedWorkflowContext>({
  toNextStep: () => {
    throw new Error('Next step called but no function provided.');
  },
  toNextStepInTest: (reverse: any) => {
    throw new Error('Next step in test called but no function provided.');
  },
  advance: () => {
    throw new Error('Advance called but no function provided.');
  },
  advanceInTest: () => {
    throw new Error('AdvanceInTest called but no function provided.');
  },
  retreatInTest: () => {
    throw new Error('RetreatInTest called but no function provided.');
  },
  flags: {
    pp: 'QLE',
    cookie: false,
    flowName: 'OFF_CAM',
    enableUpload: false,
    recordSessions: false,
  },
  showVideoControls: false,
  setPrerequisite: () => { throw new Error('Set Prerequisite called but no function provided.'); },
  checkPrerequisites: () => { throw new Error('Check Prerequisites called but no function provided.'); },
  activityOptions: {},
  ready: false,
  onReady: () => { },
});

export type {
  ICommencedWorkflowContext,
  ICommencedWorkflowContextPrerequisite,
  ICommencedWorkflowContextPrerequisites,
};

export default CommencedWorkflowContext;
