import { current } from '@reduxjs/toolkit';
import { Progress } from 'antd';
import React, { useEffect, useState } from 'react';

interface IProps {
  text: string;
  onValid?: (valid: boolean) => void;
  reveal?: boolean;
}

const getWordCount = (text: string): number =>
  text
    //exclude  start and end white-space
    .replace(/(^\s*)|(\s*$)/gi, '')
    //2 or more space to 1
    .replace(/[ ]{2,}/gi, ' ')
    // exclude newline with a start spacing
    .replace(/\n /, '\n')
    .split(' ')
    .filter(str => !!str)
    .length;


/**
 * The average reading speed is 250 words per minute. We're going to
 * guess that anyone reading faster than this value below probably didn't read this
 * properly.
 */
const EXPECTED_WPM = 500;
const TICK_RATE = 1000 / 24;

const RequiredReadingBlock: React.FunctionComponent<IProps> = (props) => {
  const { text, onValid, reveal } = props;
  const [valid, setValid] = useState(false);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [targetTime, setTargetTime] = useState<number>();

  useEffect(() => {
    const words = getWordCount(text);
    setTargetTime((words / EXPECTED_WPM) * 60 * 1000);
  }, [text]);

  useEffect(() => {
    if (targetTime == null) {
      return;
    }

    const interval = setInterval(() => {
      setCurrentTime(value => value + TICK_RATE);
    }, TICK_RATE);

    return () => {
      clearInterval(interval);
    };
  }, [targetTime]);

  useEffect(() => {
    if (targetTime != null && currentTime >= targetTime) {
      setValid(true);
      setTargetTime(undefined);
    }
  }, [targetTime, currentTime]);

  useEffect(() => {
    onValid?.(valid);
  }, [onValid, valid]);

  return (
    <div className="required-reading-block">
      <div className="required-reading-block-text">{text}</div>
      {reveal && !!current && !!targetTime && (
        <Progress
          percent={(currentTime / targetTime) * 100}
          showInfo={false}
        />
      )}
    </div>
  );
};

export default RequiredReadingBlock;
