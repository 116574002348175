import { Checkbox, Form, Radio, Select, SelectProps } from 'antd';
import { RuleObject } from 'antd/lib/form';
import { useIsMobile } from 'app/hooks';
import { CommencedWorkflowContext } from 'app/modules/pux/contexts';
import { IWorkflowFormElementElement, IWorkflowFormElementElementOption } from 'lib/modules/qualieApi/entities/workflow';
import _ from 'lodash/fp';
import React, { useContext, useMemo } from 'react';
import cx from 'classnames';
import { useIntl } from 'react-intl';
import { WORKFLOW_ELEMENT_FORM_ELEMENT_TYPES } from '.';
import { IWorkflowFormElementProps } from './types';
import { QuestionLabel } from 'app/modules/pux/components/content';

interface IMatrixRowProps {
  headers: IWorkflowFormElementElementOption[];
  path: (string | number)[];
  workflowElement: IWorkflowFormElementElement;
}

const MatrixRow: React.FunctionComponent<IMatrixRowProps> = (props) => {
  const { headers, workflowElement, path } = props;
  const { uiTexts } = useContext(CommencedWorkflowContext);
  const name = useMemo(() => path ? [...path, workflowElement.name] : [workflowElement.name], [path, workflowElement]);
  const sorted = useMemo(() => _.sortBy('position', workflowElement.options), [workflowElement.options]);
  const FormInput = useMemo(() => {
    switch (workflowElement.inputType) {
      case WORKFLOW_ELEMENT_FORM_ELEMENT_TYPES.Radio:
        return Radio;
      default:
        return Checkbox;
    };
  }, [workflowElement.inputType]);
  const intl = useIntl();
  const isMobile = useIsMobile();

  const rules = useMemo(() => ([
    {
      validator: async (rule: RuleObject, value: any) => {
        if (workflowElement.required && (!value || (_.isArray(value) && value.length === 0))) {
          throw new Error(uiTexts?.REQUIRED?.value);
        }
      },
    },
    ...(workflowElement.inputType === WORKFLOW_ELEMENT_FORM_ELEMENT_TYPES.Checkbox ? [{
      validator: async (rule: RuleObject, value: any) => {
        const selectedCount = _.isArray(value) ? value.length : 0;
        const min = Math.max(workflowElement.min, workflowElement.required ? 1 : 0);
        if (min && selectedCount < min) {
          throw new Error((uiTexts?.WORKFLOW_ELEMENT_FORM_ELEMENT_MATRIX_CHECKBOX_MIN_LIMIT) ? intl.formatMessage({
            id:  'NOT_DEFINED',
            defaultMessage: uiTexts?.WORKFLOW_ELEMENT_FORM_ELEMENT_MATRIX_CHECKBOX_MIN_LIMIT?.value,
          }, {min: min}) : intl.formatMessage({ id: 'workflowElement.formElement.matrix.checkbox.minLimit' }, {
            min: min,
          }));
        } else if (workflowElement.max && selectedCount > workflowElement.max) {
          throw new Error((uiTexts?.WORKFLOW_ELEMENT_FORM_ELEMENT_MATRIX_CHECKBOX_MAX_LIMIT) ? intl.formatMessage({
            id:  'NOT_DEFINED',
            defaultMessage: uiTexts?.WORKFLOW_ELEMENT_FORM_ELEMENT_MATRIX_CHECKBOX_MAX_LIMIT?.value,
          }, {max: workflowElement.max})  : intl.formatMessage({ id: 'workflowElement.formElement.matrix.checkbox.maxLimit' }, {
            max: workflowElement.max,
          }));
        }
      },
    }] : []),
  ]), [intl, workflowElement, uiTexts]);

  if (isMobile) {
    let selectProps: Partial<SelectProps<string>> = {
      showArrow: true,
    };
    if (workflowElement.inputType === WORKFLOW_ELEMENT_FORM_ELEMENT_TYPES.Checkbox) {
      selectProps = {
        ...selectProps,
        maxTagCount: 'responsive',
        mode: 'multiple',
      };
    }

    return (
      <Form.Item
        className="matrix-form-mobile-row"
        label={<QuestionLabel>{workflowElement.label}</QuestionLabel>}
        name={name}
        rules={rules}
      >
        <Select {...selectProps}>
          {headers?.map((header, index) => (
            <Select.Option
              className="matrix-form-mobile-row-option"
              key={`${workflowElement.value}-${header.value}`}
              value={sorted[index].key}
            >
              {header.label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    );
  }

  return (
      <Form.Item
        name={name}
        noStyle={true}
        dependencies={[name, ...(sorted || []).map(a => [...name, a.key])]}
        rules={rules}
      >
        <FormInput.Group className="matrix-form-element-body-row">
          <Form.Item
            shouldUpdate={true}
            noStyle
          >
            {form => {
              const errors = form.getFieldError(name);

              return (
                <div className="matrix-form-element-body-row-cell-label">
                  <p>{workflowElement.label}</p>
                  {errors?.length ? (<span className="matrix-form-element-body-row-error">{errors[0]}</span>) : null}
                </div>
              );
            }}
          </Form.Item>
          {sorted?.map(option => (
            <div
              key={option.key}
              className="matrix-form-element-body-row-cell"
            >
              <Form.Item valuePropName="checked">
                <FormInput value={option.key} />
              </Form.Item>
            </div>
          ))}
      </FormInput.Group>
    </Form.Item>
  );
};

const MatrixFormElement: React.FunctionComponent<IWorkflowFormElementProps<IWorkflowFormElementElement>> = (props) => {
  const { workflowElement, path } = props;
  const name = useMemo(() => path ? [...path, workflowElement.name] : [workflowElement.name], [path, workflowElement]);
  const sorted = useMemo(() => _.sortBy('position', workflowElement.collection), [workflowElement.collection]);
  const nonHiddenSorted = sorted.filter((value) => value.inputType !== 'HIDDEN');
  const headers = useMemo(() => _.sortBy('position', nonHiddenSorted?.[0]?.options), [nonHiddenSorted]);
  const isMobile = useIsMobile();
  const wrapperClassName = useMemo(() => cx({
    'matrix-form-element': true,
    'mobile-layout': isMobile,
  }), [isMobile]);

  return (
    <div className={wrapperClassName}>
      <div className="matrix-form-element-inner">
        {!isMobile && (
          <div className="matrix-form-element-header-row">
            <div className="matrix-form-element-header-row-cell" />
            {headers.map(option => (
              <div
                key={option.label}
                className="matrix-form-element-header-row-cell"
              >{option.label}</div>
            ))}
          </div>
        )}
        {nonHiddenSorted.map(a => (
          <MatrixRow
            key={a.position}
            headers={headers}
            path={name}
            workflowElement={a}
          />
        ))}
      </div>
    </div>
  );
};

export default MatrixFormElement;
