enum CommenceWorkflowCode {
  SUCCESS = '20',
  NO_CONTENT = '21',
  NOT_FOUND = '23',
  FAILED = '40',
  AGE_NOT_ALLOWED = '41',
  INVALID_FORM = '42',
  QUOTA_FULL = '43',
  PARTICIPANT_EXISTS = '44',
  PARTICIPANT_TERMINATED = '45',
  PROVIDER_NOT_ALLOWED = '47',
  TERMINATED = '41',
};

enum VideoProvider {
  VideoJs = 'VIDEOJS',
  Vimeo = 'VIMEO',
  Wistia = 'WISTIA',
  Youtube = 'YOUTUBE',
}

enum WorkflowFormElementElementOptionPattern {
  Email = 'EMAIL',
};

interface IStimuli {
  id: number | null;
  hash: string;
  name: string;
  position: number;
  stimulusType: string;
  path: string | null;
  imageLink: string | null;
  qualieHash: string | null;
  vimeoHash: string | null;
  wistiaHash: string | null;
}

interface IStimulus {
  stimuli: IStimuli[];
}

interface IWorkflowActivityOptions {
  locationRequired?: boolean;
  surveyType?: string;
  autoSubmit?: boolean;
  must_view_initiatives?: boolean;
  this_one_text?: string;
  randomise_answers?: boolean;
  visibilityOverrideType?: string;
  stimulus?: IStimulus;
}

interface IWorkflowElement {
  name: string;
  visible: boolean;
  workflowElementType: string;
  label?: string;
  value?: string;
  collection?: IWorkflowElement[];
  position?: string;
}

interface IWorkflowCameraTagElement extends IWorkflowElement {
  recordLength: string;
  cameraTagOptions: any;
}

interface IWorkflowQualieCameraElement extends IWorkflowElement {
  recordLength: string;
  allowUpload: boolean;
}

interface IWorkflowInitiativeElement extends IWorkflowElement { }

interface IWorkflowStimulusElement extends IWorkflowElement {
  stimulus: IStimulus;
}

interface IWorkflowCarouselSlideElement {
  position: string;
  type: string;
  provider?: VideoProvider
  videoId?: string;
  height?: string;
  width?: string;
  thumbnail?: string;
  path?: string;
  link?: string;
  image?: any;
}

interface IWorkflowCarouselElement extends IWorkflowElement {
  carouselHtmlElements: IWorkflowCarouselSlideElement[];
}

interface IWorkflowFormElementElementOption {
  label: string;
  name: string;
  position: number;
  value: string;
  key: string;
  type?: string;
  maxLength?: number;
  showRemaining?: boolean;
  pattern?: WorkflowFormElementElementOptionPattern;
  exclusive?: boolean;
  limit?: string;
  imageUri?: string;
}

interface IWorkflowFormElementElement extends IWorkflowElement {
  label: string;
  type?: string;
  inputType?: string;
  options?: IWorkflowFormElementElementOption[];
  collection?: IWorkflowFormElementElement[];
  required?: boolean;
  min: number;
  max: number;
  tag?: string;
  labelOverride?: string;
}

interface IWorkflowFormElement extends IWorkflowElement {
  actionURL: string;
  formType: string;
  elements: IWorkflowFormElementElement[];
  submitButtonText: string;
}

interface IWorkflowExternalVideoElement extends IWorkflowElement {
  provider: string;
  width: string;
  height: string;
  thumbnail: string;
  videoId: string;
}

interface IWorkflowImageElement extends IWorkflowElement {
  path: string;
  sourceUri: string;
}

interface IWorkflowImageLinkElement extends IWorkflowElement {
  path: string;
  sourceUri: string;
  link?: string;
  image?: any;
}

type IThemeConfig = {
  /**
   * Set the basic core colours of the theme
   */
  colours: {
    primary: string;
    textLight: string;
    textDark: string;
    backgroundStrong: string;
    backgroundSoft: string;
  };
  font?: {
    family: string;
    resource?: string;
  };
  /**
   * A collection of raw CSS style overrides if the basic theme configurations can't
   * do something a client wants specifically.
   */
  rawStyles?: string;
  direction?: string;
};

interface IWorkflowActivity {
  /** TODO: Type me */
  activityConditions: any;
  activityOptions: IWorkflowActivityOptions;
  /** TODO: Type me */
  advancedActivityConditions: any;
  flowActivities: number;
  lastActivity: boolean;
  layoutType: string;
  position: number;
  themeType: string;
  workflowActivityType: string;
  workflowElements: IWorkflowElement[];
  theme?: IThemeConfig;
  recordSessions?: boolean;
  participantId?: string;
};

interface ICommencedWorkflow {
  code: CommenceWorkflowCode;
  message: string;
  data: IWorkflowActivity[];
};

interface IReviewVideoElement {
  duration: string;
  filmedVideoType: string;
  hash: string;
  height: string;
  width: string;
  id: number;
  key: string;
  name: string;
  path: string;
  retryCount: number;
  sourceUri: string;
  status: string;
  thumbnail: string;
  type: string;
  videoProvider: string;
}

interface IReviewImageFileElement {
  id: number;
  hash: string;
  sourceUri: string;
  name: string;
  path: string;
  type: string;
}

interface IReviewOpinionActivity {
  hash: string;
  opinionType: string;
  reviewable: boolean;
  video: IReviewVideoElement;
}

interface IReviewVideoOpinionActivity extends IReviewOpinionActivity {
  video: IReviewVideoElement;
}


interface IReviewImageOpinionActivity extends IReviewOpinionActivity {
  imageFile: IReviewImageFileElement;
}

interface IReviewRatingOption extends IWorkflowFormElementElementOption { }

interface IReviewVideo {
  opinionActivity: IReviewVideoOpinionActivity;
  ratings: IReviewRatingOption[];
}

interface IReviewText {
  opinionActivity: IReviewOpinionActivity;
  questionAnswer: string;
  ratings: IReviewRatingOption[];
}

interface IReviewImage {
  imageFile: IReviewImageFileElement;
  opinionActivity: IReviewImageOpinionActivity;
  title: string;
  ratings: IReviewRatingOption[];
}

export {
  WorkflowFormElementElementOptionPattern,
};

export type {
  IWorkflowElement,
  IWorkflowCameraTagElement,
  IWorkflowQualieCameraElement,
  IWorkflowInitiativeElement,
  IWorkflowStimulusElement,
  IWorkflowCarouselElement,
  IWorkflowCarouselSlideElement,
  IWorkflowFormElementElement,
  IWorkflowFormElementElementOption,
  IWorkflowFormElement,
  IWorkflowExternalVideoElement,
  IWorkflowImageElement,
  IWorkflowImageLinkElement,
  IThemeConfig,
  IWorkflowActivity,
  IWorkflowActivityOptions,
  ICommencedWorkflow,
  IReviewVideo,
  IReviewText,
  IStimuli,
  IStimulus,
  IReviewImage,
};
