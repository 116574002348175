import { createSlice } from '@reduxjs/toolkit';
import { Dictionary } from 'lib/types';

interface IPuxState {
  config: {
    hosts: Dictionary<string>;
    showVideoControls: boolean;
  },
}

const initialState: IPuxState = {
  config: {
    hosts: {
      api: process.env.REACT_APP_API_HOST!,
      wistia: process.env.REACT_APP_WISTIA_HOST!,
    },
    showVideoControls: process.env.REACT_APP_SHOW_VIDEO_CONTROLS === 'true',
  },
};

const puxSlice = createSlice({
  name: 'pux',
  initialState: initialState,
  reducers: {},
});

export default puxSlice;
