import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Colord, colord } from 'colord';
import { workflowsSelectors } from 'app/modules/workflows/store';
import { Helmet } from 'react-helmet';

const Theme: React.FunctionComponent = () => {
  const theme = useSelector(workflowsSelectors.getTheme);
  const styles = useMemo(() => {
    if (!theme) {
      return '';
    }

    const font = theme.font;
    const white = colord('#FFF');
    const primary = colord(theme.colours.primary);
    const textLight = colord(theme.colours.textLight);
    const textDark = colord(theme.colours.textDark);
    const backgroundSoft = colord(theme.colours.backgroundSoft);
    const backgroundStrong = colord(theme.colours.backgroundStrong);
    const getTextColour = (colour: Colord) => colour.brightness() <= 0.65 ? textLight : textDark;
    const getButtonBackground = (colour: Colord) => colour.brightness() <= 0.85 ? white : primary;
    const getButtonColor = (colour: Colord) => colour.brightness() > 0.85 ? getTextColour(primary) : primary.darken(0.05);

    return `
${font?.resource || ''}
${font?.family ? `
body,
.camera_tag {
  font-family: ${font.family};
}` : ''}
html {
  --antd-wave-shadow-color: ${primary.toHex()};
}
::selection {
  color: ${getTextColour(backgroundStrong).toHex()};
  background: ${(backgroundStrong.isDark() ? backgroundStrong.lighten(0.1) : backgroundStrong.darken(0.1)).toHex()};
}
.ant-layout-footer {
  background: ${backgroundSoft.toHex()};
  color: ${getTextColour(backgroundSoft).toHex()};
}
.ant-btn-default {
  color: ${primary.toHex()};
  border-color: ${primary.toHex()};
}
.ant-btn-default:focus,
.ant-btn-default:hover {
  color: ${(primary.isDark() ? primary.lighten(0.2) : primary.darken(0.1)).toHex()};
  border-color: ${(primary.isDark() ? primary.lighten(0.2) : primary.darken(0.1)).toHex()};
}
.ant-btn-primary {
  color: ${getTextColour(primary).toHex()};
  background: ${primary.toHex()};
  border-color: ${primary.toHex()};
}
.ant-btn-primary:focus,
.ant-btn-primary:hover {
  color: ${getTextColour(primary).toHex()};
  background: ${(primary.isDark() ? primary.lighten(0.2) : primary.darken(0.1)).toHex()};
  border-color: ${(primary.isDark() ? primary.lighten(0.2) : primary.darken(0.1)).toHex()};
}
.ant-progress-success-bg,
.ant-progress-bg {
  background-color: ${primary.toHex()};
}
.ant-progress-inner {
  background-color: ${backgroundSoft.toHex()}
}
.ant-radio-checked .ant-radio-inner {
  border-color: ${primary.toHex()};
}
.ant-radio-inner::after {
  background-color: ${primary.toHex()};
}
.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: ${primary.toHex()};
}
.ant-input:focus,
.ant-input-focused,
.ant-input-number:focus,
.ant-input-number-focused {
  border-color: ${primary.toHex()};
  box-shadow: 0 0 0 2px ${primary.alpha(0.3).toHex()};
}
.ant-input:hover,
.ant-input-number:hover {
  border-color: ${primary.toHex()};
}
.ant-btn-link {
  color: ${primary.toHex()};
}
.split-workflow-form-container .split-workflow-form-container-filled {
  background-color: ${backgroundStrong.toHex()};
}
.root-layout,
.split-workflow-form-container .split-workflow-form-container-empty {
  background-color: ${backgroundSoft.toHex()};
}
.split-workflow-form-container .split-workflow-form-container-filled,
.split-workflow-form-container .split-workflow-form-container-filled p,
.split-workflow-form-container .split-workflow-form-container-filled h1,
.split-workflow-form-container .split-workflow-form-container-filled h2,
.split-workflow-form-container .split-workflow-form-container-filled h3,
.split-workflow-form-container .split-workflow-form-container-filled h4,
.split-workflow-form-container .split-workflow-form-container-filled h5,
.split-workflow-form-container .split-workflow-form-container-filled h6,
.split-workflow-form-container .split-workflow-form-container-filled .workflow-activity-title,
.split-workflow-form-container .split-workflow-form-container-filled .workflow-activity-subtitle,
.split-workflow-form-container .split-workflow-form-container-filled .workflow-activity-subtitle-sm,
.split-workflow-form-container .split-workflow-form-container-filled .ant-form-item-label label,
.split-workflow-form-container .split-workflow-form-container-filled .ant-checkbox-wrapper,
.split-workflow-form-container .split-workflow-form-container-filled .ant-radio-wrapper,
.split-workflow-form-container .split-workflow-form-container-filled .nps-radio-form-labels,
.split-workflow-form-container .split-workflow-form-container-filled .scale-slider-form-labels {
  color: ${getTextColour(backgroundStrong).toHex()};
}
.split-workflow-form-container .split-workflow-form-container-empty,
.split-workflow-form-container .split-workflow-form-container-empty p,
.split-workflow-form-container .split-workflow-form-container-empty h1,
.split-workflow-form-container .split-workflow-form-container-empty h2,
.split-workflow-form-container .split-workflow-form-container-empty h3,
.split-workflow-form-container .split-workflow-form-container-empty h4,
.split-workflow-form-container .split-workflow-form-container-empty h5,
.split-workflow-form-container .split-workflow-form-container-empty h6,
.split-workflow-form-container .split-workflow-form-container-empty .workflow-activity-title,
.split-workflow-form-container .split-workflow-form-container-empty .workflow-activity-subtitle,
.split-workflow-form-container .split-workflow-form-container-empty .workflow-activity-subtitle-sm,
.split-workflow-form-container .split-workflow-form-container-empty .ant-form-item-label label,
.split-workflow-form-container .split-workflow-form-container-empty .ant-checkbox-wrapper,
.split-workflow-form-container .split-workflow-form-container-empty .ant-radio-wrapper,
.split-workflow-form-container .split-workflow-form-container-empty .nps-radio-form-labels,
.split-workflow-form-container .split-workflow-form-container-empty .scale-slider-form-labels {
  color: ${getTextColour(backgroundSoft).toHex()};
}
.split-workflow-form-container .split-workflow-form-container-filled .ant-btn-primary {
  color: ${getButtonColor(backgroundStrong).toHex()};
  background: ${getButtonBackground(backgroundStrong).toHex()};
  border-color: ${getButtonBackground(backgroundStrong).toHex()};
}
.split-workflow-form-container .split-workflow-form-container-empty .ant-btn-primary {
  color: ${getButtonColor(backgroundSoft).toHex()};
  background: ${getButtonBackground(backgroundSoft).toHex()};
}

.split-workflow-form-container .split-workflow-form-container-filled .camera_tag .cameratag_screen .cameratag_prompt {
  background: ${backgroundSoft.toHex()};
  color: ${getTextColour(backgroundSoft).toHex()};
}
.split-workflow-form-container .split-workflow-form-container-empty .camera_tag .cameratag_screen .cameratag_prompt {
  background: ${backgroundStrong.toHex()};
  color: ${getTextColour(backgroundStrong).toHex()};
}

.ant-radio-button-wrapper:hover {
  color: ${primary.toHex()};
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
  color: ${primary.toHex()};
  border-color: ${primary.toHex()};
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: ${primary.toHex()};
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: ${primary.lighten(0.2).toHex()};
  border-color: ${primary.lighten(0.2).toHex()};
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before {
  background-color: ${primary.lighten(0.2).toHex()};
}
.ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
  border-right-color: ${primary.lighten(0.2).toHex()};
}
.video-rating-radio-icon,
.text-rating-radio-icon,
.image-rating-radio-icon {
  color: ${primary.toHex()};
}
.video-rating-radio-group .ant-radio-button-wrapper-checked,
.text-rating-radio-group .ant-radio-button-wrapper-checked,
.image-rating-radio-group .ant-radio-button-wrapper-checked {
  background-color: ${backgroundStrong.lighten(0.1).toHex()};
}
.workflow-activity-title {
  color: ${primary.toHex()};
}
.goodbye-icon {
  color: ${primary.toHex()};
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: ${primary.toHex()};
  border-color: ${primary.toHex()};
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: ${primary.toHex()};
}
.ant-spin {
  color: ${primary.toHex()};
}
.ant-spin-dot-item {
  background-color: ${primary.toHex()};
}
.ant-select:not(.ant-select-disabled):hover .ant-select-selector,
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: ${primary.toHex()};
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: ${primary.alpha(0.1).toHex()};
}

.scale-form-labels .scale-form-label-wrapper .scale-form-label-arrow {
  border-bottom-color: ${primary.toHex()};
}

.scale-form-labels .scale-form-label-wrapper .scale-form-label-text,
.scale-form-element .ant-radio-group .ant-radio-button-wrapper.ant-radio-button-wrapper-checked,
.nps-radio-form-element .ant-radio-group .ant-radio-button-wrapper.ant-radio-button-wrapper-checked,
.rating-measure-radio-form-element .ant-radio-group .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
  background-color: ${primary.toHex()};
  color: ${getTextColour(primary).toHex()};
}
.camera-tag-overlay-actions-inner .ant-btn-primary,
.split-workflow-form-container .split-workflow-form-container-empty .camera-tag-overlay-actions-inner .ant-btn-primary {
  color: ${getTextColour(primary).toHex()};
  background: ${primary.toHex()};
  border-color: ${primary.toHex()};
}
.camera_tag .cameratag_screen .cameratag_prompt {
  color: ${getTextColour(primary).toHex()};
  background: ${primary.toHex()};
}
.camera_tag .cameratag_screen.cameratag_accept .cameratag_accept_btn, .camera_tag .cameratag_screen.cameratag_accept .cameratag_rerecord_btn, .camera_tag .cameratag_screen.cameratag_accept .cameratag_play_btn {
  color: ${textDark.toHex()};
  border: 1px solid ${textLight.toHex()};
  background: ${textLight.toHex()};
}
.camera_tag .cameratag_screen.cameratag_accept .cameratag_accept_btn:hover, .camera_tag .cameratag_screen.cameratag_accept .cameratag_rerecord_btn:hover, .camera_tag .cameratag_screen.cameratag_accept .cameratag_play_btn:hover {
  border-color: ${primary.toHex()};
  background: ${primary.toHex()};
}
.camera_tag .cameratag_screen.cameratag_accept .cameratag_accept_btn:hover span, .camera_tag .cameratag_screen.cameratag_accept .cameratag_rerecord_btn:hover span, .camera_tag .cameratag_screen.cameratag_accept .cameratag_play_btn:hover span {
  color: ${getTextColour(primary).toHex()};
}
.ant-slider-handle {
  border-color: ${primary.lighten(0.2).toHex()};
}
.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open),
.ant-slider-handle:focus {
  border-color: ${primary.lighten(0.1).toHex()};
}
.ant-slider-track {
  background-color: ${primary.lighten(0.3).toHex()};
}
.ant-slider:hover .ant-slider-track {
  background-color: ${primary.lighten(0.2).toHex()};
}
.incentive-code-content {
  border-color: ${primary.toHex()};
  background: ${backgroundStrong.toHex()};
  color: ${getTextColour(backgroundStrong).toHex()};
}
.incentive-code .ant-checkbox-wrapper {
  color: ${getTextColour(backgroundStrong).toHex()};
}
.incentive-code-end {
  color: ${primary.toHex()};
}
.incentive-code .incentive-code-value {
  border-color: ${primary.toHex()};
}
.preference-opinion-form-element .preference-container-wrapper .preference-container .preference-rating {
  background-color: ${backgroundStrong.toHex()};
}
.preference-opinion-form-element .preference-container-wrapper .preference-container {
  border: 2px solid ${backgroundStrong.toHex()};
}
.preference-container-wrapper .preference-container .preference-rating .preference-rating-wrapper .ant-select-selector,
.preference-container-wrapper .preference-container .preference-rating .preference-rating-wrapper .ant-select-arrow {
  color: ${getTextColour(backgroundStrong).toHex()};
}
.content-reminder-banner-element .content-reminder-banner-element-content .banner-element-view-link .ant-btn,
.preference-container-wrapper .preference-stimulus-wrapper .preference-view-link .ant-btn {
  color: ${getTextColour(primary).toHex()};
  background-color: ${primary.toHex()};
  border-color: ${primary.toHex()};
}
.preference-single-opinion-form .preference-single-opinion-form-element.selected .preference-select-btn:not(.selected) {
  color: ${getTextColour(backgroundSoft).toHex()};
  background-color: ${backgroundSoft.toHex()};
  border-color: ${getTextColour(backgroundSoft).toHex()};
}
.preference-single-opinion-form-element .preference-container-wrapper .preference-container {
  border: 2px solid ${backgroundStrong.toHex()};
}
.ant-carousel .carousel-arrow {
  background-color: ${primary.toHex()};
  color: ${getTextColour(primary).toHex()};
}
.ant-carousel .carousel-arrow:hover {
  background-color: ${primary.lighten(0.1).toHex()};
}
.ant-carousel .carousel-arrow:active {
  background-color: ${primary.lighten(0.2).toHex()};
}}
.gender-radio-form-element .gender-radio-form-group .ant-radio-button-wrapper:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper-checked:first-child {
  border-color: ${primary.toHex()};
}
.gender-radio-form-element .gender-radio-form-group .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
  border-color: ${primary.toHex()};
  background-color: ${primary.toHex()};
  color: ${getTextColour(primary).toHex()};
}
.drag-confirm-wrapper .drag-confirm-overlay .drag-confirm-overlay-content {
  background-color: ${backgroundStrong.toHex()};
  color: ${getTextColour(backgroundStrong).toHex()};
}
.drag-confirm-wrapper .drag-confirm-overlay .drag-confirm-overlay-content button {
  background-color: ${primary.toHex()};
  border-color: ${primary.toHex()};
  color: ${getTextColour(primary).toHex()};
}
.qualie-camera-sound-waveform-bar {
  background-color: ${primary.toHex()};
}
.form-element-radio-button-group .form-element-radio-button.ant-radio-button-wrapper.ant-radio-button-wrapper-in-form-item {
  color: ${getTextColour(primary).toHex()};
  background-color: ${primary.toHex()};
  border-color: ${getTextColour(primary).toHex()};
  box-shadow: none;
}
.form-element-radio-button-image-group .form-element-radio-button-image.ant-radio-button-wrapper.ant-radio-button-wrapper-in-form-item {
  color: ${getTextColour(primary).toHex()};
  background-color: ${primary.toHex()};
  border-color: ${getTextColour(primary).toHex()};
  box-shadow: none;
}

${theme.rawStyles || ''}
`;
  }, [theme]);

  return (
    <Helmet>
      <style>{styles}</style>
    </Helmet>
  );
};

export default Theme;
