import _ from 'lodash/fp';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IParticipant } from 'lib/modules/qualieApi/entities/participant';
import type {
  ICommencedWorkflow,
  IThemeConfig,
  IWorkflowActivity,
} from 'lib/modules/qualieApi/entities/workflow';
import { commenceWorkflow, createParticipant, getNextWorkflowStep } from './thunks';
import { IFlags } from './types';


interface IWorkflowsState {
  theme?: IThemeConfig;
  commencedWorkflow?: ICommencedWorkflow;
  currentWorkflowStepPosition?: number;
  workflowSteps: { [x: number]: IWorkflowActivity }
  participant?: IParticipant;
  flags: IFlags;
}

const initialState: IWorkflowsState = {
  flags: {
    cookie: false,
    enableUpload: false,
  },
  workflowSteps: {},
};

const workflowsSlice = createSlice({
  name: 'workflows',
  initialState: initialState,
  reducers: {
    setParticipant(state, action: PayloadAction<{ participant?: IParticipant, flags: IFlags }>) {
      state.participant = action.payload.participant;
      state.flags = action.payload.flags;
    },
    setFlags(state, action: PayloadAction<IFlags>) {
      state.flags = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(commenceWorkflow.fulfilled, (state, action) => {
      state.commencedWorkflow = action.payload;
      state.workflowSteps = _.keyBy('position', action.payload.data);
      state.theme = action.payload.data?.find(a => !!a.theme)?.theme || state.theme;
    });

    builder.addCase(createParticipant.fulfilled, (state, action) => {
      state.participant = action.payload.data;
    });

    builder.addCase(getNextWorkflowStep.fulfilled, (state, action) => {
      state.workflowSteps[action.payload.data.position] = action.payload.data;
      state.currentWorkflowStepPosition = action.payload.data.position;
    });
  },
});

export default workflowsSlice;