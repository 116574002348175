import React from 'react';
import IWorkflowElementProps from '../IWorkflowElementProps';

const LabelWorkflowElement: React.FunctionComponent<IWorkflowElementProps> = (props) => {
  const { workflowElement } = props;

  return <React.Fragment>{workflowElement.value}</React.Fragment>;
};

export default LabelWorkflowElement;
