import _ from 'lodash/fp';
import { IWorkflowFormElementElement } from 'lib/modules/qualieApi/entities/workflow';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { IWorkflowFormElementProps } from './types';
import { Form, Slider } from 'antd';
import { CommencedWorkflowContext } from 'app/modules/pux/contexts';
import ErrorMessage from '../../../errorMessage';
import { QuestionLabel } from 'app/modules/pux/components/content';
import { WORKFLOW_ELEMENT_FORM_ELEMENT_TYPES } from './index';
import { workflowsSelectors } from 'app/modules/workflows/store';
import { useSelector } from 'react-redux';

const ThemometerFormElement: React.FunctionComponent<IWorkflowFormElementProps<IWorkflowFormElementElement>> = (props) => {
  const { workflowElement, path } = props;
  const sortedOptions = useMemo(() => _.sortBy('position', workflowElement.options), [workflowElement.options]);
  const name = useMemo(() => path ? [...path, workflowElement.name] : [workflowElement.name], [path, workflowElement]);
  const { uiTexts } = useContext(CommencedWorkflowContext);
  const [dirty, setDirty] = useState<boolean>(false);
  const theme = useSelector(workflowsSelectors.getTheme);
  const label = useMemo(() => {
    if (workflowElement.inputType === WORKFLOW_ELEMENT_FORM_ELEMENT_TYPES.Radio) {
      return false;
    }

    return <QuestionLabel>{workflowElement.label}</QuestionLabel>;
  }, [workflowElement]);
  const handleStyles = useMemo(() => {
    let styles: React.CSSProperties = {};
    if (!dirty) {
      styles['display'] = 'none';
    }

    return styles;
  }, [dirty]);

  const onAfterChange = useCallback((value: number) => setDirty(true), [setDirty]);

  return (
    <Form.Item
      className="thermometer-form-element"
      name={name}
      label={label}
      getValueProps={(key: string) => ({ value: key === '' || key == null ? null : key })}
      rules={[
        {
          required: workflowElement.required,
          message: <ErrorMessage message={uiTexts?.NEED_ANSWER?.value} />,
        },
      ]}
    >
      <Slider
        dots={true}
        tooltipVisible={false}
        min={1}
        max={sortedOptions.length}
        handleStyle={handleStyles}
        onAfterChange={onAfterChange}
        reverse={theme?.direction === 'rtl'}
      />
    </Form.Item>
  );
};

export default ThemometerFormElement;
