import { useIsVerticalLayout } from 'app/hooks';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { CSSTransition, SwitchTransition } from 'react-transition-group';

interface IProps {
  reveal?: boolean;
}

const timeout = {
  appear: 0,
  enter: 800,
  exit: 800,
};

const RouteTransition: React.FunctionComponent<IProps> = ({
  children,
  reveal,
}) => {
  const location = useLocation();
  const isVerticalLayout = useIsVerticalLayout();
  const isReverseMode = useSelector((state: any) => state.reverseMode.reverseMode);
  const enterActive = useMemo(() => isVerticalLayout ? (isReverseMode) ? 'animate__fadeInDownBig animate__fast' : 'animate__fadeInUpBig animate__fast' : (isReverseMode) ? 'animate__fadeInLeftBig animate__fast' : 'animate__fadeInRightBig animate__fast', [isVerticalLayout, isReverseMode]);
  const exitActive = useMemo(() => isVerticalLayout ? (isReverseMode) ? 'animate__fadeOutDownBig animate__fast' : 'animate__fadeOutUpBig animate__fast' : (isReverseMode) ? 'animate__fadeOutRightBig animate__fast' : 'animate__fadeOutLeftBig animate__fast', [isVerticalLayout, isReverseMode]);
  const key = useMemo(() => `${reveal === false ? '+' : ''}${location.key || '*'}`, [location.key, reveal]);

  const classNames = useMemo(() => (
    reveal
      ? {
        enter: 'animate__animated',
        enterActive: enterActive,
        exit: 'animate__animated',
        exitActive: exitActive,
      }
      : {
        enter: 'route-transition-blocked',
        enterActive: 'route-transition-blocked',
        enterDone: 'route-transition-blocked',
        exit: 'route-transition-blocked',
        exitActive: 'route-transition-blocked',
        exitDone: 'animate__animated',
      }
  ), [reveal, enterActive, exitActive]);

  return (
    <SwitchTransition>
      <CSSTransition
        key={key}
        timeout={timeout}
        classNames={classNames}
        in={true}
        appear={true}
      >
        {children}
      </CSSTransition>
    </SwitchTransition>
  );
};

export default RouteTransition;