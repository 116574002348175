import _ from 'lodash/fp';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { ICommencedWorkflowContext, ICommencedWorkflowContextPrerequisite, ICommencedWorkflowContextPrerequisites } from 'app/modules/pux/contexts/commencedWorkflow';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { workflowsActions, workflowsSelectors } from '../store';
import { push } from 'connected-react-router';
import appRoutes from 'app/routes';
import { isWorkflowActivityBlocking } from 'app/modules/pux/utils';
import { puxSelectors } from 'app/modules/pux/store';
import { setReverseMode } from 'app/modules/reverseMode/store/slice';

const useParticipant = (workflowId?: string) => {
  const dispatch = useAppDispatch();
  const commencedWorkflow = useAppSelector(workflowsSelectors.getCommencedWorkflow);
  const participant = useAppSelector(workflowsSelectors.getParticipant);
  const { cookie, uid } = useAppSelector(workflowsSelectors.getFlags);

  useEffect(() => {
    if (cookie && uid == null && workflowId && commencedWorkflow) {
      dispatch(workflowsActions.getPersistedParticipant({ workflowId: workflowId }));
    }
  }, [dispatch, cookie, uid, workflowId, commencedWorkflow]);

  return participant;
};

const useCommencedWorkflowContextValue = (workflowId?: string, position?: number) => {
  const dispatch = useAppDispatch();
  const flags = useAppSelector(workflowsSelectors.getFlags);
  const participant = useParticipant(workflowId);
  const displayVideoControls = useAppSelector(puxSelectors.getShowVideoControls);
  const showVideoControls = useMemo(() => (participant?.panelProvider.test || false) || displayVideoControls, [participant, displayVideoControls]);
  const [prerequisites, rawSetPrerequisites] = useState<ICommencedWorkflowContextPrerequisites>();
  const commencedWorkflowStep = useAppSelector(state => workflowsSelectors.getWorkflowStep(state, position));
  const nextCommencedWorkflowStep = useAppSelector(state => commencedWorkflowStep ? workflowsSelectors.getWorkflowStep(state, commencedWorkflowStep.position + 1) : null);
  const uiTexts = useMemo(() => _.keyBy('name', commencedWorkflowStep?.workflowElements?.find(a => a.name === 'uiTexts')?.collection), [commencedWorkflowStep]);
  const isBlockingActivity = useMemo(() => commencedWorkflowStep ? isWorkflowActivityBlocking(commencedWorkflowStep) : true, [commencedWorkflowStep]);
  const [ready, setReady] = useState<number | null>(null);

  const setPrerequisite = useCallback((key: string, prerequisite?: ICommencedWorkflowContextPrerequisite | null) => {
    if (commencedWorkflowStep) {
      rawSetPrerequisites(prerequisites => prerequisite
        ? {
          ...(prerequisites || {}),
          [key]: prerequisite,
        }
        : {
          ...(_.omit(key, prerequisites)),
        }
      );
    }
  }, [rawSetPrerequisites, commencedWorkflowStep]);

  const checkPrerequisites = useCallback(() => {
    let valid = true;

    if (_.values(prerequisites).filter(a => !a.valid).length > 0) {
      for (let key in prerequisites) {
        if (!prerequisites[key].valid) {
          valid = false;
        }
        if (!prerequisites[key].reveal) {
          setPrerequisite(key, {
            ...prerequisites[key],
            reveal: true,
          });
        }
      }
    }

    return valid;
  }, [prerequisites, setPrerequisite]);

  const toNextStep = useCallback(async () => {
    dispatch(setReverseMode(false));

    if (!checkPrerequisites() || !workflowId) {
      return;
    }

    if (nextCommencedWorkflowStep) {
      dispatch(push(appRoutes.route('workflowElementStep', {
        workflowId: workflowId,
        position: (String)(nextCommencedWorkflowStep.position),
        workflowActivityType: nextCommencedWorkflowStep.workflowActivityType,
        participantId: nextCommencedWorkflowStep.participantId,
      }, {})));
    } else if (participant) {
      const nextStep = await dispatch(workflowsActions.getNextWorkflowStep({
        workflowId: workflowId,
        participantId: participant.hash,
      })).unwrap();

      dispatch(push(appRoutes.route('workflowElementStep', {
        workflowId: workflowId,
        position: (String)(nextStep.data.position),
        workflowActivityType: nextStep.data.workflowActivityType,
        participantId: nextStep.data.participantId,
      }, {}), { noVerify: true }));
    }
  }, [checkPrerequisites, workflowId, nextCommencedWorkflowStep, participant, dispatch]);

  const toNextStepInTest = useCallback(async (reverse: any) => {
    if (!reverse) {
      dispatch(setReverseMode(false));
    }
    if (!workflowId) {
      return;
    }

    if (participant) {
      const nextStep = await dispatch(workflowsActions.getNextWorkflowStep({
        workflowId: workflowId,
        participantId: participant.hash,
      })).unwrap();

      dispatch(push(appRoutes.route('workflowElementStep', {
        workflowId: workflowId,
        position: (String)(nextStep.data.position),
        workflowActivityType: nextStep.data.workflowActivityType,
        participantId: nextStep.data.participantId,
      }, {}), { noVerify: true }));
    }
  }, [workflowId, participant, dispatch]);

  const advance = useCallback(async () => {
    if (workflowId && participant) {
      await dispatch(workflowsActions.advanceParticipant({
        workflowId: workflowId,
        participantId: participant.hash,
      })).unwrap();
      toNextStep();
    }
  }, [toNextStep, workflowId, dispatch, participant]);

  const advanceInTest = useCallback(async () => {
    if (workflowId && participant) {
      await dispatch(workflowsActions.advanceParticipantInTest({
        workflowId: workflowId,
        participantId: participant.hash,
      })).unwrap();
      toNextStepInTest(false);
    }
  }, [workflowId, participant, dispatch, toNextStepInTest]);

  const retreatInTest = useCallback(async () => {
    if (workflowId && participant) {
      await dispatch(workflowsActions.retreatParticipantInTest({
        workflowId: workflowId,
        participantId: participant.hash,
      })).unwrap();
      toNextStepInTest(true);
    }
  }, [workflowId, participant, dispatch, toNextStepInTest]);

  const onReady = useCallback(() => {
    dispatch(setReverseMode(false));
    setReady(commencedWorkflowStep?.position || null);
  }, [commencedWorkflowStep?.position, dispatch]);

  const commencedWorkflowContextValue: ICommencedWorkflowContext = useMemo(() => ({
    toNextStep: toNextStep,
    toNextStepInTest: toNextStepInTest,
    advance: advance,
    advanceInTest: advanceInTest,
    retreatInTest: retreatInTest,
    workflowId: workflowId,
    flags: flags,
    showVideoControls: showVideoControls,
    participant: participant,
    uiTexts: uiTexts,
    prerequisites: prerequisites,
    setPrerequisite: setPrerequisite,
    checkPrerequisites: checkPrerequisites,
    activityOptions: commencedWorkflowStep?.activityOptions || {},
    ready: isBlockingActivity ? ready != null : true,
    onReady,
    enableUpload: flags.enableUpload,
    referrer: flags.referrer,
    activityHash: flags.activityHash,
  }), [
    toNextStep,
    toNextStepInTest,
    advance,
    advanceInTest,
    retreatInTest,
    workflowId,
    participant,
    prerequisites,
    setPrerequisite,
    checkPrerequisites,
    uiTexts,
    flags,
    showVideoControls,
    commencedWorkflowStep,
    ready,
    onReady,
    isBlockingActivity,
  ]);

  useEffect(() => commencedWorkflowStep ? () => rawSetPrerequisites({}) : undefined, [commencedWorkflowStep]);

  useEffect(() => {
    setReady(value => {
      if (commencedWorkflowStep && value != null && value !== commencedWorkflowStep.position) {
        return null;
      }

      return value;
    });
  }, [commencedWorkflowStep, ready]);

  return commencedWorkflowContextValue;
};

export {
  useParticipant,
  useCommencedWorkflowContextValue,
};
