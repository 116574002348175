import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { QualieVideoPlayer, QualieVideoPlayerRef } from 'qualie-common-react';
import { IExternalVideoWorkflowElementProps } from './types';
import { CommencedWorkflowContext } from 'app/modules/pux/contexts';

const QualieExternalVideoWorkflowElement: React.FunctionComponent<IExternalVideoWorkflowElementProps> = ({
  active,
  workflowElement,
  onValid,
}) => {
  const playerRef = useRef<QualieVideoPlayerRef | null>(null);
  const [watched, setWatched] = useState(false);
  const { showVideoControls } = useContext(CommencedWorkflowContext);

  const controlBarChildren = useMemo(() => [
    'playToggle',
    'volumePanel',
    ...(showVideoControls ? [
      'progressControl',
    ] : []),
    'currentTimeDisplay',
    'timeDivider',
    'durationDisplay',
    'subsCapsButton',
    'fullscreenToggle',
  ], [showVideoControls]);

  const onVideoEnd = useCallback(() => {
    setWatched(true);
  }, []);

  useEffect(() => {
    if (onValid) {
      onValid(workflowElement, watched);
    }
  }, [onValid, watched, workflowElement]);

  useEffect(() => {
    if (active === false && playerRef.current != null) {
      playerRef.current.pause();
    }
  }, [active]);

  return (
    <div className="qualie-external-video-workflow-element">
      <QualieVideoPlayer
        ref={playerRef}
        autoPlay={false}
        hash={workflowElement.videoId}
        onVideoEnd={onVideoEnd}
        fill={true}
        fluid={false}
        responsive={true}
        controlBarChildren={controlBarChildren}
        thumbnail={workflowElement.thumbnail}
      />
    </div>
  );
};

export default QualieExternalVideoWorkflowElement;
