import { ExclamationCircleOutlined } from '@ant-design/icons';
import React from 'react';

const ErrorMessage: React.FunctionComponent<{ message?: string }> = ({
  message,
}) => {
  return (
    <React.Fragment>
      <ExclamationCircleOutlined /> {message}
    </React.Fragment>
  );
};

export default ErrorMessage;