import React, { useImperativeHandle, useMemo, useRef } from 'react';
import { BasicWorkflowFormSize } from './types';
import cx from 'classnames';

interface IProps {
  children?: React.ReactNode;
  size: BasicWorkflowFormSize;
}

interface IBasicWorkflowFormRef {
  focus: () => void;
}

const BasicWorkflowForm = React.forwardRef<IBasicWorkflowFormRef, IProps>((props, ref) => {
  const { size, children } = props;
  const finalClassName = useMemo(() => cx({
    'default-workflow-form-container': size === 'default' || !size,
    'large-workflow-form-container': size === 'large',
    'small-workflow-form-container': size === 'small',
  }), [size]);

  const containerRef = useRef<HTMLDivElement | null>(null);
  useImperativeHandle(ref, () => ({
    focus: () => {
      if (!containerRef.current) {
        return;
      }
      window.scroll({
        top: containerRef.current.offsetTop - 25,
        left: 0,
        behavior: 'smooth',
      });
    },
  }));

  return (
    <div
      className={finalClassName}
      ref={containerRef}
    >
      {children}
    </div>
  );
});

export type {
  IBasicWorkflowFormRef,
};

export default BasicWorkflowForm;
