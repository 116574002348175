import React, { useMemo } from 'react';
import cx from 'classnames';
import { SplitWorkflowFormFocus } from './types';
import SplitWorkflowFormFilled from './splitWorkflowFormFilled';
import SplitWorkflowFormEmpty from './splitWorkflowFormEmpty';


interface IProps {
  className?: string;
  focus: SplitWorkflowFormFocus;
}

interface IStatic {
  Filled: typeof SplitWorkflowFormFilled;
  Empty: typeof SplitWorkflowFormEmpty;
}

const SplitWorkflowForm: React.FunctionComponent<IProps> & IStatic = (props) => {
  const { focus, children, className } = props;
  const finalClassName = useMemo(() => cx({
    ...(className ? { [className]: true } : {}),
    'split-workflow-form-container': true,
    'focus-filled': focus === 'filled',
    'focus-empty': focus === 'empty',
  }), [className, focus]);

  return (
    <div className={finalClassName}>{children}</div>
  );
};

SplitWorkflowForm.Empty = SplitWorkflowFormEmpty;
SplitWorkflowForm.Filled = SplitWorkflowFormFilled;

export default SplitWorkflowForm;
