import { RootState } from 'app/store';

const getHost = (state: RootState, name: string) => state?.puxReducer.config.hosts[name];
const getShowVideoControls = (state: RootState) => state?.puxReducer.config.showVideoControls;

const puxSelectors = {
  getHost,
  getShowVideoControls,
};

export default puxSelectors;
