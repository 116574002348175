import { IWorkflowFormElementElement } from 'lib/modules/qualieApi/entities/workflow';
import React, { useMemo } from 'react';
import FormElement from '..';
import { IWorkflowFormElementProps } from './types';

const GenericFormElement: React.FunctionComponent<IWorkflowFormElementProps<IWorkflowFormElementElement>> = (props) => {
  const { workflowElement, path } = props;
  const name = useMemo(() => path ? [...path, workflowElement.name] : [workflowElement.name], [path, workflowElement]);
  // Need to forcefully type this as the API breaks it's own rules on this particular workflow element.
  const value: IWorkflowFormElementElement = workflowElement.value as any;

  return (
    <React.Fragment>
      <FormElement
        key={value.name}
        workflowElement={value}
        path={name}
      />
    </React.Fragment>
  );
};

export default GenericFormElement;
