import _ from 'lodash/fp';
import { IWorkflowFormElementElement } from 'lib/modules/qualieApi/entities/workflow';
import React, { useContext, useMemo } from 'react';
import { Form, Radio } from 'antd';
import { IWorkflowFormElementProps } from './types';
import { CommencedWorkflowContext } from 'app/modules/pux/contexts';
import { QuestionLabel } from 'app/modules/pux/components/content';
import ErrorMessage from '../../../errorMessage';

const RatingMeasureRadioFormElement: React.FunctionComponent<IWorkflowFormElementProps<IWorkflowFormElementElement>> = (props) => {
  const { workflowElement, path } = props;
  const options = useMemo(() => _.sortBy('position', workflowElement.options), [workflowElement]);
  const name = useMemo(() => path ? [...path, workflowElement.name] : [workflowElement.name], [path, workflowElement]);
  const { uiTexts } = useContext(CommencedWorkflowContext);

  return (
    <Form.Item
      className="rating-measure-radio-form-element"
      label={<QuestionLabel>{workflowElement.label}</QuestionLabel>}
      name={name}
      rules={[
        {
          required: workflowElement.required,
          message: <ErrorMessage message={uiTexts?.IS_REQUIRED?.value} />,
        },
      ]}
    >
      <Radio.Group>
        {options.map(option => (
          <Radio.Button
            key={option.value}
            value={option.key}
          >{option.label}</Radio.Button>
        ))}
      </Radio.Group>
    </Form.Item>
  );
};

export default RatingMeasureRadioFormElement;