import appRoutes from 'app/routes';
import React from 'react';
import { Switch, Route } from 'react-router';
import WorkflowElementStepRoute from './workflowElementStep';
import WorkflowLandingRoute from './workflowLanding';
import MaintenanceRoute from './maintenance';

interface IProps { }

const Routes: React.FunctionComponent<IProps> = (props) => {

  return (
    <Switch>
      <Route
        strict
        exact
        path={appRoutes.rawRoute('maintenance')}
        component={MaintenanceRoute}
      />
      <Route
        strict
        exact
        path={appRoutes.rawRoute('workflowLanding')}
        component={WorkflowLandingRoute}
      />
      <Route
        strict
        exact
        path={appRoutes.rawRoute('workflowElementStep')}
        component={WorkflowElementStepRoute}
      />
    </Switch>
  );
};

export default Routes;
