import React, { useMemo } from 'react';
import cx from 'classnames';

interface IProps {
  min: number;
  max: number;
  value: number;
}

const RecordProgress: React.FunctionComponent<IProps> = (props) => {
  const { min, max, value } = props;
  const normMin = useMemo(() => (Number)(min) / ((Number)(max) || Number.MAX_SAFE_INTEGER), [min, max]);
  const normValue = useMemo(() => (Number)(value) / ((Number)(max) || Number.MAX_SAFE_INTEGER), [value, max]);

  const styleBarBackMin = useMemo(() => ({ width: `${Math.round(normMin * 100)}%` }), [normMin]);
  const styleBarBack = useMemo(() => ({ width: `${100 - Math.round(normMin * 100)}%` }), [normMin]);
  const styleBar = useMemo(() => ({ width: `${Math.round(normValue * 100)}%` }), [normValue]);

  const classBar = useMemo(() => cx({
    'camera-tag-record-progress-bar': true,
    'too-short': normValue < normMin,
  }), [normValue, normMin]);

  return (
    <div className="camera-tag-record-progress">
      <div
        className={classBar}
        style={styleBar}
      />
      <div
        className="camera-tag-record-progress-bar-back-min"
        style={styleBarBackMin}
      />
      <div
        className="camera-tag-record-progress-bar-back"
        style={styleBarBack}
      />
    </div>
  );
};

export default RecordProgress;
