import { Dictionary } from 'lib/types';
import qs from 'query-string';

type NamedRouteQuery = Dictionary | null;
type NamedRouteParams = Dictionary | null;
type NamedRoute<TParams extends NamedRouteParams, TQuery extends NamedRouteParams> = {
  url: string;
  splitUrl: string[];
  components: Dictionary<string>;
  params?: TParams;
  query?: TQuery;
};

type NamedRouteCollection = { [route: string]: NamedRoute<NamedRouteParams, NamedRouteQuery> };

class MissingParamError extends Error {
  constructor(route: string | number | symbol, param: string) {
    super(`Route param ${JSON.stringify(param)} required by route ${JSON.stringify(route)} but none provided!`);
  }
}

class MissingRouteError extends Error {
  constructor(route: string | number | symbol) {
    super(`Attempted to parse route ${JSON.stringify(route)} but none exists!`);
  }
}

const build = <TRoutes extends NamedRouteCollection>(routes: TRoutes) => {
  const route = <TRoute extends keyof TRoutes>(
    route: TRoute,
    params: Exclude<TRoutes[TRoute]['params'], undefined>,
    query: Exclude<TRoutes[TRoute]['query'], undefined>
  ) => {
    const url = routes[route]?.splitUrl;
    const components = routes[route]?.components;

    if (url == null || components == null) {
      throw new MissingRouteError(route);
    }

    const parsedUrl = url.map(a => {
      if (a in components) {
        if (params == null || !(components[a] in params)) {
          throw new MissingParamError(route, components[a]);
        }

        return params[components[a]];
      }

      return a;
    }).join('/');

    const parsedQuery = query ? `?${qs.stringify(query)}` : '';

    return parsedUrl + parsedQuery;
  };
  const rawRoute = <TRoute extends keyof TRoutes>(route: TRoute) => {
    if (route in routes && routes[route].url != null) {
      return routes[route].url;
    }
    throw new MissingRouteError(route);
  };

  return {
    routes: routes,
    route,
    rawRoute,
  };
};

const createRoute = <TParams extends NamedRouteParams = null, TQuery extends NamedRouteQuery = null>(url: string): NamedRoute<TParams, TQuery> => ({
  url: url,
  splitUrl: url.split('/'),
  components: url
    .split('/')
    .filter(a => a.startsWith(':'))
    .reduce<Dictionary<string>>((acc, curr) => {
      acc[curr] = curr.substring(1);

      return acc;
    }, {}),
});

const NamedRouting = {
  build: build,
  createRoute: createRoute,
};

export {
  MissingParamError,
  MissingRouteError,
};

export default NamedRouting;
