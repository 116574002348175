import { Button } from 'antd';
import { CommencedWorkflowContext } from 'app/modules/pux/contexts';
import { useKeyedWorkflowElements } from 'app/modules/pux/hooks';
import { IWorkflowCarouselElement, IWorkflowElement, IWorkflowFormElement, IWorkflowInitiativeElement, IWorkflowQualieCameraElement } from 'lib/modules/qualieApi/entities/workflow';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import PrerequisiteError from '../../prerequisiteError';
import WorkflowElement from '../../workflowElement';
import IWorkflowActivityProps from '../IWorkflowActivityProps';
import SplitWorkflowForm from '../../layout/splitWorkflowForm';
import { WorkflowActivitySubtitle, WorkflowActivityTitle } from '../../content';
import FormActions from '../../layout/formActions';
import BannerWorkflowElement from '../../workflowElement/types/contentBanner';
import _ from 'lodash/fp';
import WorkflowActivityAdvanceRetreat from '../../content/workflowActivityTestAdvanceRetreat';

type Elements = {
  title: IWorkflowElement;
  subTitle: IWorkflowElement;
  explainer: IWorkflowCarouselElement;
  explainerButtonText: IWorkflowElement;
  initiatives: IWorkflowInitiativeElement;
  recordOpinionQCForm: IWorkflowFormElement;
  qualieCamOptions: IWorkflowElement;
  showAdvanceRetreat: IWorkflowElement;
  showAdvance: IWorkflowElement;
  showRetreat: IWorkflowElement;
};

const RecordOpinionQcWorkflowActivity: React.FunctionComponent<IWorkflowActivityProps> = (props) => {
  const { workflowActivity } = props;
  const elements = useKeyedWorkflowElements<Elements>(workflowActivity.workflowElements);
  const { setPrerequisite, checkPrerequisites, toNextStep, uiTexts, advanceInTest, retreatInTest } = useContext(CommencedWorkflowContext);
  const [explainerComplete, setExplainerComplete] = useState(false);
  const dependencies = useMemo(() => ({
    qualieCamOptions: elements.qualieCamOptions,
  }), [elements]);
  const explainers = useMemo(() => _.sortBy('position', elements.explainer?.carouselHtmlElements || []), [elements]);
  const stimuli = useMemo(() => {
    const initiative = (elements.initiatives?.collection || [])[0] as any;

    return _.sortBy('position', initiative?.stimuli || []);
  }, [elements]);
  const showExplainer = !explainerComplete && !!elements.explainer;
  const allowUpload = (dependencies.qualieCamOptions as IWorkflowQualieCameraElement)?.allowUpload;
  const [ uploadingVideo, setUploadingVideo ] = useState<boolean>(false);
  const { flags } = useContext(CommencedWorkflowContext);

  const onExplainerValid = useCallback((element: IWorkflowElement, valid: boolean, errorMessage?: string) => {
    setPrerequisite(element.name, valid ? null : {
      valid: valid,
      error: (!valid && (errorMessage || uiTexts?.VIEW_ALL_CONTENT?.value)) || null,
      reveal: false,
    });
  }, [setPrerequisite, uiTexts]);

  const onExplainerFinish = useCallback(() => {
    if (elements.showAdvanceRetreat?.value === 'true' || checkPrerequisites()) {
      setExplainerComplete(true);
    }
  }, [checkPrerequisites, elements]);

  const onUploadClicked = useCallback(() => {
    setUploadingVideo(true);
  }, []);

  const onUploadCancelled = useCallback(() => {
    setUploadingVideo(false);
  }, []);

  const uploadVideoOpinionFormElement: IWorkflowFormElement = {
    actionURL: elements.recordOpinionQCForm.actionURL,
    formType: 'UPLOAD_VIDEO_OPINION_ACTIVITY',
    elements: elements.recordOpinionQCForm.elements,
    submitButtonText: 'Upload',
    name: 'uploadVideoOpinionForm',
    visible: uploadingVideo,
    workflowElementType: 'FORM',
  };

  return (
    <SplitWorkflowForm focus="empty">
      <SplitWorkflowForm.Filled>
        <WorkflowActivityTitle>{elements.title.value}</WorkflowActivityTitle>
        <WorkflowActivitySubtitle>{elements.subTitle.value}</WorkflowActivitySubtitle>
        {elements.showAdvanceRetreat?.value === 'true' &&
          <WorkflowActivityAdvanceRetreat
            onAdvance={advanceInTest}
            onRetreat={retreatInTest}
            allowAdvance={elements.showAdvance?.value === 'true'}
            allowRetreat={elements.showRetreat?.value === 'true'}
          />}
      </SplitWorkflowForm.Filled>
      <SplitWorkflowForm.Empty
        header={((!showExplainer && !!elements.explainer) || !!stimuli?.length) && (
          <BannerWorkflowElement
            explainers={explainers}
            stimuli={stimuli}
          />
        )}
      >
        {showExplainer && (
          <React.Fragment>
            <WorkflowElement
              workflowElement={elements.explainer}
              onValid={onExplainerValid}
            />
            <PrerequisiteError target="explainer" />
            <FormActions>
              <Button
                type="primary"
                onClick={onExplainerFinish}
              >{elements.explainerButtonText.value}</Button>
            </FormActions>
          </React.Fragment>
        )}
        {!showExplainer && (
          <>
            {(allowUpload || flags.enableUpload) && (
              <div className="upload-button-container">
                <Button
                  onClick={onUploadClicked}
                  hidden={uploadingVideo}
                  style={{margin: 'auto'}}
                >
                  {uiTexts?.WORKFLOW_ELEMENT_FORM_ELEMENT_VIDEO_UPLOAD_BUTTON?.value}
                </Button>
              </div>
            )}
            <WorkflowElement
              workflowElement={uploadVideoOpinionFormElement}
              onFinish={toNextStep}
              onBeforeFinish={checkPrerequisites}
              dependencies={dependencies}
              hide={!uploadingVideo}
              onCancel={onUploadCancelled}
            />
            <WorkflowElement
              workflowElement={elements.recordOpinionQCForm}
              onFinish={toNextStep}
              onBeforeFinish={checkPrerequisites}
              dependencies={dependencies}
              hide={uploadingVideo}
            />
          </>
        )}
      </SplitWorkflowForm.Empty>
    </SplitWorkflowForm>
  );
};

export default RecordOpinionQcWorkflowActivity;
