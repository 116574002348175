import { useAudioLevels } from 'app/hooks/audio';
import React, { useMemo } from 'react';
import cx from 'classnames';

interface IProps {
  mediaStream: MediaStream | null;
  direction: 'vertical' | 'horizontal';
}

const REFRESH_RATE_MS = 1000 / 24;
const RESOLUTION = 64;

const SoundWaveform: React.FunctionComponent<IProps> = ({
  mediaStream,
  direction,
}) => {
  const [averageSample] = useAudioLevels(mediaStream, REFRESH_RATE_MS, RESOLUTION);
  const className = useMemo(() => cx({
    'qualie-camera-sound-waveform': true,
    'qualie-camera-sound-waveform-vertical': direction === 'vertical',
    'qualie-camera-sound-waveform-horizontal': direction === 'horizontal',
  }), [direction]);
  const barStyle = useMemo(() => ({
    [direction === 'horizontal' ? 'width' : 'height']: `${(averageSample / 255) * 100}%`,
  }), [direction, averageSample]);

  return (
    <div className={className}>
      <div
        className="qualie-camera-sound-waveform-bar"
        style={barStyle}
      />
    </div>
  );
};

SoundWaveform.defaultProps = {
  direction: 'horizontal',
};

export default SoundWaveform;
