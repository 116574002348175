import _ from 'lodash/fp';
import { IWorkflowFormElementElement, IWorkflowFormElementElementOption } from 'lib/modules/qualieApi/entities/workflow';
import React, { useContext, useMemo } from 'react';
import { Button, Form, Input, Space } from 'antd';
import { IWorkflowFormElementProps } from './types';
import { CommencedWorkflowContext } from 'app/modules/pux/contexts';
import { QuestionLabel } from 'app/modules/pux/components/content';
import ErrorMessage from '../../../errorMessage';
import { RuleObject } from 'antd/lib/form';
import { useIntl } from 'react-intl';
import { WORKFLOW_ELEMENT_FORM_ELEMENT_TYPES } from '.';

const ButtonCheckboxButton: React.FunctionComponent<{
  active: boolean;
  option: IWorkflowFormElementElementOption;
  onClick: (option: IWorkflowFormElementElementOption) => void;
}> = ({
  children,
  active,
  option,
  onClick,
}) => (
    <Button
      className="button-checkbox-form-element-btn"
      type={active ? 'primary' : 'default'}
      onClick={() => onClick(option)}
    >{children}</Button>
  );

const ButtonCheckboxFormElement: React.FunctionComponent<IWorkflowFormElementProps<IWorkflowFormElementElement>> = (props) => {
  const { workflowElement, path } = props;
  const options = useMemo(() => _.sortBy('position', workflowElement?.options || []), [workflowElement]);
  const name = useMemo(() => path ? [...path, workflowElement.name] : [workflowElement.name], [path, workflowElement]);
  const { uiTexts } = useContext(CommencedWorkflowContext);
  const intl = useIntl();

  const rules = useMemo(() => ([
    {
      validator: async (rule: RuleObject, value: any) => {
        if (workflowElement.required && (!value || (_.isArray(value) && value.length === 0))) {
          throw new Error(uiTexts?.REQUIRED?.value);
        }
      },
      required: workflowElement.required,
    },
    ...(workflowElement.inputType === WORKFLOW_ELEMENT_FORM_ELEMENT_TYPES.CheckboxButton ? [{
      validator: async (rule: RuleObject, value: any) => {
        const selectedCount = _.isArray(value) ? value.length : 0;
        const min = Math.max(workflowElement.min, workflowElement.required ? 1 : 0);
        if (min && selectedCount < min) {
          throw new Error((uiTexts?.WORKFLOW_ELEMENT_FORM_ELEMENT_MATRIX_CHECKBOX_MIN_LIMIT) ? intl.formatMessage({
            id:  'NOT_DEFINED',
            defaultMessage: uiTexts?.WORKFLOW_ELEMENT_FORM_ELEMENT_MATRIX_CHECKBOX_MIN_LIMIT?.value,
          }, {min: min}) : intl.formatMessage({ id: 'workflowElement.formElement.matrix.checkbox.minLimit' }, {
            min: min,
          }));
        } else if (workflowElement.max && selectedCount > workflowElement.max) {
          throw new Error((uiTexts?.WORKFLOW_ELEMENT_FORM_ELEMENT_MATRIX_CHECKBOX_MAX_LIMIT) ? intl.formatMessage({
            id:  'NOT_DEFINED',
            defaultMessage: uiTexts?.WORKFLOW_ELEMENT_FORM_ELEMENT_MATRIX_CHECKBOX_MAX_LIMIT?.value,
          }, {max: workflowElement.max}) : intl.formatMessage({ id: 'workflowElement.formElement.matrix.checkbox.maxLimit' }, {
            max: workflowElement.max,
          }));
        }
      },
    }] : []),
  ]), [intl, workflowElement, uiTexts]);

  return (
    <Form.Item
      label={<QuestionLabel>{workflowElement.label}</QuestionLabel>}
      name={[...name, 'answers']}
      rules={rules}
      getValueFromEvent={(args: string[]) => {
        for (const arg of args.reverse()) {
          // Handle last click being exclusive.
          if (options.find(option => option.key === arg)?.exclusive) {
            return [arg];
          }
          // Handle last click not being exclusive.
          if (!options.find(option => option.key === arg)?.exclusive) {
            return args.filter(arg => !options.find(option => option.key === arg)?.exclusive);
          }
        }

        return args;
      }}
    >
      <Form.Item
        shouldUpdate={true}
        noStyle={true}
      >
        {form => {
          const path = [...name, 'answers'];
          const value = form.getFieldValue(path) as string[];
          const otherOption = options.find(a => a?.type === 'TEXT');
          const otherSelected = value && otherOption ? value.includes(otherOption.key) : false;
          const exclusiveOptions = options.filter(a => a?.exclusive).map(b => b.key);

          const onClick = (option: IWorkflowFormElementElementOption) => {
            let next = null;

            if (exclusiveOptions.length !== 0) {
              if (exclusiveOptions.includes(option.key)) {
                next = (!value?.includes(option.key) ? [...[], option.key] : (value || []).filter(a => a !== option.key));
              } else {
                if (!value?.includes(option.key)) {
                  next = (value || []).filter(a => !exclusiveOptions.includes(a));
                  next = [...(next || []), option.key];
                } else {
                  next = (value || []).filter(a => a !== option.key);
                }
              }
            } else {
              next = (!value?.includes(option.key) ? [...(value || []), option.key] : (value || []).filter(a => a !== option.key));
            }

            form.setFieldsValue(_.set(path, next, {}));
          };

          return (
            <React.Fragment>
              <Space wrap={true}>
                {options.map(option => (
                  <ButtonCheckboxButton
                    key={option.key}
                    active={!!value?.includes(option.key)}
                    option={option}
                    onClick={onClick}
                  >{option.label}</ButtonCheckboxButton>
                ))}
              </Space>
              {!!otherSelected && !!otherOption && (
                <div className="button-checkbox-form-element-other">
                  <Form.Item
                    label={otherOption.label}
                    name={[...name, 'other']}
                    rules={[
                      {
                        required: workflowElement.required,
                        message: <ErrorMessage message={uiTexts?.IS_REQUIRED?.value} />,
                      },
                      {
                        max: Number(otherOption.limit),
                        message: <ErrorMessage message={uiTexts?.MAXIMUM_CHARACTERS_EXCEEDED?.value} />,
                      },
                    ]}
                  >
                    <Input
                      onClick={e => e.stopPropagation()}
                      className="form-element-checkbox-other-input"
                    />
                  </Form.Item>
                </div>
              )}
            </React.Fragment>
          );
        }}
      </Form.Item>
    </Form.Item>
  );
};

export default ButtonCheckboxFormElement;