import { IWorkflowImageLinkElement } from 'lib/modules/qualieApi/entities/workflow';
import React, { useCallback, useEffect, useState } from 'react';
import IWorkflowElementProps from '../IWorkflowElementProps';

interface IProps extends IWorkflowElementProps<IWorkflowImageLinkElement> {
  active?: boolean;
}

const ImageLinkWorkflowElement: React.FunctionComponent<IProps> = (props) => {
  const { workflowElement, onValid, active } = props;
  const [clicked, setClicked] = useState<boolean>(false);

  useEffect(() => {
    if (onValid && (active || active === undefined)) {
      onValid(workflowElement, clicked);
    }
  }, [active, onValid, clicked, workflowElement]);

  useEffect(() => {
    if (!workflowElement.link) {
      setClicked(true);
    }
  }, [workflowElement, setClicked]);

  const onClick = useCallback(() => {
    setClicked(true);
  }, [setClicked]);

  return (
    <div className="workflow-element-image">
      <div className="workflow-element-image-content">
        <a
          href={workflowElement.link}
          target="_blank"
          rel="noreferrer noopener"
          onClick={onClick}
        >
          <img
            src={workflowElement.sourceUri || workflowElement.path || workflowElement.image?.sourceUri || workflowElement.image?.path}
            alt={workflowElement.name}
          />
        </a>
      </div>
    </div>
  );
};

export default ImageLinkWorkflowElement;
