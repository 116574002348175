import { Alert, Button, Form, FormProps } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useAppDispatch } from 'app/hooks';
import FormActions from 'app/modules/pux/components/layout/formActions';
import { CommencedWorkflowContext } from 'app/modules/pux/contexts';
import { useSortedWorkflowElements } from 'app/modules/pux/hooks';
import { workflowsActions } from 'app/modules/workflows';
import QualieAPI from 'lib/modules/qualieApi';
import { IWorkflowElement, IWorkflowFormElement, IWorkflowFormElementElement } from 'lib/modules/qualieApi/entities/workflow';
import { Dictionary } from 'lib/types';
import Utils from 'lib/utils';
import _ from 'lodash';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import IWorkflowElementProps from '../../../IWorkflowElementProps';
import FormElement from '../formElement';

const getInitialValues = (elements: IWorkflowElement[]) => elements.reduce<Dictionary>((acc, curr) => {
  switch (curr.name) {
    case 'opinionActivities':
      acc[curr.name] = _.sortBy(curr.collection || [], 'position').map(element => element.name);
      break;
    default:
      acc[curr.name] = curr.collection
        ? getInitialValues(curr.collection)
        : curr.value;
      break;
  }

  return acc;
}, {});

const RankQuestionOpinionFormWorkflowElement: React.FunctionComponent<IWorkflowElementProps<IWorkflowFormElement>> = (props) => {
  const { workflowElement, onFinish, onBeforeFinish, skipOnBeforeFinish } = props;
  const [error, setError] = useState<string | null>();
  const [fields, setFields] = useState<FormProps['fields']>();
  const [submitted, setSubmitted] = useState(false);
  const intl = useIntl();
  const { uiTexts } = useContext(CommencedWorkflowContext);
  const dispatch = useAppDispatch();
  const { toNextStep } = useContext(CommencedWorkflowContext);
  const elements = useSortedWorkflowElements(workflowElement.elements);
  const [form] = useForm();
  const initialValues = useMemo(() => getInitialValues(elements), [elements]);

  const onFormFinish = useCallback(async (values) => {
    if (!skipOnBeforeFinish && onBeforeFinish && !(await onBeforeFinish(workflowElement))) {
      return;
    }

    // This code maps the provided form structure to what the API expects.
    const opinionActivities = _.keyBy(elements.find(element => element.name === 'opinionActivities')?.collection, 'name');
    const parsedValues = {
      ...values,
      opinionActivities: _.map(values.opinionActivities, (value, key) => {
        const opinion: IWorkflowFormElementElement = opinionActivities[key];
        const question = _.find(opinion.collection, { name: 'question' });
        const rank = _.isObject(value) ? (value as any).rank.answer : value;
        const option = _.find(_.find(opinion.collection, { name: 'rank' })?.options, { key: rank });

        return {
          opinionType: 'RANK_QUESTION',
          initiativeHash: opinion.name,
          participantHash: values.participantHash,
          type: 'com.qualie.api.dto.RankQuestionOpinionActivityDTO',
          question: question?.value,
          answer: option?.value,
          rank: rank,
        };
      }),
    };

    setSubmitted(true);
    setError(null);
    setFields(fields => fields?.map(a => ({
      name: a.name,
      errors: [],
    })));

    try {
      await dispatch(workflowsActions.submitWorkflowFormElement({
        actionUrl: workflowElement.actionURL,
        form: parsedValues,
      })).unwrap();
    } catch (e: any) {
      setSubmitted(false);
      switch (e?.code) {
        case QualieAPI.codes.response.InvalidForm:
          setFields(Utils.apiToFormErrors(e?.data || {}, []));
          break;
        case QualieAPI.codes.response.QuotaFull:
        case QualieAPI.codes.response.Terminated:
          toNextStep();

          return;
      }
      setError(e?.message || ((uiTexts?.WORKFLOW_ELEMENT_FORM_GENERIC_SERVER_ERROR) ? intl.formatMessage({
        id:  'NOT_DEFINED',
        defaultMessage: uiTexts?.WORKFLOW_ELEMENT_FORM_GENERIC_SERVER_ERROR?.value,
      }) : intl.formatMessage({ id: 'workflowElement.form.genericServerError' })));

      return;
    }

    if (onFinish) {
      onFinish(workflowElement);
    }
  }, [elements, intl, workflowElement, dispatch, setFields, onFinish, onBeforeFinish, toNextStep, uiTexts, skipOnBeforeFinish]);

  useEffect(() => {
    setSubmitted(false);
  }, [initialValues]);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFormFinish}
      initialValues={initialValues}
      fields={fields}
    >
      {!!error && (
        <Alert
          type="error"
          message={error}
          showIcon
        />
      )}
      {elements.map(a => (
        <FormElement
          key={a.name}
          workflowElement={a}
        />
      ))}
      <FormActions>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            disabled={submitted}
          >{workflowElement.submitButtonText}</Button>
        </Form.Item>
      </FormActions>
    </Form>
  );
};

export default RankQuestionOpinionFormWorkflowElement;