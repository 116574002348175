import { DraggingStyle, NotDraggingStyle } from 'react-beautiful-dnd';

type Dictionary<T = any> = { [x: string]: T };

type DraggableStyle = DraggingStyle | NotDraggingStyle | undefined;

type FormElementName = (string | number)[];

type QualieFile = {
  duration?: string;
  filmedVideoType?: FilmedVideoType;
  hash: string;
  height?: string;
  id: string;
  key?: string;
  name: string;
  path: string;
  retryCount?: number;
  sourceUri: string;
  link?: string | null;
  status?: FileStatus;
  thumbnail?: string;
  type: QualieFileType;
  videoProvider?: VideoProvider;
  width?: string;
};

type Stimulus = {
  hash: string;
  id: number;
  name: string;
  position: number;
  qualieFile: QualieFile;
  stimulusType: StimulusType;
};

enum FileStatus {
  BeingRetrieved = 'BEING_RETRIEVED',
  Deleted = 'DELETED',
  Failed = 'FAILED',
  Processing = 'PROCESSING',
  Queued = 'QUEUED',
  QueuedInProcessing = 'QUEUED_IN_PROCESSING',
  ProcessingFailed = 'PROCESSING_FAILED',
  Ready = 'READY',
  UploadFailed = 'UPLOAD_FAILED',
}

enum FilmedVideoType {
  Asset = 'ASSET',
  Curated = 'CURATED',
  Material = 'MATERIAL',
  Opinion = 'OPINION',
  Strategy = 'STRATEGY',
  WarmUp = 'WARM_UP',
}

enum QualieFileType {
  Audio = 'AUDIO',
  Download = 'DOWNLOAD',
  Html = 'HTML',
  Image = 'IMAGE',
  ImageAudio = 'IMAGE_AUDIO',
  ImageLink = 'IMAGE_LINK',
  ImagePrototypeLink = 'IMAGE_PROTOTYPE_LINK',
  Pdf = 'PDF',
  Ppt = 'PPT',
  Pptx = 'PPTX',
  Video = 'VIDEO',
}

enum StimulusType {
  Audio = 'AUDIO',
  AudioAd = 'AUDIO_AD',
  AudioScript = 'AUDIO_SCRIPT',
  ConceptStatement = 'CONCEPT_STATEMENT',
  DigitalBanner = 'DIGITAL_BANNER',
  DigitalDynamic = 'DIGITAL_DYNAMIC',
  DigitalSuite = 'DIGITAL_SUITE',
  FinishedTvc = 'FINISHED_TVC',
  Image = 'IMAGE',
  ImageAppDownloadLink = 'IMAGE_APPDOWNLOAD_LINK',
  ImageAudio = 'IMAGE_AUDIO',
  ImageAudioAd = 'IMAGE_AUDIO_AD',
  ImageLink = 'IMAGE_LINK',
  ImagePrototypeLink = 'IMAGE_PROTOTYPE_LINK',
  ImageWebsiteLink = 'IMAGE_WEBSITE_LINK',
  Outdoor = 'OUTDOOR',
  RouchTvc = 'ROUGH_TVC',
  SetupImage = 'SETUP_IMAGE',
  SetupVideo = 'SETUP_VIDEO',
  TvcScene = 'TVC_SCENE',
  Video = 'VIDEO',
}

enum VideoProvider {
  VideoJs = 'VIDEOJS',
  Vimeo = 'VIMEO',
  Wistia = 'WISTIA',
  Youtube = 'YOUTUBE',
}

const imageLinkTypes: string[] = [
  StimulusType.ImageAppDownloadLink,
  StimulusType.ImageLink,
  StimulusType.ImagePrototypeLink,
  StimulusType.ImageWebsiteLink,
];

const imageStimulusTypes: string[] = [
  StimulusType.AudioScript,
  StimulusType.Image,
  StimulusType.Outdoor,
  StimulusType.SetupImage,
  StimulusType.TvcScene,
];

const videoStimulusTypes: string[] = [
  StimulusType.DigitalDynamic,
  StimulusType.DigitalSuite,
  StimulusType.FinishedTvc,
  StimulusType.ImageAudioAd,
  StimulusType.RouchTvc,
  StimulusType.SetupVideo,
  StimulusType.Video,
];

export type {
  Dictionary,
  DraggableStyle,
  FileStatus,
  FilmedVideoType,
  FormElementName,
  QualieFile,
  QualieFileType,
  Stimulus,
  VideoProvider,
};

export {
  imageLinkTypes,
  imageStimulusTypes,
  StimulusType,
  videoStimulusTypes,
};