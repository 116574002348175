import _ from 'lodash/fp';
import { IWorkflowElement, IWorkflowFormElementElement } from 'lib/modules/qualieApi/entities/workflow';
import React, { useCallback, useContext, useMemo } from 'react';
import { IWorkflowFormElementProps } from './types';
import InitiativeWorkflowElement from '../../../initiative';
import { CommencedWorkflowContext } from 'app/modules/pux/contexts';
import { QualieFile, StimulusType } from 'lib/types';
import ThemometerFormElement from './thermometer';
import cx from 'classnames';
import { Form } from 'antd';
import ErrorMessage from '../../../errorMessage';

type Stimuli = {
  hash: string;
  id: number;
  name: string;
  position: number;
  qualieFile: QualieFile;
  stimulusType: StimulusType;
};

interface IInitiative extends IWorkflowElement {
  group: string;
  hash: string;
  id: number;
  internalName: string;
  name: string;
  stimuli: Stimuli[];
}

interface IOpinionContainerProps {
  element: IWorkflowElement;
  initiative: IInitiative;
}

const OpinionContainer: React.FunctionComponent<IOpinionContainerProps> = ({
  element,
  initiative,
}) => {
  const { setPrerequisite, prerequisites, uiTexts } = useContext(CommencedWorkflowContext);
  const rankElement: IWorkflowFormElementElement = useMemo(() => _.find({ name: 'rank' }, element.collection), [element.collection]) as IWorkflowFormElementElement;
  const initiativeWorkflowElement: IWorkflowElement = useMemo(() => ({
    name: `initiatives-${initiative?.hash}`,
    visible: true,
    workflowElementType: 'COLLECTION',
    collection: [initiative],
  }), [initiative]);

  const onInitiativesValid = useCallback((element: IWorkflowElement, valid: boolean) => {
    setPrerequisite(element.name, valid ? null : {
      valid: valid,
      error: (!valid && uiTexts?.VIEW_ALL_CONTENT?.value) || null,
      reveal: false,
    });
  }, [setPrerequisite, uiTexts]);

  return (
    <div className="ranking-opinion-container">
      <div className="ranking-opinion-container-content">
        <InitiativeWorkflowElement
          workflowElement={initiativeWorkflowElement}
          onValid={onInitiativesValid}
        />
        <ThemometerFormElement
          workflowElement={rankElement}
          path={['opinionActivities', element.name]}
        />
        <div className="ranking-opinion-container-error-text">
          <Form.Item
            shouldUpdate={true}
            noStyle={true}
          >
              {form => {
                const error = form.getFieldError(element.name);
                const prerequisiteError = prerequisites?.[`initiatives-${initiative.hash}`];

                return (
                  <React.Fragment>
                    {error.length > 0 && <ErrorMessage message={error[0]} />}
                    {prerequisiteError?.reveal && <ErrorMessage message={prerequisiteError.error!} />}
                  </React.Fragment>
                );
              }}
            </Form.Item>
          </div>
      </div>
    </div>
  );
};

const RankingOpinionFormElement: React.FunctionComponent<IWorkflowFormElementProps<IWorkflowFormElementElement>> = (props) => {
  const { dependencies, workflowElement } = props;
  const wrapperClassName = useMemo(() => cx({
    'ranking-opinion-form-element': true,
    'multiple': (workflowElement.collection || []).length > 1,
  }), [workflowElement.collection]);
  const initiatives = dependencies?.initiatives?.collection;

  return (
    <div className={wrapperClassName}>
      {workflowElement.collection?.map((element, index) => {
        const initiative: any = _.find({ hash: element.name }, initiatives || []);

        return (
          <OpinionContainer
            element={element}
            key={`ranking-opinion-container-${index}`}
            initiative={initiative}
          />
        );
      })}
    </div>
  );
};

export default RankingOpinionFormElement;
