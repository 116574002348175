import { Alert, Button, Form, FormProps } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useAppDispatch } from 'app/hooks';
import FormActions from 'app/modules/pux/components/layout/formActions';
import { CommencedWorkflowContext, FormElementContext, IFormElementContext } from 'app/modules/pux/contexts';
import { useSortedWorkflowElements } from 'app/modules/pux/hooks';
import { workflowsActions } from 'app/modules/workflows';
import QualieAPI from 'lib/modules/qualieApi';
import { IWorkflowElement, IWorkflowFormElement, IWorkflowFormElementElement } from 'lib/modules/qualieApi/entities/workflow';
import { Dictionary } from 'lib/types';
import Utils from 'lib/utils';
import _ from 'lodash';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import IWorkflowElementProps from '../../../IWorkflowElementProps';
import FormElement from '../formElement';
import { WORKFLOW_ELEMENT_FORM_ELEMENT_TYPES } from '../formElement/types';

type Initiative = {
  initiativeHash: string;
  this_one_text: string;
  opinionType: string;
  type: string;
  preference: number;
}

const getInitialValues = (elements: IWorkflowElement[]) => elements.reduce<Dictionary>((acc, curr) => {
  switch (curr.name) {
    case 'opinionActivities':
      acc[curr.name] = {
        preferences: _.sortBy(curr.collection || [], 'position').map(element => element.name),
        selected: null,
      };
      break;
    default:
      acc[curr.name] = curr.collection
        ? getInitialValues(curr.collection)
        : curr.value;
      break;
  }

  return acc;
}, {});

const PreferenceOpinionMultiActivityFormWorkflowElement: React.FunctionComponent<IWorkflowElementProps<IWorkflowFormElement, {initiatives: IWorkflowElement}>> = (props) => {
  const { dependencies, workflowElement, onFinish, onBeforeFinish, skipOnBeforeFinish } = props;
  const [error, setError] = useState<string | null>();
  const [fields, setFields] = useState<FormProps['fields']>();
  const [submitted, setSubmitted] = useState(false);
  const [groupedInitiatives] = useState(_.groupBy((dependencies?.initiatives?.collection || []), 'name'));
  const [initiatives] = useState<IWorkflowElement[]>(dependencies?.initiatives?.collection || []);
  const intl = useIntl();
  const { uiTexts } = useContext(CommencedWorkflowContext);
  const { activityOptions, toNextStep } = useContext(CommencedWorkflowContext);
  const dispatch = useAppDispatch();
  const elements = useSortedWorkflowElements(workflowElement.elements);
  const [form] = useForm();
  const initialValues = useMemo(() => getInitialValues(elements), [elements]);
  const typeMapper = useCallback((element: IWorkflowFormElementElement) => {
    if (element.name === 'opinionActivities' && element.inputType === WORKFLOW_ELEMENT_FORM_ELEMENT_TYPES.Collection) {
      return WORKFLOW_ELEMENT_FORM_ELEMENT_TYPES.PreferenceSingleOpinion;
    }

    return undefined;
  }, []);
  const formElementContextValue: IFormElementContext = useMemo(() => ({
    form: form,
    typeMapper: typeMapper,
  }), [form, typeMapper]);

  const onFormFinish = useCallback(async (values) => {
    if (!skipOnBeforeFinish && onBeforeFinish && !(await onBeforeFinish(workflowElement))) {
      return;
    }

    const matchedInitiative: IWorkflowElement = _.find(initiatives, { hash: values.opinionActivities.selected }) as IWorkflowElement;

    let preference = 2;
    let results: Initiative[] = [];
    _.forEach(groupedInitiatives, (groupedInitiative: IWorkflowElement[], groupKey: string) => {
      const preferenceNumber = (matchedInitiative.name === groupKey) ? 1 : preference++;
      _.forEach(groupedInitiative, (val: any) => {
        results.push({
          initiativeHash: val.hash,
          this_one_text: activityOptions.this_one_text!,
          opinionType: 'PREFERENCE',
          type: 'com.qualie.api.dto.PreferenceOpinionActivityDTO',
          preference: preferenceNumber,
        });
      });
    });

    const parsedValues = {
      ...values,
      opinionActivities: results,
    };

    setSubmitted(true);
    setError(null);
    setFields(fields => fields?.map(a => ({
      name: a.name,
      errors: [],
    })));

    try {
      await dispatch(workflowsActions.submitWorkflowFormElement({
        actionUrl: workflowElement.actionURL,
        form: parsedValues,
      })).unwrap();
    } catch (e: any) {
      setSubmitted(false);
      switch (e?.code) {
        case QualieAPI.codes.response.InvalidForm:
          setFields(Utils.apiToFormErrors(e?.data || {}, []));
          break;
        case QualieAPI.codes.response.QuotaFull:
        case QualieAPI.codes.response.Terminated:
          toNextStep();

          return;
      }
      setError(e?.message || ((uiTexts?.WORKFLOW_ELEMENT_FORM_GENERIC_SERVER_ERROR) ? intl.formatMessage({
        id:  'NOT_DEFINED',
        defaultMessage: uiTexts?.WORKFLOW_ELEMENT_FORM_GENERIC_SERVER_ERROR?.value,
      }) : intl.formatMessage({ id: 'workflowElement.form.genericServerError' })));

      return;
    }

    if (onFinish) {
      onFinish(workflowElement);
    }
  }, [activityOptions, groupedInitiatives, initiatives, intl, workflowElement, dispatch, setFields, onFinish, onBeforeFinish, toNextStep, uiTexts, skipOnBeforeFinish]);

  useEffect(() => {
    setSubmitted(false);
  }, [initialValues]);

  return (
    <FormElementContext.Provider value={formElementContextValue}>
      <Form
        className="preference-single-opinion-form"
        form={form}
        layout="horizontal"
        onFinish={onFormFinish}
        initialValues={initialValues}
        fields={fields}
      >
        {!!error && (
          <Alert
            type="error"
            message={error}
            showIcon
          />
        )}
        {elements.map(a => (
          <FormElement
            dependencies={dependencies}
            key={a.name}
            workflowElement={a}
          />
        ))}
        <FormActions>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              disabled={submitted}
            >{workflowElement.submitButtonText}</Button>
          </Form.Item>
        </FormActions>
      </Form>
    </FormElementContext.Provider>
  );
};

export default PreferenceOpinionMultiActivityFormWorkflowElement;