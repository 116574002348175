import React from 'react';
import IWorkflowElementProps from '../IWorkflowElementProps';

const HeadingWorkflowElement: React.FunctionComponent<IWorkflowElementProps> = (props) => {
  const { workflowElement } = props;

  return <h1>{workflowElement.value}</h1>;
};

export default HeadingWorkflowElement;
