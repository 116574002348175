import React from 'react';
import ReactMarkdown from 'react-markdown';

const WorkflowActivitySubtitle: React.FunctionComponent = ({ children }) => (
  <h2 className="workflow-activity-subtitle">
    <ReactMarkdown>{children as string}</ReactMarkdown>
  </h2>
);

export default WorkflowActivitySubtitle;
