type ApiConfig = {
  hosts: {
    gateway: string;
  };
};

type ApiRequest = {
  method: REQUEST_METHODS;
  url: string;
  headers?: { [header: string]: string };
  body?: any;
};
type ApiResponse<TBody = any> = {
  body: TBody;
  status: number;
  statusText: string;
  ok: boolean;
};

type ApiQueryParms = { [property: string]: any }

type RequestContext = <TBody, >(config: ApiConfig) => Promise<ApiResponse<TBody>>;

class QualieApiError extends Error { }
class QualieApiUninitialisedError extends QualieApiError {
  message = 'Attempted to create a request context before API was initialised!';
}

enum REQUEST_METHODS {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
  OPTIONS = 'OPTIONS',
};

enum RESPONSE_CODES {
  Success = '20',
  NoContent = '21',
  NotFound = '23',
  Failed = '40',
  AgeNotAllowed = '41',
  InvalidForm = '42',
  QuotaFull = '43',
  ParticipantExists = '44',
  ParticipantTerminated = '45',
  InactiveWorkflow = '46',
  ProviderNotAllowed = '47',
  Terminated = '41',
};

enum ERROR_CODES {
  Invalid = 400,
};

export type {
  ApiConfig,
  RequestContext,
  ApiRequest,
  ApiResponse,
  ApiQueryParms,
};

export {
  QualieApiError,
  QualieApiUninitialisedError,
  REQUEST_METHODS,
  RESPONSE_CODES,
  ERROR_CODES,
};
