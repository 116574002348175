import { Alert, Button, Form, FormProps } from 'antd';
import { useAppDispatch } from 'app/hooks';
import { useSortedWorkflowElements } from 'app/modules/pux/hooks';
import { workflowsActions } from 'app/modules/workflows';
import QualieAPI from 'lib/modules/qualieApi';
import { IWorkflowElement, IWorkflowFormElement } from 'lib/modules/qualieApi/entities/workflow';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import FormElement from '../formElement';
import { IWorkflowFormElementProps } from '../formElement/types/types';
import { WORKFLOW_ELEMENT_FORM_ELEMENT_TYPES } from '../formElement/types';
import FormActions from 'app/modules/pux/components/layout/formActions';
import Utils from 'lib/utils';
import { Dictionary } from 'lib/types';
import { CommencedWorkflowContext } from 'app/modules/pux/contexts';

const FORM_ELEMENT_OVERRIDES: Dictionary<WORKFLOW_ELEMENT_FORM_ELEMENT_TYPES> = {
  rating: WORKFLOW_ELEMENT_FORM_ELEMENT_TYPES.ImageRatingRadio,
};

const getInitialValues = (elements: IWorkflowElement[]) => elements.reduce<Dictionary>((acc, curr) => {
  acc[curr.name] = curr.collection
    ? getInitialValues(curr.collection)
    : curr.value;

  return acc;
}, {});

const ReviewImageOpinionFormWorkflowElement: React.FunctionComponent<IWorkflowFormElementProps<IWorkflowFormElement>> = (props) => {
  const { workflowElement, onFinish, onBeforeFinish } = props;
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const { uiTexts } = useContext(CommencedWorkflowContext);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState<string | null>();
  const [fields, setFields] = useState<FormProps['fields']>();
  const { toNextStep } = useContext(CommencedWorkflowContext);
  const elements = useSortedWorkflowElements(workflowElement.elements);
  const initialValues = useMemo(() => getInitialValues(elements), [elements]);

  const onFormFinish = useCallback(async (values) => {
    if (onBeforeFinish && !(await onBeforeFinish(workflowElement))) {
      return;
    }

    setSubmitted(true);
    setError(null);
    setFields(fields => fields?.map(a => ({
      name: a.name,
      errors: [],
    })));

    const payload = {
      participantHash: values.participantHash,
      reviewTilesType: values.reviewTilesType,
      imageReviewOpinions: [
        {
          opinionHash: values.opinionHash,
          rating: values.rating,
        },
      ],
    };

    try {
      await dispatch(workflowsActions.submitWorkflowFormElement({
        actionUrl: workflowElement.actionURL,
        form: payload,
      })).unwrap();
    } catch (e: any) {
      setSubmitted(false);
      switch (e?.code) {
        case QualieAPI.codes.response.InvalidForm:
          setFields(Utils.apiToFormErrors(e?.data || {}, [
            {
              from: 'imageReviewOpinions[0].',
              to: '',
            },
          ]));
          break;
        case QualieAPI.codes.response.QuotaFull:
        case QualieAPI.codes.response.Terminated:
          toNextStep();

          return;
      }
      setError(e?.message || ((uiTexts?.WORKFLOW_ELEMENT_FORM_GENERIC_SERVER_ERROR) ? intl.formatMessage({
        id:  'NOT_DEFINED',
        defaultMessage: uiTexts?.WORKFLOW_ELEMENT_FORM_GENERIC_SERVER_ERROR?.value,
      }) : intl.formatMessage({ id: 'workflowElement.form.genericServerError' })));

      return;
    }

    if (onFinish) {
      onFinish(workflowElement);
    }
  }, [intl, workflowElement, dispatch, setFields, onFinish, onBeforeFinish, toNextStep, uiTexts]);

  useEffect(() => {
    setSubmitted(false);
  }, [initialValues]);

  return (
    <Form
      layout="vertical"
      onFinish={onFormFinish}
      initialValues={initialValues}
      fields={fields}
    >
      {!!error && (
        <Alert
          type="error"
          message={error}
          showIcon
        />
      )}
      {elements.map(element => (
        <FormElement
          asType={FORM_ELEMENT_OVERRIDES[element.name]}
          key={element.name}
          workflowElement={element}
        />
      ))}
      <FormActions>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            disabled={submitted}
          >{workflowElement.submitButtonText}</Button>
        </Form.Item>
      </FormActions>
    </Form>
  );
};

export default ReviewImageOpinionFormWorkflowElement;
