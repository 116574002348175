import _ from 'lodash';
import { AudioOutlined, CaretRightOutlined, CloseOutlined, DislikeFilled, LikeFilled, SettingFilled, VideoCameraOutlined, WarningFilled } from '@ant-design/icons';
import { useVideoRecorder, VideoRecorderStatus } from 'app/hooks/video';
import { CommencedWorkflowContext } from 'app/modules/pux/contexts';
import QualieAPI from 'lib/modules/qualieApi';
import { IWorkflowQualieCameraElement } from 'lib/modules/qualieApi/entities/workflow';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import IWorkflowElementProps from '../../IWorkflowElementProps';
import { Button, Divider, Form, notification, Progress, Select } from 'antd';
import cx from 'classnames';
import { useCountdown } from 'app/hooks';
import { useIntl } from 'react-intl';
import { QuestionLabel } from '../../../content';
import RecordProgress from './recordProgress';
import { useSilenceMonitor } from 'app/hooks/audio';
import SoundWaveform from './soundWaveform';
import Guidelines from './guidelines';
import rollbar from 'lib/rollbar';
import { ApiResponse } from 'lib/modules/qualieApi/types';
import Utils from 'lib/utils';

const COUNTDOWN = 3;
const DEFAULT_MAX_RECORD_TIME = 120;
const DEFAULT_MIN_RECORD_TIME = (Number)(process.env.REACT_APP_MIN_RECORD_TIME);

const getTrackDeviceId = (track?: MediaStreamTrack | null) => track != null
  ? track.getCapabilities
    ? track.getCapabilities().deviceId
    : track.getSettings
      ? track.getSettings().deviceId
      : null
  : null;

const getVideoConfig = (maxRecordMs: number) => {
  /**
   * https://github.com/collab-project/videojs-record/issues/627
   * On some of the iOS devices, the video gets too big so that Safari fails to process the file and returns 0-byted one without failing (which is undocumented and, obviously, an error).
   * As a workaround, we reduce the quality of a video in the native recording API (which is used by the `videojs-record` and `RecordRTC` internally) so that it won't get to this size.
   *
   * TODO: remove it when https://bugs.webkit.org/show_bug.cgi?id=85851 is resolved by Apple.
   */
  if (Utils.isIOS() && [15, 16].includes(Utils.getIOSVersion()[0] as number)) {
    if (maxRecordMs <= (60 * 1000)) {
      return {
        fps: 30,
        res: 1920,
        mbs: 5,
        maxMs: maxRecordMs,
      };
    } else if (maxRecordMs <= (90 * 1000)) {
      return {
        fps: 30,
        res: 1280,
        mbs: 3.2,
        maxMs: maxRecordMs,
      };
    } else if (maxRecordMs <= (180 * 1000)) {
      return {
        fps: 30,
        res: 1280,
        mbs: 2.5,
        maxMs: maxRecordMs,
      };
    } else {
      return {
        fps: 30,
        res: 640,
        mbs: 1.3,
        maxMs: 240 * 1000,
      };
    }
  }

  if (maxRecordMs <= (90 * 1000)) {
    return {
      fps: 30,
      res: 1920,
      mbs: 5,
      maxMs: maxRecordMs,
    };
  }

  // Drop the bit rate a bit for everyone if the video could be very long
  return {
    fps: 30,
    res: 1280,
    mbs: 3,
    maxMs: maxRecordMs,
  };
};

interface IRecordingActionButtonProps {
  onClick: () => void;
}

const RecordButton: React.FunctionComponent<IRecordingActionButtonProps> = ({
  onClick,
}) => (
  <button
    onClick={onClick}
    className="qualie-camera-workflow-element-recording-action record animate__animated animate__zoomInUp"
  />
);

const StopButton: React.FunctionComponent<IRecordingActionButtonProps> = ({
  onClick,
}) => (
  <button
    onClick={onClick}
    className="qualie-camera-workflow-element-recording-action stop animate__animated animate__zoomInUp"
  />
);

const ResetButton: React.FunctionComponent<IRecordingActionButtonProps> = ({
  onClick,
}) => {
  const intl = useIntl();
  const { uiTexts } = useContext(CommencedWorkflowContext);

  const resetLabel = (uiTexts?.WORKFLOW_ELEMENT_QUALIECAMERA_ACTION_RESET_LABEL) ? intl.formatMessage({
    id:  'NOT_DEFINED',
    defaultMessage: uiTexts?.WORKFLOW_ELEMENT_QUALIECAMERA_ACTION_RESET_LABEL?.value,
  }) : intl.formatMessage({ id: 'workflowElement.qualieCamera.action.reset.label' });

  return (
    <Button
      onClick={onClick}
      className="qualie-camera-workflow-element-recording-action reset"
      icon={<DislikeFilled />}
      type="primary"
    >
      {resetLabel}
    </Button>
  );
};

const UploadButton: React.FunctionComponent<IRecordingActionButtonProps> = ({
  onClick,
}) => {
  const intl = useIntl();
  const { uiTexts } = useContext(CommencedWorkflowContext);

  const uploadLabel = (uiTexts?.WORKFLOW_ELEMENT_QUALIECAMERA_ACTION_UPLOAD_LABEL) ? intl.formatMessage({
    id:  'NOT_DEFINED',
    defaultMessage: uiTexts?.WORKFLOW_ELEMENT_QUALIECAMERA_ACTION_UPLOAD_LABEL?.value,
  }) : intl.formatMessage({ id: 'workflowElement.qualieCamera.action.upload.label' });

  return (
    <Button
      onClick={onClick}
      className="qualie-camera-workflow-element-recording-action upload"
      icon={<LikeFilled />}
      type="primary"
    >
      {uploadLabel}
    </Button>
  );
};

const PreviewButton: React.FunctionComponent<IRecordingActionButtonProps> = ({
  onClick,
}) => {
  const intl = useIntl();
  const { uiTexts } = useContext(CommencedWorkflowContext);

  const previewLabel = (uiTexts?.WORKFLOW_ELEMENT_QUALIECAMERA_ACTION_PREVIEW_LABEL) ? intl.formatMessage({
    id:  'NOT_DEFINED',
    defaultMessage: uiTexts?.WORKFLOW_ELEMENT_QUALIECAMERA_ACTION_PREVIEW_LABEL?.value,
  }) : intl.formatMessage({ id: 'workflowElement.qualieCamera.action.preview.label' });

  return (
    <Button
      onClick={onClick}
      className="qualie-camera-workflow-element-recording-action upload"
      icon={<CaretRightOutlined />}
      type="primary"
    >
      {previewLabel}
    </Button>
  );
};

const DevicesButton: React.FunctionComponent<IRecordingActionButtonProps> = ({
  onClick,
}) => (
  <button
    onClick={onClick}
    className="qualie-camera-workflow-element-recording-action devices animate__animated animate__zoomInDown"
  >
    <SettingFilled />
  </button>
);

const CancelButton: React.FunctionComponent<IRecordingActionButtonProps> = ({
  onClick,
}) => (
  <button
    onClick={onClick}
    className="qualie-camera-workflow-element-recording-action cancel animate__animated animate__zoomInDown"
  >
    <CloseOutlined />
  </button>
);

const RemainingTime: React.FunctionComponent<{ value: number }> = ({
  value,
}) => {
  const label = useMemo(() => {
    const asSeconds = value / 1000;
    const minutes = Math.floor(asSeconds / 60);
    const seconds = Math.floor(asSeconds % 60);

    return `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  }, [value]);

  return (<div className="qualie-camera-worfklow-element-remaining-time animate__animated animate__zoomInUp">{label}</div>);
};

enum QualieCameraStatus {
  Record = 'RECORD',
  Uploading = 'UPLOADING',
  Preview = 'PREVIEW',
  DeviceConfiguration = 'DEVICE_CONFIGURATION',
};

type DeviceConfiguration = {
  video: MediaDeviceInfo[];
  audio: MediaDeviceInfo[];
  currentAudioDeviceId: string;
  currentVideoDeviceId: string;
};

const QualieCameraWorkflowElement: React.FunctionComponent<IWorkflowElementProps<IWorkflowQualieCameraElement>> = ({
  workflowElement,
  onFinish,
  hide,
}) => {
  const intl = useIntl();
  const minRecordTime = DEFAULT_MIN_RECORD_TIME * 1000;
  const maxRecordTime = useMemo(() => {
    let value = Number.parseInt(workflowElement.recordLength);
    if (Number.isNaN(value) || value == null) {
      value = DEFAULT_MAX_RECORD_TIME;
    }

    return value * 1000;
  }, [workflowElement.recordLength]);
  const videoConfig = useMemo(() => getVideoConfig(maxRecordTime), [maxRecordTime]);

  const [
    onStartRecording,
    onStopRecording,
    onReset,
    onGetDevices,
    onSetDevices,
    videoRecorderStatus,
    duration,
    mediaStream,
    recordingBlob,
  ] = useVideoRecorder(videoConfig);
  const [status, setStatus] = useState<QualieCameraStatus>(QualieCameraStatus.Record);
  const [nextStatus, setNextStatus] = useState<QualieCameraStatus>();
  const livePreviewRef = useRef<HTMLVideoElement | null>();
  const previewRef = useRef<HTMLVideoElement | null>();
  const [countdown, setCountdown] = useCountdown();
  const { participant, workflowId } = useContext(CommencedWorkflowContext);
  const [revealProgress, setRevealProgress] = useState(false);
  const [previewProgress, setPreviewProgress] = useState(0);
  const [uploadProgress, setUploadProgress] = useState<number | null>(null);
  const [recordingDataUrl, setRecordingDataUrl] = useState<string>();
  const [silenceDetected] = useSilenceMonitor(videoRecorderStatus === VideoRecorderStatus.Recording ? mediaStream : null);
  const [devices, setDevices] = useState<DeviceConfiguration>();
  const [flipVideo] = useState(true);
  const { uiTexts } = useContext(CommencedWorkflowContext);
  const [isHidden, setIsHidden] = useState<boolean>(false);

  const className = useMemo(() => cx({
    'qualie-camera-workflow-element': true,
    'active': countdown != null || videoRecorderStatus === VideoRecorderStatus.Recording,
    'uploading': status === QualieCameraStatus.Uploading,
    'preview': status === QualieCameraStatus.Preview,
    'flip-video': flipVideo,
  }), [status, countdown, videoRecorderStatus, flipVideo]);

  const onTryStopRecording = useCallback(() => {
    if (duration < minRecordTime) {
      setRevealProgress(true);
    } else {
      onStopRecording();
    }
  }, [duration, minRecordTime, onStopRecording]);

  const onStartCountdown = useCallback(() => {
    setCountdown(COUNTDOWN);
  }, [setCountdown]);

  const onStartUpload = useCallback(async () => {
    if (!workflowId || !participant?.hash || !recordingBlob) {
      return;
    }

    setNextStatus(QualieCameraStatus.Uploading);
  }, [participant?.hash, recordingBlob, workflowId]);

  const onUpload = useCallback(async () => {
    if (!workflowId || !participant?.hash || !recordingBlob) {
      return;
    }

    let result: ApiResponse;
    const now = (new Date()).valueOf();
    setUploadProgress(0);
    try {
      result = await QualieAPI.Workflow.UploadParticipantVideo(workflowId, participant.hash, recordingBlob, (progress, total) => {
        setUploadProgress(Math.round(_.clamp((progress || 0) / (total || 1), 0, 1) * 100));
      });
      if (!result.ok) {
        rollbar.error('Qualie Video Upload Rejected', result, {
          blob: {
            size: recordingBlob.size,
            type: recordingBlob.type,
          },
          videoConfig: videoConfig,
          recordDuration: duration,
          uploadDuration: (new Date().valueOf()) - now,
        });
        setNextStatus(QualieCameraStatus.Record);

        return;
      } else {
        rollbar.info('Qualie Video Upload Successful', result, {
          blob: {
            size: recordingBlob.size,
            type: recordingBlob.type,
          },
          videoConfig: videoConfig,
          recordDuration: duration,
          uploadDuration: (new Date().valueOf()) - now,
        });
      }
    } catch (e: any) {
      rollbar.error('Qualie Video Upload Failed', e, {
        blob: {
          size: recordingBlob.size,
          type: recordingBlob.type,
        },
        videoConfig: videoConfig,
        recordDuration: duration,
        uploadDuration: (new Date().valueOf()) - now,
      });
      setNextStatus(QualieCameraStatus.Record);

      return;
    }

    setUploadProgress(100);

    onFinish?.(workflowElement, result.body.data);
  }, [
    onFinish,
    setNextStatus,
    participant?.hash,
    recordingBlob,
    workflowElement,
    workflowId,
    videoConfig,
    duration,
  ]);

  const onPlayPreview = useCallback(() => {
    if (previewRef.current) {
      previewRef.current.pause();
      previewRef.current.currentTime = 0;
      previewRef.current.play();
    }
  }, []);

  const onStartPreview = useCallback(() => {
    setNextStatus(QualieCameraStatus.Preview);
    onPlayPreview();
  }, [onPlayPreview]);

  const onEndPreview = useCallback(() => {
    try {
      if (previewRef.current) {
        previewRef.current.pause();
        previewRef.current.currentTime = 0;
      }
    } catch (e) {
      console.warn(e);
    };
    setNextStatus(QualieCameraStatus.Record);
  }, []);

  const onDeviceConfiguration = useCallback(async () => {
    const devices = await onGetDevices();
    const audioTrack = mediaStream?.getAudioTracks()[0];
    const videoTrack = mediaStream?.getVideoTracks()[0];

    setDevices({
      video: devices.filter(a => a.kind === 'videoinput'),
      audio: devices.filter(a => a.kind === 'audioinput'),
      currentAudioDeviceId: getTrackDeviceId(audioTrack) || 'default',
      currentVideoDeviceId: getTrackDeviceId(videoTrack) || 'default',
    });
    setNextStatus(QualieCameraStatus.DeviceConfiguration);
  }, [onGetDevices, mediaStream]);

  const onDeviceConfigurationClose = useCallback(() => {
    setNextStatus(QualieCameraStatus.Record);
  }, []);

  const onChangeVideo = useCallback((deviceId: string) => {
    setDevices(value => ({
      ...value,
      currentVideoDeviceId: deviceId,
    }) as DeviceConfiguration);
    onSetDevices({ videoDeviceId: deviceId });
  }, [onSetDevices]);

  const onChangeAudio = useCallback((deviceId: string) => {
    setDevices(value => ({
      ...value,
      currentAudioDeviceId: deviceId,
    }) as DeviceConfiguration);
    onSetDevices({ audioDeviceId: deviceId });
  }, [onSetDevices]);

  useEffect(() => {
    if (mediaStream != null) {
      if (livePreviewRef.current) {
        livePreviewRef.current.srcObject = mediaStream;
      }
    }
  }, [mediaStream]);

  useEffect(() => {
    if (hide !== isHidden) {
      setIsHidden(hide ? hide : false);

      if (hide === false) {
        if (mediaStream != null) {
          if (livePreviewRef.current) {
            livePreviewRef.current.srcObject = mediaStream;
          }
        }
      }
    }
  }, [hide, isHidden, mediaStream]);

  useEffect(() => {
    if (countdown === 0 && videoRecorderStatus === VideoRecorderStatus.Ready) {
      onStartRecording();
    }
  }, [countdown, videoRecorderStatus, onStartRecording]);

  useEffect(() => {
    if (videoRecorderStatus === VideoRecorderStatus.Recording && duration >= videoConfig.maxMs) {
      onStopRecording();
    }
  }, [videoRecorderStatus, duration, videoConfig.maxMs, onStopRecording]);

  useEffect(() => {
    setRecordingDataUrl(recordingBlob != null ? URL.createObjectURL(recordingBlob) : undefined);
  }, [recordingBlob]);

  useEffect(() => () => {
    if (recordingDataUrl != null) {
      URL.revokeObjectURL(recordingDataUrl);
      setRecordingDataUrl(undefined);
    };
  }, [recordingDataUrl]);

  useEffect(() => {
    if (nextStatus != null && status !== nextStatus) {
      setStatus(nextStatus);
      switch (nextStatus) {
        case QualieCameraStatus.Uploading:
          onUpload();
          break;
        case QualieCameraStatus.Preview:
          setPreviewProgress(0);
          break;
        case QualieCameraStatus.DeviceConfiguration:
          onReset();
          break;
      }
    }
  }, [
    status,
    nextStatus,
    onUpload,
    onStopRecording,
    onReset,
  ]);

  useEffect(() => {
    if (status !== QualieCameraStatus.Preview) {
      return;
    }

    const length = Math.floor(duration / 1000);
    const handle = setInterval(() => {
      setPreviewProgress(Math.ceil(((previewRef.current?.currentTime || 0) / length) * 100));
    }, 1000 / 24);

    return () => {
      clearInterval(handle);
    };
  }, [status, duration]);

  useEffect(() => {
    if (silenceDetected) {
      const message = (uiTexts?.WORKFLOW_ELEMENT_QUALIECAMERA_SILENCE_NOTIFICATION_CONTENT) ? intl.formatMessage({
        id:  'NOT_DEFINED',
        defaultMessage: uiTexts?.WORKFLOW_ELEMENT_QUALIECAMERA_SILENCE_NOTIFICATION_CONTENT?.value,
      }) : intl.formatMessage({ id: 'workflowElement.qualieCamera.silenceNotification.content' });
      const cta = (uiTexts?.WORKFLOW_ELEMENT_QUALIECAMERA_SILENCE_NOTIFICATION_CTA) ? intl.formatMessage({
        id:  'NOT_DEFINED',
        defaultMessage: uiTexts?.WORKFLOW_ELEMENT_QUALIECAMERA_SILENCE_NOTIFICATION_CTA?.value,
      }) : intl.formatMessage({ id: 'workflowElement.qualieCamera.silenceNotification.cta' });

      notification.open({
        key: 'workflowElement.qualieCamera.silenceNotification',
        message: (
          <React.Fragment>
            <div className="qualie-camera-silence-notification-content">{message}</div>
            <Button
              type="primary"
              onClick={() => {
                notification.close('workflowElement.qualieCamera.silenceNotification');
                onStopRecording();
                onDeviceConfiguration();
              }}
            >{cta}</Button>
          </React.Fragment>
        ),
        placement: 'bottom',
        duration: null,
        className: 'qualie-camera-silence-notification',
      });
    } else {
      notification.close('workflowElement.qualieCamera.silenceNotification');
    }
  }, [silenceDetected, intl, onStopRecording, onDeviceConfiguration, uiTexts]);

  const configurationHeading = useMemo(() => {
    return (uiTexts?.WORKFLOW_ELEMENT_QUALIECAMERA_DEVICES_CONFIGURATION_HEADING) ? intl.formatMessage({
      id:  'NOT_DEFINED',
      defaultMessage: uiTexts?.WORKFLOW_ELEMENT_QUALIECAMERA_DEVICES_CONFIGURATION_HEADING?.value,
    }) : intl.formatMessage({ id: 'workflowElement.qualieCamera.devices.configuration.heading' });
  }, [intl, uiTexts]);

  const videoLabel = useMemo(() => {
    return (uiTexts?.WORKFLOW_ELEMENT_QUALIECAMERA_DEVICES_VIDEO_LABEL) ? intl.formatMessage({
      id:  'NOT_DEFINED',
      defaultMessage: uiTexts?.WORKFLOW_ELEMENT_QUALIECAMERA_DEVICES_VIDEO_LABEL?.value,
    }) : intl.formatMessage({ id: 'workflowElement.qualieCamera.devices.video.label' });
  }, [intl, uiTexts]);

  const audioLabel = useMemo(() => {
    return (uiTexts?.WORKFLOW_ELEMENT_QUALIECAMERA_DEVICES_AUDIO_LABEL) ? intl.formatMessage({
      id:  'NOT_DEFINED',
      defaultMessage: uiTexts?.WORKFLOW_ELEMENT_QUALIECAMERA_DEVICES_AUDIO_LABEL?.value,
    }) : intl.formatMessage({ id: 'workflowElement.qualieCamera.devices.audio.label' });
  }, [intl, uiTexts]);

  const submitLabel = useMemo(() => {
    return (uiTexts?.WORKFLOW_ELEMENT_QUALIECAMERA_DEVICES_SUBMIT_LABEL) ? intl.formatMessage({
      id:  'NOT_DEFINED',
      defaultMessage: uiTexts?.WORKFLOW_ELEMENT_QUALIECAMERA_DEVICES_SUBMIT_LABEL?.value,
    }) : intl.formatMessage({ id: 'workflowElement.qualieCamera.devices.submit.label' });
  }, [intl, uiTexts]);

  const noPermissionsText = useMemo(() => {
    return (uiTexts?.WORKFLOW_ELEMENT_QUALIECAMERA_ERROR_NO_PERMISSIONS) ? intl.formatMessage({
      id:  'NOT_DEFINED',
      defaultMessage: uiTexts?.WORKFLOW_ELEMENT_QUALIECAMERA_ERROR_NO_PERMISSIONS?.value,
    }, { br: <br /> })  : intl.formatMessage({ id: 'workflowElement.qualieCamera.error.noPermissions' }, { br: <br /> });
  }, [intl, uiTexts]);

  if (hide) {
    return null;
  }

  return (
    <div className={className}>
      <div className="qualie-camera-workflow-element-container">
        <div className="qualie-camera-workflow-element-live-preview">
          <video
            ref={a => livePreviewRef.current = a}
            autoPlay
            muted
            playsInline
          />
        </div>
        <div className="qualie-camera-workflow-element-recording-preview">
          <video
            ref={a => previewRef.current = a}
            src={recordingDataUrl}
            playsInline
            onEnded={onEndPreview}
          />
          <Progress
            percent={previewProgress}
            showInfo={false}
            status="normal"
            size="small"
          />
        </div>
        <div className="qualie-camera-workflow-element-overlay">
          {status === QualieCameraStatus.Record && (
            <React.Fragment>
              {videoRecorderStatus === VideoRecorderStatus.NoPermissionGranted && (
                <div className="qualie-camera-workflow-element-overlay-error">
                  <div className="qualie-camera-workflow-element-overlay-error-content">
                    <WarningFilled />
                    <p>
                      {noPermissionsText}
                    </p>
                  </div>
                </div>
              )}
              {videoRecorderStatus === VideoRecorderStatus.Ready && (
                <React.Fragment>
                  <Guidelines />
                  {countdown == null && (
                    <React.Fragment>
                      <div className="qualie-camera-workflow-element-overlay-ready-toolbar">
                        <DevicesButton onClick={onDeviceConfiguration} />
                      </div>
                      <div className="qualie-camera-workflow-element-overlay-ready">
                        <RecordButton onClick={onStartCountdown} />
                      </div>
                    </React.Fragment>
                  )}
                  {countdown != null && (<div className="qualie-camera-workflow-element-overlay-countdown">{countdown}</div>)}
                </React.Fragment>
              )}
              {videoRecorderStatus === VideoRecorderStatus.Recording && (
                <React.Fragment>
                  <div className="qualie-camera-workflow-element-overlay-recording">
                    <StopButton onClick={onTryStopRecording} />
                    <RemainingTime value={videoConfig.maxMs - duration} />
                    {revealProgress && (
                      <RecordProgress
                        min={minRecordTime}
                        max={videoConfig.maxMs}
                        value={duration}
                      />
                    )}
                  </div>
                  <div className="qualie-camera-workflow-element-overlay-recording-blink" />
                </React.Fragment>
              )}
              {videoRecorderStatus === VideoRecorderStatus.RecordingComplete && (
                <div className="qualie-camera-workflow-element-overlay-recording-complete animate__animated animate__zoomInUp">
                  <UploadButton onClick={onStartUpload} />
                  <ResetButton onClick={onReset} />
                  <PreviewButton onClick={onStartPreview} />
                </div>
              )}
            </React.Fragment>
          )}
          {status === QualieCameraStatus.Uploading && (
            <React.Fragment>
              {uploadProgress !== null && (
                <div className="qualie-camera-workflow-element-overlay-upload-progress animate__animated animate__fadeIn">
                  <Progress
                    className="animate__animated animate__zoomInUp"
                    type="circle"
                    percent={uploadProgress}
                  />
                </div>
              )}
            </React.Fragment>
          )}
          {status === QualieCameraStatus.DeviceConfiguration && (
            <React.Fragment>
              <div className="qualie-camera-workflow-element-device-configuration">
                <Form layout="vertical">
                  <h3>
                    {configurationHeading}
                  </h3>
                  <Divider />
                  <Form.Item label={<QuestionLabel><span><VideoCameraOutlined /> {videoLabel} </span></QuestionLabel>}>
                    <Select
                      value={devices?.currentVideoDeviceId}
                      onChange={onChangeVideo}
                    >
                      {devices?.video.map(device => (
                        <Select.Option
                          key={device.deviceId}
                          value={device.deviceId}
                        >{device.label}</Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label={<QuestionLabel><span><AudioOutlined /> {audioLabel} </span></QuestionLabel>}
                    extra={(
                      <SoundWaveform
                        mediaStream={mediaStream}
                        direction="horizontal"
                      />
                    )}
                  >
                    <Select
                      value={devices?.currentAudioDeviceId}
                      onChange={onChangeAudio}
                    >
                      {devices?.audio.map(device => (
                        <Select.Option
                          key={device.deviceId}
                          value={device.deviceId}
                        >{device.label}</Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Button
                    htmlType="submit"
                    type="primary"
                    onClick={onDeviceConfigurationClose}
                  >
                    {submitLabel}
                  </Button>
                </Form>
              </div>
            </React.Fragment>
          )}
          {status === QualieCameraStatus.Preview && (
            <div className="qualie-camera-workflow-element-overlay-preview-toolbar">
              <CancelButton onClick={onEndPreview} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default QualieCameraWorkflowElement;
