import { Dictionary } from 'lib/types';
import React from 'react';
import IWorkflowElementProps from '../IWorkflowElementProps';
import CameraTagWorkflowElement from './cameraTag';
import CarouselWorkflowElement from './carousel';
import ExternalVideoWorkflowElement from './externalVideo';
import FormWorkflowElement from './form';
import HeadingWorkflowElement from './heading';
import ImageWorkflowElement from './image';
import InitiativeWorkflowElement from './initiative';
import KeyValueWorkflowElement from './keyValue';
import LabelWorkflowElement from './label';
import QualieCameraWorkflowElement from './qualieCamera';

enum WORKFLOW_ELEMENT_TYPES {
  Label = 'LABEL',
  ExternalVideo = 'EXTERNAL_VIDEO',
  Form = 'FORM',
  FormElement = 'FORM_ELEMENT',
  Heading = 'HEADING',
  Carousel = 'CAROUSEL',
  Initiative = 'INITIATIVE',
  Image = 'IMAGE',
  ImageLink = 'IMAGE_LINK',
  KeyValue = 'KEY_VALUE',
  CameraTag = 'CAMERA_TAG',
  QualieCamera = 'QUALIE_CAM',
};

const WORKFLOW_ELEMENT_TYPE_COMPONENTS: Dictionary<React.FunctionComponent<IWorkflowElementProps<any>>> = {
  [WORKFLOW_ELEMENT_TYPES.Label]: LabelWorkflowElement,
  [WORKFLOW_ELEMENT_TYPES.ExternalVideo]: ExternalVideoWorkflowElement,
  [WORKFLOW_ELEMENT_TYPES.Form]: FormWorkflowElement,
  [WORKFLOW_ELEMENT_TYPES.Heading]: HeadingWorkflowElement,
  [WORKFLOW_ELEMENT_TYPES.Carousel]: CarouselWorkflowElement,
  [WORKFLOW_ELEMENT_TYPES.Initiative]: InitiativeWorkflowElement,
  [WORKFLOW_ELEMENT_TYPES.Image]: ImageWorkflowElement,
  [WORKFLOW_ELEMENT_TYPES.ImageLink]: ImageWorkflowElement,
  [WORKFLOW_ELEMENT_TYPES.KeyValue]: KeyValueWorkflowElement,
  [WORKFLOW_ELEMENT_TYPES.CameraTag]: CameraTagWorkflowElement,
  [WORKFLOW_ELEMENT_TYPES.QualieCamera]: QualieCameraWorkflowElement,
};

export {
  WORKFLOW_ELEMENT_TYPES,
  WORKFLOW_ELEMENT_TYPE_COMPONENTS,
};
