import _ from 'lodash/fp';
import { IWorkflowFormElementElement } from 'lib/modules/qualieApi/entities/workflow';
import React, { useContext, useMemo } from 'react';
import { Form, Radio } from 'antd';
import { IWorkflowFormElementProps } from './types';
import { AudioMutedOutlined, CloseSquareOutlined, DislikeOutlined, HeartOutlined, LikeOutlined, MehOutlined } from '@ant-design/icons';
import { CommencedWorkflowContext } from 'app/modules/pux/contexts';
import ErrorMessage from '../../../errorMessage';

const Icon: React.FunctionComponent<{ type: string }> = (props) => {
  const { type } = props;

  switch (type) {
    case 'POOR_QUALITY':
      return <AudioMutedOutlined />;
    case 'NOT_RELEVANT':
      return <CloseSquareOutlined />;
    case 'OKAY':
      return <MehOutlined />;
    case 'AWESOME':
      return <HeartOutlined />;
    case 'DISAGREE':
      return <DislikeOutlined />;
    case 'REALLY_GOOD':
      return <LikeOutlined />;
    default:
      return null;
  }
};

const VideoRatingRadioFormElement: React.FunctionComponent<IWorkflowFormElementProps<IWorkflowFormElementElement>> = (props) => {
  const { workflowElement, path } = props;
  const options = useMemo(() => _.sortBy('position', workflowElement.options), [workflowElement]);
  const name = useMemo(() => path ? [...path, workflowElement.name] : [workflowElement.name], [path, workflowElement]);
  const { uiTexts } = useContext(CommencedWorkflowContext);

  return (
    <Form.Item
      name={name}
      rules={[
        {
          required: workflowElement.required,
          message: <ErrorMessage message={uiTexts?.SELECT_CHOICE?.value} />,
        },
      ]}
    >
      <Radio.Group className="video-rating-radio-group">
        {options.map(option => (
          <Radio.Button
            key={option.value}
            value={option.key}
          >
            <div className="video-rating-radio-icon">
              <Icon type={option.key} />
            </div>
            <div className="video-rating-radio-title">{option.label}</div>
            <div className="video-rating-radio-subtitle">{option.value}</div>
          </Radio.Button>
        ))}
      </Radio.Group>
    </Form.Item>
  );
};

export default VideoRatingRadioFormElement;