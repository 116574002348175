import type { RootState } from 'app/store';

const getCommencedWorkflow = (state: RootState) => state.workflowsReducer?.commencedWorkflow;
const getWorkflowStep = (state: RootState, position?: number) => (position && state?.workflowsReducer?.workflowSteps) ? state.workflowsReducer.workflowSteps[position] : null;
const getFurthestWorkflowStep = (state: RootState) => getWorkflowStep(state, state.workflowsReducer.currentWorkflowStepPosition);
const getParticipant = (state: RootState) => state?.workflowsReducer.participant;
const getFlags = (state: RootState) => state?.workflowsReducer.flags;
const getTheme = (state: RootState) => state?.workflowsReducer.theme;

const workflowsSelectors = {
  getCommencedWorkflow,
  getWorkflowStep,
  getFurthestWorkflowStep,
  getParticipant,
  getFlags,
  getTheme,
};

export default workflowsSelectors;
