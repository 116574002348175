import _ from 'lodash/fp';
import { IWorkflowFormElementElement } from 'lib/modules/qualieApi/entities/workflow';
import React, { useContext, useMemo } from 'react';
import { Form, Select } from 'antd';
import { IWorkflowFormElementProps } from './types';
import { CommencedWorkflowContext } from 'app/modules/pux/contexts';
import { QuestionLabel } from 'app/modules/pux/components/content';
import ErrorMessage from '../../../errorMessage';

const SelectFormElement: React.FunctionComponent<IWorkflowFormElementProps<IWorkflowFormElementElement>> = (props) => {
  const { workflowElement, path } = props;
  const options = useMemo(() => _.sortBy('position', workflowElement.options), [workflowElement]);
  const name = useMemo(() => path ? [...path, workflowElement.name] : [workflowElement.name], [path, workflowElement]);
  const { uiTexts } = useContext(CommencedWorkflowContext);

  return (
    <React.Fragment>
      <Form.Item
        label={<QuestionLabel>{workflowElement.label}</QuestionLabel>}
        name={[...name, 'selected']}
        rules={[
          {
            required: workflowElement.required,
            message: <ErrorMessage message={uiTexts?.IS_REQUIRED?.value} />,
          },
        ]}
      >
        <Select>
          {options.map(option => (
            <Select.Option
              key={option.key}
              value={option.key}
            >
              {option.label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </React.Fragment>
  );
};

export default SelectFormElement;