import puxSlice from './slice';
import puxSelectors from './selectors';

const puxActions = puxSlice.actions;
const puxReducer = puxSlice.reducer;

export {
  puxActions,
  puxReducer,
  puxSelectors,
};
