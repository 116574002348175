import _ from 'lodash/fp';
import { IWorkflowFormElementElement } from 'lib/modules/qualieApi/entities/workflow';
import React, { useMemo } from 'react';
import { IWorkflowFormElementProps } from './types';
import ThemometerFormElement from './thermometer';

const ThemometerMultiFormElement: React.FunctionComponent<IWorkflowFormElementProps<IWorkflowFormElementElement>> = (props) => {
  const { dependencies, workflowElement, path } = props;
  const initiative: any = useMemo(() => {
    const initiatives = _.orderBy(['group'], ['asc'], dependencies?.initiatives?.collection || []);

    return _.find((a: any) => a.stimuli.length > 0, initiatives) || _.first(initiatives);
  }, [dependencies]);
  const name = useMemo(() => path ? [...path, workflowElement.name] : [workflowElement.name, initiative.hash], [path, workflowElement, initiative]);
  const rankElement: IWorkflowFormElementElement = useMemo(() => {
    return _.find( {name: 'rank' }, initiative && _.find({ name: initiative.hash }, workflowElement.collection || [])?.collection);
  }, [workflowElement, initiative]);

  return (
    <ThemometerFormElement
      workflowElement={rankElement}
      path={name}
    />
  );
};

export default ThemometerMultiFormElement;
