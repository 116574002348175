import React, { useCallback, useEffect, useRef, useState } from 'react';
import VimeoPlayer, { EndEvent } from '@u-wave/react-vimeo';
import { IExternalVideoWorkflowElementProps } from './types';

const VimeoExternalVideoWorkflowElement: React.FunctionComponent<IExternalVideoWorkflowElementProps> = ({
  active,
  workflowElement,
  onValid,
}) => {
  const playerRef = useRef<any>();
  const [watched, setWatched] = useState(false);

  const onEnd = useCallback((e: EndEvent) => {
    setWatched(true);
  }, []);

  const onReady = useCallback((player: any) => {
    playerRef.current = player;
  }, []);

  useEffect(() => {
    if (onValid) {
      onValid(workflowElement, watched);
    }
  }, [onValid, watched, workflowElement]);

  useEffect(() => {
    if (active === false && playerRef.current != null) {
      playerRef.current.pause();
    }
  }, [active]);

  return (
    <div className="vimeo-external-video-workflow-element">
      <VimeoPlayer
        onReady={onReady}
        video={workflowElement.videoId}
        onEnd={onEnd}
        volume={1}
      />
    </div>
  );
};

export default VimeoExternalVideoWorkflowElement;
