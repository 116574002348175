import { Button } from 'antd';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { setReverseMode } from 'app/modules/reverseMode/store/slice';

interface IProps {
  onAdvance: any;
  onRetreat: any;
  allowAdvance: boolean;
  allowRetreat: boolean;
}

const WorkflowActivityAdvanceRetreat: React.FunctionComponent<IProps> = ({
  onAdvance,
  onRetreat,
  allowAdvance,
  allowRetreat,
}) => {
  const dispatch = useDispatch();

  const onClickRetreat = useCallback(() => {
    dispatch(setReverseMode(true));
    onRetreat();
  }, [dispatch, onRetreat]);

  const onClickAdvance = useCallback(() => {
    dispatch(setReverseMode(false));
    onAdvance();
  }, [dispatch, onAdvance]);

  return (
    <div className="workflow-activity-test-advance-retreat">
      <Button
        className="workflow-activity-test-retreat"
        onClick={onClickRetreat}
        disabled={!allowRetreat}
      >
        Back
      </Button>
      <Button
        className="workflow-activity-test-advance"
        onClick={onClickAdvance}
        disabled={!allowAdvance}
      >
        Forward
      </Button>
    </div>);
};

export default WorkflowActivityAdvanceRetreat;
