import { useCountdown } from 'app/hooks';
import { useKeyedWorkflowElements } from 'app/modules/pux/hooks';
import { IWorkflowElement } from 'lib/modules/qualieApi/entities/workflow';
import React, { useContext, useEffect } from 'react';
import { useIntl } from 'react-intl';
import BasicWorkflowForm from '../../layout/basicWorkflowForm';
import IWorkflowActivityProps from '../IWorkflowActivityProps';
import { CommencedWorkflowContext } from 'app/modules/pux/contexts';

type Elements = {
  paragraph1: IWorkflowElement;
  paragraph2: IWorkflowElement;
  redirectingIn: IWorkflowElement;
  redirectURL: IWorkflowElement;
  subTitle: IWorkflowElement;
  title: IWorkflowElement;
};

const COUNTDOWN = 5;

const TerminateWorkflowActivity: React.FunctionComponent<IWorkflowActivityProps> = (props) => {
  const { workflowActivity } = props;
  const intl = useIntl();
  const { uiTexts } = useContext(CommencedWorkflowContext);
  const elements = useKeyedWorkflowElements<Elements>(workflowActivity.workflowElements);
  const [countdown, setCountdown] = useCountdown();
  const redirectMessage = (uiTexts?.WORKFLOW_ACTIVITY_TERMINATE_REDIRECT_TO) ? intl.formatMessage({
    id:  'NOT_DEFINED',
    defaultMessage: uiTexts?.WORKFLOW_ACTIVITY_TERMINATE_REDIRECT_TO?.value,
  }, { count: countdown || COUNTDOWN }) : intl.formatMessage({ id: 'workflowActivity.terminate.redirectTo' }, { count: countdown || COUNTDOWN });

  useEffect(() => {
    setCountdown(COUNTDOWN);
  }, [setCountdown]);

  useEffect(() => {
    if (countdown === 0 && elements.redirectURL?.value) {
      window.location.replace(elements.redirectURL?.value);
    }
  }, [countdown, elements]);

  return (
    <BasicWorkflowForm size="default">
      <div className="terminate-content">
        <h1>{elements.title.value}</h1>
        <p>{elements.paragraph1.value}</p>
        <p>{elements.paragraph2.value}</p>
        <p>{redirectMessage} <a href={elements.redirectURL?.value}>{`${elements.redirectURL?.value?.slice(0, 30)}...`}</a></p>
      </div>
    </BasicWorkflowForm>
  );
};

export default TerminateWorkflowActivity;
