import { Alert } from 'antd';
import { IWorkflowExternalVideoElement } from 'lib/modules/qualieApi/entities/workflow';
import React, { useContext, useMemo } from 'react';
import { useIntl } from 'react-intl';
import IWorkflowElementProps from '../../IWorkflowElementProps';
import { WORKFLOW_ELEMENT_EXTERNAL_VIDEO_TYPE_COMPONENTS } from './types';
import { IExternalVideoWorkflowElementProps } from './types/types';
import { CommencedWorkflowContext } from 'app/modules/pux/contexts';

const InvalidTypeFallbackExternalVideoWorkflowElement: React.FunctionComponent<IWorkflowElementProps<IWorkflowExternalVideoElement>> = (props) => {
  const { workflowElement } = props;
  const intl = useIntl();
  const { uiTexts } = useContext(CommencedWorkflowContext);

  return (
    <Alert
      showIcon
      type="error"
      message={(uiTexts?.WORKFLOW_ELEMENT_EXTERNAL_VIDEO_INVALID_PROVIDER_FALLBACK_TITLE) ? intl.formatMessage({
        id:  'NOT_DEFINED',
        defaultMessage: uiTexts?.WORKFLOW_ELEMENT_EXTERNAL_VIDEO_INVALID_PROVIDER_FALLBACK_TITLE?.value,
      }, { type: workflowElement.provider }) : intl.formatMessage({ id: 'workflowElement.externalVideo.invalidProviderFallback.title' }, { type: workflowElement.provider })}
    />
  );
};

const ExternalVideoWorkflowElement: React.FunctionComponent<IExternalVideoWorkflowElementProps> = (props) => {
  const { workflowElement } = props;
  const WorkflowElementType = useMemo(() => WORKFLOW_ELEMENT_EXTERNAL_VIDEO_TYPE_COMPONENTS[workflowElement.provider] || InvalidTypeFallbackExternalVideoWorkflowElement, [workflowElement]);

  return <WorkflowElementType {...props} />;
};

export default ExternalVideoWorkflowElement;
