import { createAsyncThunk } from '@reduxjs/toolkit';
import QualieAPI from 'lib/modules/qualieApi';
import Cookie from 'js-cookie';
import { IFlags, IPersistedParticipant } from './types';
import workflowsSlice from './slice';
import { ApiResponse, RESPONSE_CODES } from 'lib/modules/qualieApi/types';
import { replace } from 'connected-react-router';
import { HttpStatusCode } from 'lib/constants';
import { Dictionary } from 'lib/types';
import rollbar from 'lib/rollbar';
import _ from 'lodash';

const assertApiResponse = createAsyncThunk(
  'workflows/assertApiResponse',
  async (params: { response: ApiResponse<any>, params: any }, thunkApi) => {
    if (!params.response.ok) {
      rollbar.error(`${params.response.statusText || '?'} - ${params.response?.body?.message || '?'}`, params);
    }

    switch (params.response.status) {
      case HttpStatusCode.ServiceUnavailable:
        thunkApi.dispatch(replace('/maintenance'));

        throw params.response;
    }
  }
);

const commenceWorkflow = createAsyncThunk(
  'workflows/commenceWorkflow',
  async (params: { workflowId: string, options: { flowName?: string, pp?: string, cookie?: boolean, enableUpload?: boolean, referrer?: string, uid?: string, passedParams?: Dictionary<string>, activityHash?: string, ph?: string } }, thunkApi) => {
    let persistedFlags: Partial<IFlags> = {};
    try {
      if (params.options.cookie !== false) {
        const persistedParticpant = await thunkApi.dispatch(getPersistedParticipant({ workflowId: params.workflowId })).unwrap();
        persistedFlags = persistedParticpant.flags;

        if (params.options.enableUpload === true && persistedFlags.enableUpload === false) {
          // Update enableUpload only
          persistedParticpant.flags.enableUpload = true;
          await thunkApi.dispatch(setPersistedParticipant({
            workflowId: params.workflowId,
            participant: persistedParticpant,
          }));
        }
      }
    } catch (e) {
      console.log('Commencing workflow without a participant from storage.');
    }

    const safePassedParams = params.options.passedParams
      ? _.reduce(params.options.passedParams, (acc: Dictionary<string>, curr, key) => {
        if (curr?.toString) {
          acc[key] = curr.toString();
        }

        return acc;
      }, {})
      : undefined;

    const options = {
      flowName: params.options.flowName || persistedFlags?.flowName || 'ON_CAM',
      pp: params.options.pp || persistedFlags?.pp,
      cookie: params.options.cookie != null ? params.options.cookie : true,
      enableUpload: params.options.enableUpload != null ? params.options.enableUpload : false,
      referrer: params.options.referrer || persistedFlags?.referrer,
      uid: params.options.uid || persistedFlags?.uid,
      passedParams: safePassedParams || persistedFlags?.passedParams,
      activityHash: params.options.activityHash,
      ph: params.options.ph,
    };

    const response = await QualieAPI.Workflow.CommenceWorkflow(params.workflowId, options);
    thunkApi.dispatch(workflowsSlice.actions.setFlags({
      ...options,
      recordSessions: response.body.data && response.body.data[0] ? response.body.data[0].recordSessions : false,
    }));
    await thunkApi.dispatch(assertApiResponse({
      response: response,
      params: params,
    })).unwrap();

    return response.body;
  }
);

const submitWorkflowFormElement = createAsyncThunk(
  'workflows/submitWorkflowFormElement',
  async (params: { form: Dictionary, actionUrl: string }, thunkApi) => {
    const response = await QualieAPI.Workflow.SubmitFormElement(params.actionUrl, params.form);
    await thunkApi.dispatch(assertApiResponse({
      response: response,
      params: params,
    })).unwrap();

    if (response.body.code !== QualieAPI.codes.response.Success) {
      return thunkApi.rejectWithValue(response.body);
    }

    return response.body;
  }
);

const getPersistedParticipant = createAsyncThunk(
  'workflows/getPersistedParticipant',
  async (params: { workflowId: string }, thunkApi) => {
    const data = Cookie.get(`qualie/workflows/${params.workflowId}/participant`);

    if (!data) {
      throw new Error('No participant found.');
    }

    const payload: IPersistedParticipant = JSON.parse(data);
    thunkApi.dispatch(workflowsSlice.actions.setParticipant({
      participant: payload.participant,
      flags: {
        ...payload.flags,
        cookie: true,
        enableUpload: !!payload.flags.enableUpload,
      },
    }));

    try {
      rollbar.configure({
        payload: {
          person: {
            id: payload.participant.hash,
            email: payload.participant.email,
          },
        },
      });
    } catch (e) {
      console.error(e);
    }

    return payload;
  }
);

const setPersistedParticipant = createAsyncThunk(
  'workflows/setPersistedParticipant',
  async (params: { workflowId: string, participant: IPersistedParticipant }) => {
    Cookie.set(`qualie/workflows/${params.workflowId}/participant`, JSON.stringify(params.participant));

    try {
      rollbar.configure({
        payload: {
          person: {
            id: params.participant.participant.hash,
            email: params.participant.participant.email,
          },
        },
      });
    } catch (e) {
      console.error(e);
    }
  }
);

const createParticipant = createAsyncThunk(
  'workflows/createParticipant',
  async (params: { form: Dictionary, actionUrl: string }, thunkApi) => {
    const response = await QualieAPI.Workflow.CreateParticipant(params.actionUrl, params.form);
    await thunkApi.dispatch(assertApiResponse({
      response: response,
      params: params,
    })).unwrap();

    if (![QualieAPI.codes.response.Success, QualieAPI.codes.response.ParticipantExists, QualieAPI.codes.response.QuotaFull].includes(response.body.code as RESPONSE_CODES)) {
      return thunkApi.rejectWithValue(response.body);
    }

    return response.body;
  }
);

const getNextWorkflowStep = createAsyncThunk(
  '/workflows/getNextWorkflowStep',
  async (params: { workflowId: string, participantId: string }, thunkApi) => {
    const response = await QualieAPI.Workflow.GetNextWorkflowActivity(params.workflowId, params.participantId);

    if (response.body.code !== QualieAPI.codes.response.Success) {
      return thunkApi.rejectWithValue(response.body);
    }

    return response.body;
  }
);

const getNextReviewVideo = createAsyncThunk(
  '/workflows/getNextReviewVideo',
  async (params: { nextVideoUrl: string }, thunkApi) => {
    const response = await QualieAPI.Workflow.GetNextReviewVideo(params.nextVideoUrl);
    await thunkApi.dispatch(assertApiResponse({
      response: response,
      params: params,
    })).unwrap();

    if (response.body.code !== QualieAPI.codes.response.Success && response.body.code !== QualieAPI.codes.response.NoContent) {
      return thunkApi.rejectWithValue(response.body);
    }

    return response.body;
  }
);

const getNextReviewText = createAsyncThunk(
  '/workflows/getNextReviewText',
  async (params: { nextVideoUrl: string }, thunkApi) => {
    const response = await QualieAPI.Workflow.GetNextReviewText(params.nextVideoUrl);
    await thunkApi.dispatch(assertApiResponse({
      response: response,
      params: params,
    })).unwrap();

    if (response.body.code !== QualieAPI.codes.response.Success && response.body.code !== QualieAPI.codes.response.NoContent) {
      return thunkApi.rejectWithValue(response.body);
    }

    return response.body;
  }
);

const getNextReviewImage = createAsyncThunk(
  '/workflows/getNextReviewImage',
  async (params: { nextImageUrl: string }, thunkApi) => {
    const response = await QualieAPI.Workflow.GetNextReviewImage(params.nextImageUrl);
    await thunkApi.dispatch(assertApiResponse({
      response: response,
      params: params,
    })).unwrap();

    if (response.body.code !== QualieAPI.codes.response.Success && response.body.code !== QualieAPI.codes.response.NoContent) {
      return thunkApi.rejectWithValue(response.body);
    }

    return response.body;
  }
);

const advanceParticipant = createAsyncThunk(
  '/workflows/advanceParticipant',
  async (params: { workflowId: string, participantId: string }, thunkApi) => {
    const response = await QualieAPI.Workflow.AdvanceParticipant(params.workflowId, params.participantId);
    await thunkApi.dispatch(assertApiResponse({
      response: response,
      params: params,
    })).unwrap();

    if (response.body.code !== QualieAPI.codes.response.Success) {
      return thunkApi.rejectWithValue(response.body);
    }

    return response.body;
  }
);

const advanceParticipantInTest = createAsyncThunk(
  '/workflows/advanceParticipantInTest',
  async (params: { workflowId: string, participantId: string }, thunkApi) => {
    const response = await QualieAPI.Workflow.AdvanceParticipantInTest(params.workflowId, params.participantId);
    await thunkApi.dispatch(assertApiResponse({
      response: response,
      params: params,
    })).unwrap();

    if (response.body.code !== QualieAPI.codes.response.Success) {
      return thunkApi.rejectWithValue(response.body);
    }

    return response.body;
  }
);

const retreatParticipantInTest = createAsyncThunk(
  '/workflows/retreatParticipantInTest',
  async (params: { workflowId: string, participantId: string }, thunkApi) => {
    const response = await QualieAPI.Workflow.RetreatParticipantInTest(params.workflowId, params.participantId);
    await thunkApi.dispatch(assertApiResponse({
      response: response,
      params: params,
    })).unwrap();

    if (response.body.code !== QualieAPI.codes.response.Success) {
      return thunkApi.rejectWithValue(response.body);
    }

    return response.body;
  }
);

export {
  commenceWorkflow,
  submitWorkflowFormElement,
  createParticipant,
  getNextWorkflowStep,
  getNextReviewVideo,
  getNextReviewText,
  advanceParticipant,
  advanceParticipantInTest,
  retreatParticipantInTest,
  getPersistedParticipant,
  setPersistedParticipant,
  getNextReviewImage,
};