import React, { useMemo } from 'react';
import cx from 'classnames';
import { useIsMobile } from 'app/hooks';

interface IProps {
  header?: JSX.Element | undefined | boolean;
}

const SplitWorkflowFormEmpty: React.FunctionComponent<IProps> = (props) => {
  const { children, header } = props;
  const isMobile = useIsMobile();
  const wrapperClassName = useMemo(() => cx({
    'split-workflow-form-container-empty': true,
    'banner-layout': !!header && !isMobile,
  }), [isMobile, header]);

  return (
    <div className={wrapperClassName}>
      {header}
      <div className="split-workflow-form-container-content-container">
        <div className="split-workflow-form-container-content">
          {children}
        </div>
      </div>
    </div>
  );
};

export default SplitWorkflowFormEmpty;