import { Alert, Button, Carousel, Collapse, Modal } from 'antd';
import { useIsMobile } from 'app/hooks';
import { IWorkflowCarouselSlideElement, IWorkflowElement, IWorkflowExternalVideoElement, IWorkflowImageElement } from 'lib/modules/qualieApi/entities/workflow';
import React, { forwardRef, useCallback, useContext, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import cx from 'classnames';
import { imageLinkTypes, imageStimulusTypes, Stimulus, StimulusType } from 'lib/types';
import ExternalVideoWorkflowElement from './externalVideo';
import ImageWorkflowElement from './image';
import { CarouselRef } from 'antd/lib/carousel';
import { ARROW_TYPES, CAROUSEL_SLIDE_TYPES } from './carousel';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import { WORKFLOW_ELEMENT_TYPES } from '.';
import { FullscreenOutlined } from '@ant-design/icons';
import { CommencedWorkflowContext } from 'app/modules/pux/contexts';
import ImageLinkWorkflowElement from './imageLink';

interface ICarouselArrowProps {
  className?: string;
  onClick?: () => void;
  type: keyof typeof ARROW_TYPES;
}

const CarouselArrow: React.FunctionComponent<ICarouselArrowProps> = ({ className, onClick, type }) => {
  const Arrow = ARROW_TYPES[type];

  return (
    <div
      className={className}
      onClick={onClick}
    >
      <Arrow className="carousel-arrow" />
    </div>
  );
};

interface IExplainerCarouselSlideProps {
  slide: IWorkflowCarouselSlideElement;
  active: boolean;
}

const ExplainerCarouselSlide: React.FunctionComponent<IExplainerCarouselSlideProps> = ({
  slide,
  active,
}) => {
  const intl = useIntl();
  const { uiTexts } = useContext(CommencedWorkflowContext);

  switch (slide.type) {
    case CAROUSEL_SLIDE_TYPES.Video:
      return (
        <div className="workflow-element-carousel-slide">
          <ExternalVideoWorkflowElement
            workflowElement={slide as any}
            active={active}
          />
        </div>
      );
    case CAROUSEL_SLIDE_TYPES.Image:
      return (
        <div className="workflow-element-carousel-slide">
          <ImageWorkflowElement
            workflowElement={slide as any}
            active={active}
          />
        </div>
      );
    case CAROUSEL_SLIDE_TYPES.ImageLink:
      return (
        <div className="workflow-element-carousel-slide">
          <ImageLinkWorkflowElement
            workflowElement={{
              position: slide.position,
              path: slide.image.path,
              link: slide.link,
              sourceUri: slide.path!,
              name: '',
              visible: true,
              workflowElementType: CAROUSEL_SLIDE_TYPES.ImageLink,
            } as any}
            active={active}
          />
        </div>
      );
    default:
      return (
        <div className="workflow-element-carousel-slide">
          <Alert
            type="error"
            message={(uiTexts?.WORKFLOW_ELEMENT_INVALID_TYPE_FALLBACK_TITLE) ? intl.formatMessage({
              id:  'NOT_DEFINED',
              defaultMessage: uiTexts?.WORKFLOW_ELEMENT_INVALID_TYPE_FALLBACK_TITLE?.value,
            }, { type: slide.type }) : intl.formatMessage({ id: 'workflowElement.invalidTypeFallback.title' }, { type: slide.type })}
          />
        </div>
      );
  }
};

interface IStimuliCarouselSlideProps {
  slide: Stimulus;
  active: boolean;
}

const StimuliCarouselSlide: React.FunctionComponent<IStimuliCarouselSlideProps> = ({
  slide,
  active,
}) => {
  const intl = useIntl();
  const { uiTexts } = useContext(CommencedWorkflowContext);

  const workflowElement = useMemo(() => {
    switch (slide.stimulusType) {
      case StimulusType.Video:
        return (
          <ExternalVideoWorkflowElement
            active={active}
            workflowElement={{
              name: slide.qualieFile.name,
              visible: true,
              workflowElementType: WORKFLOW_ELEMENT_TYPES.ExternalVideo,
              provider: slide.qualieFile.videoProvider,
              width: slide.qualieFile.width,
              height: slide.qualieFile.height,
              thumbnail: slide.qualieFile.thumbnail,
              videoId: slide.qualieFile.key,
              position: (String)(slide.position),
            } as IWorkflowExternalVideoElement}
          />
        );
      case StimulusType.Image:
        return (
          <ImageWorkflowElement
            active={active}
            workflowElement={{
              name: slide.qualieFile.name,
              visible: true,
              workflowElementType: WORKFLOW_ELEMENT_TYPES.Image,
              sourceUri: slide.qualieFile.sourceUri,
              path: slide.qualieFile.path,
              position: (String)(slide.position),
              link: slide.qualieFile?.link,
            } as IWorkflowImageElement}
          />
        );
      case StimulusType.ImageLink:
        return (
          <ImageLinkWorkflowElement
            active={active}
            workflowElement={{
              name: slide.qualieFile.name,
              visible: true,
              workflowElementType: WORKFLOW_ELEMENT_TYPES.Image,
              sourceUri: slide.qualieFile.sourceUri,
              path: slide.qualieFile.path,
              position: (String)(slide.position),
              link: slide.qualieFile?.link,
            } as IWorkflowImageElement}
          />
        );
      default:
        return (
          <Alert
            type="error"
            message={(uiTexts?.WORKFLOW_ELEMENT_INVALID_TYPE_FALLBACK_TITLE) ? intl.formatMessage({
              id:  'NOT_DEFINED',
              defaultMessage: uiTexts?.WORKFLOW_ELEMENT_INVALID_TYPE_FALLBACK_TITLE?.value,
            }, { type: slide.stimulusType }) : intl.formatMessage({ id: 'workflowElement.invalidTypeFallback.title' }, { type: slide.stimulusType })}
          />
        );
    }
  }, [slide, intl, active, uiTexts]);

  return (
    <div className="workflow-element-carousel-slide">
      {workflowElement}
    </div>
  );
};

interface IExplainerContentModalProps {
  isOpen: boolean;
  onCancel: () => void;
  elements: IWorkflowCarouselSlideElement[];
}

const ExplainerContentModal = forwardRef<CarouselRef, IExplainerContentModalProps>(({ isOpen, onCancel, elements }, ref) => {
  const isMobile = useIsMobile();
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const wrapperClassName = useMemo(() => cx({
    'content-banner-overlay-wrapper': true,
    'mobile-layout': isMobile,
  }), [isMobile]);

  const afterChange = useCallback((currentSlide: number) => {
    setActiveIndex(currentSlide);
  }, []);

  return (
    <Modal
      destroyOnClose={true}
      className={wrapperClassName}
      centered
      visible={isOpen}
      footer={null}
      onCancel={onCancel}
      width="100%"
    >
      <div className="workflow-element-carousel">
        <Carousel
          ref={ref}
          afterChange={afterChange}
          className={elements.length > 1 ? 'with-arrows' : undefined}
          dotPosition="top"
          dots={elements.length > 1}
          arrows={elements.length > 1}
          autoplay={false}
          prevArrow={<CarouselArrow type="left" />}
          nextArrow={<CarouselArrow type="right" />}
          slidesToShow={1}
          infinite={false}
        >
          {elements.map((element, index) => (
            <ExplainerCarouselSlide
              key={element.position}
              slide={element}
              active={index === activeIndex}
            />
          ))}
        </Carousel>
      </div>
    </Modal>
  );
});

interface IStimuliContentModalProps {
  isOpen: boolean;
  onCancel: () => void;
  elements: Stimulus[];
}

const StimuliContentModal = forwardRef<CarouselRef, IStimuliContentModalProps>(({ isOpen, onCancel, elements }, ref) => {
  const isMobile = useIsMobile();
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const wrapperClassName = useMemo(() => cx({
    'content-banner-overlay-wrapper': true,
    'mobile-layout': isMobile,
  }), [isMobile]);

  const afterChange = useCallback((currentSlide: number) => {
    setActiveIndex(currentSlide);
  }, []);

  return (
    <Modal
      destroyOnClose={true}
      className={wrapperClassName}
      centered
      visible={isOpen}
      footer={null}
      onCancel={onCancel}
      width="100%"
    >
      <div className="workflow-element-carousel">
        <Carousel
          ref={ref}
          afterChange={afterChange}
          className={elements.length > 1 ? 'with-arrows' : undefined}
          dotPosition="top"
          dots={elements.length > 1}
          arrows={elements.length > 1}
          autoplay={false}
          prevArrow={<CarouselArrow type="left" />}
          nextArrow={<CarouselArrow type="right" />}
          slidesToShow={1}
          infinite={false}
        >
          {elements.map((element, index) => (
            <StimuliCarouselSlide
              key={element.position}
              slide={element}
              active={index === activeIndex}
            />
          ))}
        </Carousel>
      </div>
    </Modal>
  );
});

interface IBannerCardProps {
  onClick: (position: number) => void;
  position: number;
  thumbnail: string;
}

const BannerCard: React.FunctionComponent<IBannerCardProps> = ({
  thumbnail,
  position,
  onClick,
}) => {
  const onClickElement = () => onClick(position - 1);

  return (
    <div className="content-reminder-banner-element">
      <div
        className="content-reminder-banner-element-content"
        onClick={onClickElement}
        style={{ backgroundImage: `url(${thumbnail})` }}
      >
        <div className="banner-element-view-link">
          <Button ghost={true}>
            <FullscreenOutlined />
          </Button>
        </div>
      </div>
    </div>
  );
};

interface IProps {
  workflowElement?: IWorkflowElement;
  explainers?: IWorkflowCarouselSlideElement[];
  stimuli?: Stimulus[];
}

const BannerWorkflowElement: React.FunctionComponent<IProps> = ({
  explainers,
  stimuli,
}) => {
  const isVisible = !!explainers?.length || !!stimuli?.length;
  const isMobile = useIsMobile();
  const wrapperClassName = useMemo(() => cx({
    'content-reminder-banner': true,
    'mobile-layout': isMobile,
  }), [isMobile]);
  const intl = useIntl();
  const { uiTexts } = useContext(CommencedWorkflowContext);

  const [isExplainerOpen, setIsExplainerOpen] = useState<boolean>(false);
  const [explainerOpenPosition, setExplainerOpenPosition] = useState<number>();
  const explainerRef = useRef<CarouselRef>();
  const onExplainerCancel = useCallback(() => setIsExplainerOpen(false), []);
  const onClickBannerExplainerElement = useCallback((position: number) => {
    setExplainerOpenPosition(position);
    setIsExplainerOpen(true);
  }, []);
  const onExplainerCarouselRef = useCallback((nextRef: CarouselRef) => {
    explainerRef.current = nextRef;
    if (explainerRef.current && explainerOpenPosition) {
      explainerRef.current.goTo(explainerOpenPosition);
      setExplainerOpenPosition(undefined);
    }
  }, [explainerOpenPosition]);

  const [isStimuliOpen, setIsStimuliOpen] = useState<boolean>(false);
  const [stimuliOpenPosition, setStimuliOpenPosition] = useState<number>();
  const stimuliRef = useRef<CarouselRef>();
  const onStimuliCancel = useCallback(() => setIsStimuliOpen(false), []);
  const onClickBannerStimuliElement = useCallback((position: number) => {
    setStimuliOpenPosition(position);
    setIsStimuliOpen(true);
  }, []);
  const onStimuliCarouselRef = useCallback((nextRef: CarouselRef) => {
    stimuliRef.current = nextRef;
    if (stimuliRef.current && stimuliOpenPosition) {
      stimuliRef.current.goTo(stimuliOpenPosition);
      setStimuliOpenPosition(undefined);
    }
  }, [stimuliOpenPosition]);

  const content: JSX.Element = (
    <React.Fragment>
      {explainers?.map((explainer: IWorkflowCarouselSlideElement) => (
        <BannerCard
          key={`banner-stimuli-element-${explainer.position}`}
          onClick={onClickBannerExplainerElement}
          position={Number(explainer.position)}
          thumbnail={imageStimulusTypes.includes(explainer.type) ? explainer.path! : imageLinkTypes.includes(explainer.type) ? explainer.image.path : explainer.thumbnail!}
        />
      ))}
      {stimuli?.map((stimulus: Stimulus) => (
        <BannerCard
          key={`banner-stimuli-element-${stimulus.position}`}
          onClick={onClickBannerStimuliElement}
          position={stimulus.position}
          thumbnail={stimulus.qualieFile.thumbnail || stimulus.qualieFile.sourceUri}
        />
      ))}
      {explainers && (
        <ExplainerContentModal
          elements={explainers}
          isOpen={isExplainerOpen}
          onCancel={onExplainerCancel}
          ref={onExplainerCarouselRef}
        />
      )}
      {stimuli && (
        <StimuliContentModal
          elements={stimuli}
          isOpen={isStimuliOpen}
          onCancel={onStimuliCancel}
          ref={onStimuliCarouselRef}
        />
      )}
    </React.Fragment>
  );

  return isVisible ? (
    <div className={wrapperClassName}>
      {isMobile && (
        <Collapse className="content-reminder-banner-collapse">
          <CollapsePanel
            header={(uiTexts?.WORKFLOW_ELEMENT_CONTENT_BANNER_VIEW_AGAIN) ? intl.formatMessage({
              id:  'NOT_DEFINED',
              defaultMessage: uiTexts?.WORKFLOW_ELEMENT_CONTENT_BANNER_VIEW_AGAIN?.value,
            }) : intl.formatMessage({ id: 'workflowElement.contentBanner.viewAgain' })}
            key="banner-collapse-mobile"
          >
            {content}
          </CollapsePanel>
        </Collapse>
      )}
      {!isMobile && content}
    </div>
  ) : null;
};

export default BannerWorkflowElement;
