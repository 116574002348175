import React, { useMemo } from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { Routes as PuxRoutes } from './modules/pux';
import { history, store } from './store';
import './styles/index.less';
import { IntlProvider } from 'react-intl';
import localeEn from 'locale/en';
import HotjarManager from './modules/pux/components/hotjarManager';
import { QualieVideoContext } from 'qualie-common-react';

interface IProps { }

const App: React.FunctionComponent<IProps> = (props) => {
  const contextValue = useMemo(() => ({ gateway: process.env.REACT_APP_GATEWAY_HOST! }), []);

  return (
    <Provider store={store}>
      <IntlProvider
        messages={localeEn.messages}
        locale={localeEn.code}
      >
        <QualieVideoContext.Provider value={contextValue}>
          <HotjarManager />
          <ConnectedRouter history={history}>
            <PuxRoutes />
          </ConnectedRouter>
        </QualieVideoContext.Provider>
      </IntlProvider>
    </Provider>
  );
};

export default App;
