import { Checkbox, Form } from 'antd';
import React, { useContext, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { CommencedWorkflowContext } from '../../contexts';
import ErrorMessage from '../workflowElement/types/errorMessage';

interface IProps {
  name: string;
  incentive: string;
  title?: string;
}

const IncentiveCode: React.FunctionComponent<IProps> = ({
  name,
  incentive,
  title,
}) => {
  const { uiTexts } = useContext(CommencedWorkflowContext);
  const validatorRules = useMemo(() => [
    {
      message: <ErrorMessage message={uiTexts?.IS_REQUIRED?.value} />,
      validator: async (r: any, v?: boolean) => {
        if (v !== true) {
          throw new Error();
        }
      },
    },
  ], [uiTexts]);

  return (
    <div className="incentive-code">
      <div className="incentive-code-end">
        <svg
          className="incentive-code-end-tab"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 128 512"
          shapeRendering="geometricPrecision"
          textRendering="geometricPrecision"
          preserveAspectRatio="none"
        >
          <path
            d="M0,512v-140.4973c40.98829,0,74.21586-51.71232,74.21586-115.5027s-33.22757-115.5027-74.21586-115.5027L0,0h128v512h-128Z"
            fill="currentColor"
            strokeWidth="0"
          />
        </svg>
      </div>
      <div className="incentive-code-content">
        {title != null && <div className="incentive-code-title">{title}</div>}
        <div className="incentive-code-value-container">
          <div className="incentive-code-value-inner">
            <div className="incentive-code-value">{incentive}</div>
            <div className="incentive-code-value-download">This feature is disabled, contact support.</div>
          </div>
        </div>
        <Form.Item
          name={name}
          valuePropName="checked"
          required={true}
          rules={validatorRules}
        >
          <Checkbox>
            <FormattedMessage id="incentiveCode.checkbox.label" />
          </Checkbox>
        </Form.Item>
      </div>
    </div>
  );
};

export default IncentiveCode;