import _ from 'lodash';
import { Form, Alert, Button, FormProps } from 'antd';
import { useSortedWorkflowElements } from 'app/modules/pux/hooks';
import { IWorkflowElement, IWorkflowFormElement, IWorkflowFormElementElement } from 'lib/modules/qualieApi/entities/workflow';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import IWorkflowElementProps from '../../../IWorkflowElementProps';
import FormElement from '../formElement';
import { useAppDispatch } from 'app/hooks';
import { workflowsActions } from 'app/modules/workflows';
import { useIntl } from 'react-intl';
import QualieAPI from 'lib/modules/qualieApi';
import FormActions from 'app/modules/pux/components/layout/formActions';
import { CommencedWorkflowContext, FormElementContext, IFormElementContext } from 'app/modules/pux/contexts';
import { WORKFLOW_ELEMENT_FORM_ELEMENT_TYPES } from '../formElement/types';
import Utils from 'lib/utils';
import { Dictionary } from 'lib/types';

const RankingOpinionMultiFormWorkflowElement: React.FunctionComponent<IWorkflowElementProps<IWorkflowFormElement>> = (props) => {
  const { dependencies, workflowElement, onFinish, onBeforeFinish, skipOnBeforeFinish } = props;
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const { uiTexts } = useContext(CommencedWorkflowContext);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState<string | null>();
  const [fields, setFields] = useState<FormProps['fields']>();
  const { toNextStep } = useContext(CommencedWorkflowContext);
  const [groupedInitiatives] = useState(_.groupBy((dependencies?.initiatives?.collection || []), 'name'));
  const elements = useSortedWorkflowElements(workflowElement.elements);
  const initialValues = useMemo(() => elements.reduce<Dictionary>((acc, curr) => {
    switch (curr.name) {
      case 'opinionActivities':
        const fields = (curr.collection || []).reduce<Dictionary>((acc, curr) => {
          if (curr.collection) {
            acc[curr.name] = curr.collection.reduce<Dictionary>((acc, curr) => {
              switch (curr.name) {
                case 'rank':
                  acc[curr.name] = null;
                  break;
                default:
                  acc[curr.name] = curr.value;
                  break;
              }

              return acc;
            }, {});
          } else {
            acc[curr.name] = curr.value;
          }

          return acc;
        }, {});

        acc[curr.name] = fields;
        break;
      default:
        acc[curr.name] = curr.value;
    }

    return acc;
  }, {}), [elements]);

  const typeMapper = useCallback((element: IWorkflowFormElementElement) => {
    if (element.name === 'opinionActivities' && element.inputType === WORKFLOW_ELEMENT_FORM_ELEMENT_TYPES.Collection) {
      return WORKFLOW_ELEMENT_FORM_ELEMENT_TYPES.ThermometerMulti;
    }

    return undefined;
  }, []);
  const formElementContextValue: IFormElementContext = useMemo(() => ({
    typeMapper: typeMapper,
  }), [typeMapper]);

  const onFormFinish = useCallback(async (values) => {
    if (!skipOnBeforeFinish && onBeforeFinish && !(await onBeforeFinish(workflowElement))) {
      return;
    }

    const hash = Object.keys(values.opinionActivities)[0];
    let results: any[] = [];
    _.forEach(groupedInitiatives, (groupedInitiative: IWorkflowElement[], groupKey: string) => {
      _.forEach(groupedInitiative, (val: any) => {
        results.push({
          type: 'com.qualie.api.dto.RankingOpinionActivityDTO',
          initiativeHash: val.hash,
          opinionType: 'RANKING',
          participantHash: values.participantHash,
          rank: values.opinionActivities[hash].rank,
        });
      });
    });

    const parsedValues = {
      ...values,
      opinionActivities: results,
    };

    setSubmitted(true);
    setError(null);
    setFields(fields => fields?.map(a => ({
      name: a.name,
      errors: [],
    })));

    try {
      await dispatch(workflowsActions.submitWorkflowFormElement({
        actionUrl: workflowElement.actionURL,
        form: parsedValues,
      })).unwrap();
    } catch (e: any) {
      setSubmitted(false);
      switch (e?.code) {
        case QualieAPI.codes.response.InvalidForm:
          setFields(Utils.apiToFormErrors(e?.data || {}, (parsedValues.opinionActivities as any[]).map((a, i) => ({
            from: `opinionActivities[${i}]`,
            to: `opinionActivities.${a.initiativeHash}`,
          }))));
          break;
        case QualieAPI.codes.response.QuotaFull:
        case QualieAPI.codes.response.Terminated:
          toNextStep();

          return;
      }
      setError(e?.message || ((uiTexts?.WORKFLOW_ELEMENT_FORM_GENERIC_SERVER_ERROR) ? intl.formatMessage({
        id:  'NOT_DEFINED',
        defaultMessage: uiTexts?.WORKFLOW_ELEMENT_FORM_GENERIC_SERVER_ERROR?.value,
      }) : intl.formatMessage({ id: 'workflowElement.form.genericServerError' })));

      return;
    }

    if (onFinish) {
      onFinish(workflowElement);
    }
  }, [intl, workflowElement, groupedInitiatives, dispatch, setFields, onFinish, onBeforeFinish, toNextStep, uiTexts, skipOnBeforeFinish]);

  useEffect(() => {
    setSubmitted(false);
  }, [initialValues]);

  return (
    <FormElementContext.Provider value={formElementContextValue}>
      <Form
        layout="vertical"
        onFinish={onFormFinish}
        initialValues={initialValues}
        fields={fields}
      >
        {!!error && (
          <Alert
            type="error"
            message={error}
            showIcon
          />
        )}
        {elements.map(a => (
          <FormElement
            dependencies={dependencies}
            key={a.name}
            workflowElement={a}
          />
        ))}
        <FormActions>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              disabled={submitted}
            >{workflowElement.submitButtonText}</Button>
          </Form.Item>
        </FormActions>
      </Form >
    </FormElementContext.Provider>
  );
};
export default RankingOpinionMultiFormWorkflowElement;
