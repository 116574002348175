import React from 'react';
import { Dictionary } from 'lib/types';
import QualieExternalVideoWorkflowElement from './qualie';
import VimeoExternalVideoWorkflowElement from './vimeo';
import WistiaExternalVideoWorkflowElement from './wistia';
import { IExternalVideoWorkflowElementProps } from './types';

enum WORKFLOW_ELEMENT_EXTERNAL_VIDEO_TYPES {
  Wistia = 'WISTIA',
  Vimeo = 'VIMEO',
  Qualie = 'QUALIE',
};

const WORKFLOW_ELEMENT_EXTERNAL_VIDEO_TYPE_COMPONENTS: Dictionary<React.FunctionComponent<IExternalVideoWorkflowElementProps>> = {
  [WORKFLOW_ELEMENT_EXTERNAL_VIDEO_TYPES.Wistia]: WistiaExternalVideoWorkflowElement,
  [WORKFLOW_ELEMENT_EXTERNAL_VIDEO_TYPES.Vimeo]: VimeoExternalVideoWorkflowElement,
  [WORKFLOW_ELEMENT_EXTERNAL_VIDEO_TYPES.Qualie]: QualieExternalVideoWorkflowElement,
};

export {
  WORKFLOW_ELEMENT_EXTERNAL_VIDEO_TYPES,
  WORKFLOW_ELEMENT_EXTERNAL_VIDEO_TYPE_COMPONENTS,
};
