import _ from 'lodash/fp';
import { IWorkflowFormElementElement } from 'lib/modules/qualieApi/entities/workflow';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { IWorkflowFormElementProps } from './types';
import { Form, Slider } from 'antd';
import { CommencedWorkflowContext } from 'app/modules/pux/contexts';
import ErrorMessage from '../../../errorMessage';
import { workflowsSelectors } from 'app/modules/workflows/store';
import { useSelector } from 'react-redux';

const ScaleSliderFormElement: React.FunctionComponent<IWorkflowFormElementProps<IWorkflowFormElementElement>> = (props) => {
  const { workflowElement, path } = props;
  const sortedOptions = useMemo(() => _.orderBy(['position'], ['desc'], workflowElement.options), [workflowElement.options]);
  const frontLabel = useMemo(() => sortedOptions[0].label, [sortedOptions]);
  const endLabel = useMemo(() => sortedOptions[sortedOptions.length - 1].label, [sortedOptions]);
  const name = useMemo(() => path ? [...path, workflowElement.name] : [workflowElement.name], [path, workflowElement]);
  const { uiTexts } = useContext(CommencedWorkflowContext);
  const [dirty, setDirty] = useState<boolean>(false);
  const theme = useSelector(workflowsSelectors.getTheme);
  const handleStyles = useMemo(() => {
    let styles: React.CSSProperties = {};
    if (!dirty) {
      styles['display'] = 'none';
    }

    return styles;
  }, [dirty]);

  const onAfterChange = useCallback((value: number) => setDirty(true), [setDirty]);

  return (
    <Form.Item
      className="scale-slider-form-element"
      name={name}
      getValueProps={(key: string) => ({ value: key === '' || key == null ? null : key })}
      rules={[
        {
          required: workflowElement.required,
          message: <ErrorMessage message={uiTexts?.NEED_YOUR_ANSWER?.value} />,
        },
      ]}
      extra={(
        <div className="scale-slider-form-labels">
          <div className="scale-slider-form-label slider-label-left">{frontLabel}</div>
          <div className="scale-slider-form-label slider-label-right">{endLabel}</div>
        </div>
      )}
    >
      <Slider
        dots={true}
        tooltipVisible={false}
        min={1}
        max={sortedOptions.length}
        handleStyle={handleStyles}
        onAfterChange={onAfterChange}
        reverse={theme?.direction === 'rtl'}
      />
    </Form.Item>
  );
};

export default ScaleSliderFormElement;
