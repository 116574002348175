import { Form, Input } from 'antd';
import { QuestionLabel } from 'app/modules/pux/components/content';
import { CommencedWorkflowContext } from 'app/modules/pux/contexts';
import { IWorkflowFormElementElement } from 'lib/modules/qualieApi/entities/workflow';
import React, { useContext, useMemo } from 'react';
import ErrorMessage from '../../../errorMessage';
import { IWorkflowFormElementProps } from './types';

const NumberFormElement: React.FunctionComponent<IWorkflowFormElementProps<IWorkflowFormElementElement>> = (props) => {
  const { workflowElement, path } = props;
  const name = useMemo(() => path ? [...path, workflowElement.name] : [workflowElement.name], [path, workflowElement]);
  const { uiTexts } = useContext(CommencedWorkflowContext);

  return (
    <Form.Item
      label={<QuestionLabel>{workflowElement.label}</QuestionLabel>}
      name={name}
      rules={[
        {
          required: workflowElement.required,
          message: <ErrorMessage message={uiTexts?.IS_REQUIRED?.value} />,
        },
      ]}
    >
      <Input type="number" />
    </Form.Item>
  );
};

export default NumberFormElement;