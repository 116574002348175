import React from 'react';
import IWorkflowElementProps from '../IWorkflowElementProps';

const MessageWorkflowElement: React.FunctionComponent<IWorkflowElementProps> = (props) => {
  const { workflowElement } = props;

  return <div className="workflow-element-message-content">{workflowElement.value}</div>;
};

export default MessageWorkflowElement;
