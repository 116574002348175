import React, { useEffect } from 'react';
import { workflowsSelectors } from 'app/modules/workflows/store';
import { useAppSelector } from 'app/hooks';

const HotjarManager: React.FunctionComponent = () => {
  const { recordSessions } = useAppSelector(workflowsSelectors.getFlags);

  useEffect(() => {
    if (recordSessions) {
      try {
        window.hj?.('event', 'recordParticipantSession');
      } catch (e) {
        console.log(e);
      }
    }
  }, [recordSessions]);

  return null;
};

export default HotjarManager;
