import { useSortedWorkflowElements } from 'app/modules/pux/hooks';
import { IWorkflowFormElementElement } from 'lib/modules/qualieApi/entities/workflow';
import React, { useMemo } from 'react';
import FormElement from '..';
import { IWorkflowFormElementProps } from './types';

const CollectionFormElement: React.FunctionComponent<IWorkflowFormElementProps<IWorkflowFormElementElement>> = (props) => {
  const { workflowElement, path } = props;
  const name = useMemo(() => path ? [...path, workflowElement.name] : [workflowElement.name], [path, workflowElement]);
  const collection = useSortedWorkflowElements<IWorkflowFormElementElement>(workflowElement.collection as any);

  return (
    <React.Fragment>
      {collection.map((a) => (
        <FormElement
          key={a.name}
          workflowElement={a}
          path={name}
        />
      ))}
    </React.Fragment>
  );
};

export default CollectionFormElement;
