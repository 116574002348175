import _ from 'lodash';
import { Dictionary } from './types';

const scrollToTop = () => {
  if (window.scroll) {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }
};

const jumpToTop = () => {
  if (window.scrollTo) {
    window.scrollTo(0, 0);
  }
};

/**
 * Returns a promise that resolves after the provided time.
 * A handy function when you need to perform a timeout or wait
 * in an async function.
 */
const wait = (ms: number) => new Promise((resolve) => {
  setTimeout(() => {
    resolve(undefined);
  }, ms);
});

/**
 * Maps Qualie API errors into a structure that is compatible with
 * the forms in the user interface.
 */
const apiToFormErrors = (errors: Dictionary<string>, overrides?: { from: string, to: string }[]) => {
  let errorsWithOverrides: typeof errors = {};

  if (overrides) {
    for (let key in errors) {
      let finalKey = key;
      for (let i = 0; i < overrides.length; i++) {
        if (key.startsWith(overrides[i].from)) {
          finalKey = key.replace(overrides[i].from, overrides[i].to);
          break;
        }
      }
      errorsWithOverrides[finalKey] = errors[key];
    }
  }

  return _.map(errorsWithOverrides, (v, k) => ({
    name: k.split('.'),
    errors: [v],
  }));
};

const isIOS = () => (
  [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod',
  ].includes(navigator.platform)
  // iPad on iOS 13 detection
  || (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
);

const getIOSVersion = () => {
  try {
    // supports iOS 2.0 and later
    const match = (navigator.userAgent).match(/OS (\d+)_(\d+)_?(\d+)?/);
    if (match?.length === 4) {
      return [
        parseInt(match[1], 10),
        parseInt(match[2], 10),
        parseInt(match[3] || '0', 10),
      ];
    }
  } catch (e) {
    return [null, null, null];
  }

  return [null, null, null];
};

const Utils = {
  scrollToTop: scrollToTop,
  jumpToTop: jumpToTop,
  wait,
  apiToFormErrors,
  isIOS,
  getIOSVersion,
};

export default Utils;
