import { createSlice } from '@reduxjs/toolkit';

interface IAppState {}

const initialState: IAppState = {};

const appSlice = createSlice({
  name: 'app',
  initialState: initialState,
  reducers: {},
});

const appActions = appSlice.actions;
const appReducer = appSlice.reducer;

export {
  appActions,
  appReducer,
};