import _ from 'lodash';
import { Form, Alert, Button, FormProps, Row, Col } from 'antd';
import { useKeyedWorkflowElements, useSortedWorkflowElements } from 'app/modules/pux/hooks';
import { IWorkflowFormElement, IWorkflowFormElementElement } from 'lib/modules/qualieApi/entities/workflow';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import FormElement from '../formElement';
import { CommencedWorkflowContext } from 'app/modules/pux/contexts';
import { useAppDispatch } from 'app/hooks';
import { workflowsActions } from 'app/modules/workflows';
import { useIntl } from 'react-intl';
import QualieAPI from 'lib/modules/qualieApi';
import { IWorkflowFormElementProps } from '../formElement/types/types';
import { WORKFLOW_ELEMENT_FORM_ELEMENT_TYPES } from '../formElement/types';
import { useForm } from 'antd/lib/form/Form';
import FormActions from 'app/modules/pux/components/layout/formActions';
import { Dictionary } from 'lib/types';
import GenderRadioFormElement from '../formElement/types/genderRadio';
import { DefaultRowGutters } from 'lib/constants';

type KeyedElements = {
  age?: IWorkflowFormElementElement;
  email?: IWorkflowFormElementElement;
  gender?: IWorkflowFormElementElement;
  location?: IWorkflowFormElementElement;
  name?: IWorkflowFormElementElement;
};

const customElementMap = [
  'age',
  'email',
  'gender',
  'location',
  'name',
];

const DetailsFormWorkflowElement: React.FunctionComponent<IWorkflowFormElementProps<IWorkflowFormElement>> = (props) => {
  const { workflowElement, onFinish, onBeforeFinish } = props;
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const { uiTexts } = useContext(CommencedWorkflowContext);
  const [form] = useForm();
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState<string | null>();
  const [fields, setFields] = useState<FormProps['fields']>();
  const { flags, participant, workflowId, onReady, toNextStep } = useContext(CommencedWorkflowContext);
  const elements = useSortedWorkflowElements(workflowElement.elements);
  const keyedElements = useKeyedWorkflowElements<KeyedElements>(elements.filter(a => customElementMap.includes(a.name)));
  const showEmail = useMemo(() => _.find(elements, { name: 'uid' })?.value === '', [elements]);
  const isAutoSubmit = useMemo(() => {
    const filteredElements = elements?.filter(element => ![
      WORKFLOW_ELEMENT_FORM_ELEMENT_TYPES.Collection,
      WORKFLOW_ELEMENT_FORM_ELEMENT_TYPES.Hidden,
    ].includes(element.inputType as WORKFLOW_ELEMENT_FORM_ELEMENT_TYPES)) || [];
    const isOnlyEmailElement = filteredElements.length === 1 && !!_.find(filteredElements, { name: 'email' });

    return (!showEmail && isOnlyEmailElement) && !(filteredElements.length > 1);
  }, [elements, showEmail]);
  const activityHash = useMemo(() => _.find(elements, { name: 'activityHash' })?.value, [elements]);

  const initialValues = useMemo(() => elements.reduce<Dictionary>((acc, curr) => {
    // reserved field names get their values elsewhere
    switch (curr.name) {
      case 'provider':
        acc[curr.name] = flags.pp;
        break;
      case 'email':
      case 'name':
      case 'gender':
      case 'age':
      case 'location':
        return acc;
      default:
        acc[curr.name] = curr.value;
    }

    return acc;
  }, {}), [elements, flags]);

  const nameElement = keyedElements?.name;
  const emailElement = keyedElements?.email;
  const ageElement = keyedElements?.age;
  const locationElement = keyedElements?.location;
  const genderElement = keyedElements?.gender;
  const showAgeElement = ageElement?.inputType !== WORKFLOW_ELEMENT_FORM_ELEMENT_TYPES.Hidden;
  const showGenderElement = genderElement?.inputType !== WORKFLOW_ELEMENT_FORM_ELEMENT_TYPES.Hidden;
  const showNameElement = nameElement?.inputType !== WORKFLOW_ELEMENT_FORM_ELEMENT_TYPES.Hidden;
  const showLocationElement = locationElement?.inputType !== WORKFLOW_ELEMENT_FORM_ELEMENT_TYPES.Hidden;

  const onFormFinish = useCallback(async (values) => {
    if (onBeforeFinish && !(await onBeforeFinish(workflowElement))) {
      return;
    }

    let fieldData: Dictionary<string> = {};
    if (showNameElement && values.name) {
      fieldData.name = values.name;
    }
    if (!showNameElement && !values.name) {
      fieldData.name = nameElement?.value || 'NA';
    }
    if (showEmail && values.email) {
      fieldData.email = values.email;
    }
    if (!showEmail && !values.email) {
      fieldData.uid = flags?.uid || '__GENERATE__';
    }
    if (showGenderElement && values.gender) {
      fieldData.gender = values.gender;
    }
    if (!showGenderElement && !values.gender) {
      fieldData.gender = 'MALE';
    }
    if (showAgeElement && values.age) {
      fieldData.age = values.age;
    }
    if (!showAgeElement && !values.age) {
      fieldData.age = '99';
    }
    if (showLocationElement && values.locationRequired && values.location) {
      fieldData.location = values.location;
    }
    if (!showLocationElement && values.locationRequired && !values.location) {
      fieldData.location = locationElement?.value || 'NA';
    }
    if (activityHash) {
      fieldData.activityHash = activityHash;
    }

    const parsedValues = {
      ...fieldData,
      referrer: values.referrer,
      participantStatus: values.participantStatus,
      group: values.group,
      respondentType: values.respondentType,
      provider: values.provider,
      locationRequired: values.locationRequired,
      questionAnswers: values.questionAnswers || [],
      passedData: flags.passedParams,
    };

    setSubmitted(true);
    setError(null);
    setFields(elements.map(a => ({
      name: a.name,
      errors: [],
    })));

    try {
      const participant = await dispatch(workflowsActions.createParticipant({
        actionUrl: workflowElement.actionURL,
        form: parsedValues,
      })).unwrap();
      dispatch(workflowsActions.setPersistedParticipant({
        workflowId: workflowId!,
        participant: {
          participant: participant.data,
          flags: flags,
        },
      }));
    } catch (e: any) {
      setSubmitted(false);
      switch (e?.code) {
        case QualieAPI.codes.response.InvalidForm:
          const errors = _.map(e?.data, (v: string, k: string) => ({
            name: k,
            errors: [v],
          }));
          setFields(errors);
          break;
        case QualieAPI.codes.response.Terminated:
          toNextStep();

          return;
      }
      setError(e?.message || ((uiTexts?.WORKFLOW_ELEMENT_FORM_GENERIC_SERVER_ERROR) ? intl.formatMessage({
        id:  'NOT_DEFINED',
        defaultMessage: uiTexts?.WORKFLOW_ELEMENT_FORM_GENERIC_SERVER_ERROR?.value,
      }) : intl.formatMessage({ id: 'workflowElement.form.genericServerError' })));
    }
  }, [onBeforeFinish, workflowElement, showNameElement, showEmail, showGenderElement, showAgeElement, showLocationElement, activityHash, flags, elements, nameElement?.value, locationElement?.value, dispatch, workflowId, uiTexts?.WORKFLOW_ELEMENT_FORM_GENERIC_SERVER_ERROR, intl, toNextStep]);

  useEffect(() => {
    if (participant && onFinish) {
      onFinish(workflowElement);
    }
  }, [participant, onFinish, workflowElement]);

  useEffect(() => {
    if (isAutoSubmit) {
      form.submit();
    } else {
      onReady();
    }
  }, [isAutoSubmit, form, onReady]);

  useEffect(() => {
    setSubmitted(false);
  }, [initialValues]);

  return (
    <Form
      className="details-form"
      form={form}
      layout="vertical"
      onFinish={onFormFinish}
      initialValues={initialValues}
      fields={fields}
    >
      {!!error && (
        <Alert
          type="error"
          message={error}
          showIcon
        />
      )}
      {nameElement && !showAgeElement && (
        <FormElement
          key={nameElement.name}
          workflowElement={nameElement}
        />
      )}
      {nameElement && showAgeElement && (
        <Row gutter={DefaultRowGutters}>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 12 }}
          >
            {nameElement && (
              <FormElement
                key={nameElement.name}
                workflowElement={nameElement}
              />
            )}
          </Col>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 12 }}
          >
            {ageElement && (
              <FormElement
                key={ageElement.name}
                workflowElement={ageElement}
              />
            )}
          </Col>
        </Row>
      )}
      {genderElement && showGenderElement && (
        <GenderRadioFormElement
          key={genderElement.name}
          workflowElement={genderElement}
        />
      )}
      {showEmail && emailElement && (
        <FormElement
          key={emailElement.name}
          workflowElement={emailElement}
        />
      )}
      {locationElement && (
        <FormElement
          key={locationElement.name}
          workflowElement={locationElement}
        />
      )}
      {elements.filter(a => !customElementMap.includes(a.name)).map(a => (
        <FormElement
          asType={isAutoSubmit && a.inputType !== WORKFLOW_ELEMENT_FORM_ELEMENT_TYPES.Collection ? WORKFLOW_ELEMENT_FORM_ELEMENT_TYPES.Hidden : undefined}
          key={a.name}
          workflowElement={a}
        />
      ))}
      {!isAutoSubmit && <FormActions>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            disabled={submitted}
          >{workflowElement.submitButtonText}</Button>
        </Form.Item>
      </FormActions>}
    </Form >
  );
};
export default DetailsFormWorkflowElement;
