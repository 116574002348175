import { useAppSelector, useExternalScript, useMountEffect } from 'app/hooks';
import { puxSelectors } from 'app/modules/pux/store';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import cx from 'classnames';
import { IExternalVideoWorkflowElementProps } from './types';
import { CommencedWorkflowContext } from 'app/modules/pux/contexts';

const getVideo = (videoId: string) => window?.Wistia?.api(videoId);
const isVideoComplete = (video: any) => (video.time() / video.duration()) > 0.90;

const WistiaExternalVideoWorkflowElement: React.FunctionComponent<IExternalVideoWorkflowElementProps> = ({
  active,
  workflowElement,
  onValid,
}) => {
  const mounted = useRef(true);
  const host = useAppSelector(state => puxSelectors.getHost(state, 'wistia'));
  const [video, setVideo] = useState<any>();
  const [watched, setWatched] = useState(false);
  const { showVideoControls } = useContext(CommencedWorkflowContext);
  const wrapperClassName = useMemo(() => cx({
    'wistia_embed': true,
    [`wistia_async_${workflowElement.videoId}`]: true,
    'playbar=false': !showVideoControls,
    'hsl=false': true,
  }), [showVideoControls, workflowElement.videoId]);

  /**
   * Now that one of our scripts is loaded we should try and get the video
   * out of the Wistia API.
   */
  const onVideoScriptLoad = useCallback(() => {
    if (mounted.current) {
      setVideo(getVideo(workflowElement.videoId));
    }
  }, [workflowElement]);

  /**
   * Our video changed we should retarget to the new video from the Wistia API.
   */
  useEffect(() => {
    setVideo(getVideo(workflowElement.videoId));
  }, [workflowElement]);

  useEffect(() => {
    if (watched) {
      return;
    }

    const pollingHandle = setInterval(() => {
      if (video?.embedded()) {
        if (isVideoComplete(video)) {
          setWatched(true);
        }
      } else {
        const nextVideo = getVideo(workflowElement.videoId);
        if (nextVideo !== video) {
          setVideo(nextVideo);
        }
      }
    }, 1000);

    return () => {
      clearInterval(pollingHandle);
    };
  }, [video, watched, setWatched, setVideo, workflowElement.videoId]);

  useEffect(() => {
    if (onValid) {
      onValid(workflowElement, watched);
    }
  }, [workflowElement, watched, onValid]);

  useEffect(() => {
    if (active === false && video != null) {
      video.pause();
    }
  }, [active, video]);

  useExternalScript('https://fast.wistia.com/assets/external/E-v1.js', false, onVideoScriptLoad);
  // We need to destroy the jsonp video script on unmount to clean up properly!
  useExternalScript(`${host}/embed/medias/${workflowElement.videoId}.jsonp`, true, onVideoScriptLoad);

  useMountEffect(() => () => {
    mounted.current = false;
  });

  return (
    <div className="wistia-external-video-workflow-element" >
      {!!workflowElement.videoId && (
        <div
          className={wrapperClassName}
          style={{
            width: '100%',
            height: '100%',
          }}
        />
      )}
    </div >
  );
};

export default WistiaExternalVideoWorkflowElement;
