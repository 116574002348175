import _ from 'lodash';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
  IWorkflowElement,
  IWorkflowFormElement,
  IWorkflowFormElementElement,
  IWorkflowFormElementElementOption,
} from 'lib/modules/qualieApi/entities/workflow';
import IWorkflowElementProps from '../../../IWorkflowElementProps';
import { useAppDispatch } from 'app/hooks';
import { Alert, Button, Form, FormProps } from 'antd';
import { useSortedWorkflowElements } from 'app/modules/pux/hooks';
import FormElement from '../formElement';
import { workflowsActions } from 'app/modules/workflows';
import QualieAPI from 'lib/modules/qualieApi';
import { useIntl } from 'react-intl';
import FormActions from 'app/modules/pux/components/layout/formActions';
import Utils from 'lib/utils';
import { Dictionary } from 'lib/types';
import { WORKFLOW_ELEMENT_FORM_ELEMENT_TYPES } from '../formElement/types';
import { CommencedWorkflowContext } from 'app/modules/pux/contexts';
import StimulusWorkflowElement from '../../stimulus';

type CheckboxElementValues = {
  answers: string[];
  other: string;
}

type RadioElementValues = {
  answer: string;
  other: string;
}

type ScaleElementValues = {
  selected: string;
}

type SelectElementValues = {
  selected: string;
}

const getInitialValues = (elements: IWorkflowElement[]) => elements.reduce<Dictionary>((acc, curr) => {
  switch (curr.name) {
    case 'questionAnswers':
      return acc;
    default:
      acc[curr.name] = curr.collection
        ? getInitialValues(curr.collection)
        : curr.value;
      break;
  }

  return acc;
}, {});

const QuestionsFormWorkflowElement: React.FunctionComponent<IWorkflowElementProps<IWorkflowFormElement>> = (props) => {
  const { workflowElement, onFinish, onBeforeFinish } = props;
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const { uiTexts } = useContext(CommencedWorkflowContext);
  const { toNextStep } = useContext(CommencedWorkflowContext);
  const [error, setError] = useState<string | null>();
  const [fields, setFields] = useState<FormProps['fields']>();
  const elements = useSortedWorkflowElements<IWorkflowFormElementElement>(workflowElement.elements);
  const initialValues = useMemo(() => getInitialValues(elements), [elements]);
  const [submitted, setSubmitted] = useState(false);
  const { activityOptions } = useContext(CommencedWorkflowContext);
  const stimulus = activityOptions?.stimulus ? activityOptions?.stimulus : null;
  const [onStimulusValidCalled, setOnStimulusValidCalled] = useState<boolean>(false);
  const [stimulusValid, setStimulusValid] = useState<boolean>(false);

  const onCheckError = useCallback(() => {

    if ((stimulus && stimulus.stimuli.length === 1 && (stimulus.stimuli[0].stimulusType === 'VIDEO' || stimulus.stimuli[0].stimulusType === 'IMAGE_LINK') && (!stimulusValid)) ||
        (stimulus && stimulus.stimuli.length > 1 && (!onStimulusValidCalled || !stimulusValid))) {
      setError(uiTexts?.VIEW_ALL_CONTENT?.value);

      return true;
    }

    return error;
  }, [stimulus, onStimulusValidCalled, stimulusValid, error, uiTexts?.VIEW_ALL_CONTENT?.value]);

  const onFormFinish = useCallback(async (values) => {
    if (onBeforeFinish && !(await onBeforeFinish(workflowElement))) {
      return;
    }

    if (onCheckError()) {
      return;
    }

    const questionAnswers = _.keyBy(elements.find(element => element.name === 'questionAnswers')?.collection, 'name');
    const parsedValues = {
      ...values,
      questionAnswers: _.map(values.questionAnswers, (v, k) => {
        const question: IWorkflowFormElementElement = questionAnswers[k];

        /**
         * This little nightmare is all about mapping the provided form structure to what the API expects.
         */
        let selectedAnswers: Dictionary = {};
        if (question?.options && (_.isArray(v) || _.isString(v))) {
          selectedAnswers = (_.isArray(v) ? v : [v]).reduce<Dictionary<string>>((acc, v) => {
            const option = question.options?.find((a: IWorkflowFormElementElementOption) => a.key === v);
            acc[v] = option ? option.value : v;

            return acc;
          }, {});
        } else {
          if (_.isObject(v)) {
            switch (question.inputType) {
              case WORKFLOW_ELEMENT_FORM_ELEMENT_TYPES.Checkbox:
              case WORKFLOW_ELEMENT_FORM_ELEMENT_TYPES.CheckboxButton:
              case WORKFLOW_ELEMENT_FORM_ELEMENT_TYPES.CheckboxImage:
                const checkboxesValues: CheckboxElementValues = v as CheckboxElementValues;
                if (checkboxesValues.answers) {
                  selectedAnswers = checkboxesValues.answers.reduce<Dictionary<string>>((acc, v) => {
                    const checkboxOption = question.options?.find(a => a.key === v);

                    if (checkboxOption?.type === 'TEXT') {
                      acc[v] = checkboxesValues.other;
                    } else {
                      acc[v] = checkboxOption ? checkboxOption.value : v;
                    }

                    return acc;
                  }, {});
                }
                break;
              case WORKFLOW_ELEMENT_FORM_ELEMENT_TYPES.Radio:
              case WORKFLOW_ELEMENT_FORM_ELEMENT_TYPES.RadioButton:
              case WORKFLOW_ELEMENT_FORM_ELEMENT_TYPES.RadioImage:
                const radioValues: RadioElementValues = v as RadioElementValues;
                const radioOption = question.options?.find(a => a.key === radioValues.answer);

                if (radioValues.answer) {
                  if (radioOption?.type === 'TEXT') {
                    selectedAnswers[radioValues.answer] = radioValues.other;
                  } else {
                    const option = question.options?.find((a: IWorkflowFormElementElementOption) => a.key === radioValues.answer);
                    selectedAnswers[radioValues.answer] = option ? option.value : v;
                  }
                }
                break;
              case WORKFLOW_ELEMENT_FORM_ELEMENT_TYPES.Scale:
                const scaleValues: ScaleElementValues = v as ScaleElementValues;
                const option = question.options?.find((a: IWorkflowFormElementElementOption) => a.key === scaleValues.selected);
                selectedAnswers[scaleValues.selected] = option ? option.value : v;
                break;
              case WORKFLOW_ELEMENT_FORM_ELEMENT_TYPES.Select:
                const selectValues: SelectElementValues = v as SelectElementValues;
                const selectOption = question.options?.find((a: IWorkflowFormElementElementOption) => a.key === selectValues.selected);
                selectedAnswers[selectValues.selected] = selectOption ? selectOption.value : v;
                break;
              default:
                selectedAnswers = v;
                break;
            }
          } else {
            let key = null;
            switch (question.inputType) {
              case WORKFLOW_ELEMENT_FORM_ELEMENT_TYPES.ThemometerFormElement:
                key = `${question?.name || k}_${v - 1}`;
                break;
              default:
                key = question?.name || k;
                break;
            }

            selectedAnswers = { [key]: v };
          }
        }

        return {
          questionHash: k,
          selectedAnswers: selectedAnswers,
        };
      }),
    };

    setSubmitted(true);
    setError(null);
    setFields(fields => fields?.map(a => ({
      name: a.name,
      errors: [],
    })));

    try {
      await dispatch(workflowsActions.submitWorkflowFormElement({
        actionUrl: workflowElement.actionURL,
        form: parsedValues,
      })).unwrap();
    } catch (e: any) {
      setSubmitted(false);
      switch (e?.code) {
        case QualieAPI.codes.response.InvalidForm:
          setFields(Utils.apiToFormErrors(e?.data || {}, []));
          break;
        case QualieAPI.codes.response.QuotaFull:
        case QualieAPI.codes.response.Terminated:
          toNextStep();

          return;
      }
      setError(e?.message || ((uiTexts?.WORKFLOW_ELEMENT_FORM_GENERIC_SERVER_ERROR) ? intl.formatMessage({
        id:  'NOT_DEFINED',
        defaultMessage: uiTexts?.WORKFLOW_ELEMENT_FORM_GENERIC_SERVER_ERROR?.value,
      }) : intl.formatMessage({ id: 'workflowElement.form.genericServerError' })));

      return;
    }

    if (onFinish) {
      onFinish(workflowElement);
    }
  }, [onBeforeFinish, workflowElement, onCheckError, elements, onFinish, dispatch, uiTexts, intl, toNextStep]);

  useEffect(() => {
    setSubmitted(false);
  }, [initialValues]);

  const onStimulusValid = useCallback((element: IWorkflowElement, valid: boolean) => {
    setOnStimulusValidCalled(true);
    setStimulusValid(valid);
    if (valid) {
      setError(null);
    }
  }, []);

  const stimulusWorkflowElement = useMemo(() => {
    if (stimulus) {
      return ({
        name: 'stimulus',
        visible: true,
        workflowElementType: 'COLLECTION',
        stimulus,
      });
    }

    return null;
  }, [stimulus]);

  return (
    <>
      {stimulusWorkflowElement &&
        <StimulusWorkflowElement
          workflowElement={stimulusWorkflowElement}
          onValid={onStimulusValid}
        />
      }
      <Form
        layout="vertical"
        onFinish={onFormFinish}
        initialValues={initialValues}
        fields={fields}
      >
        {!!error && (
          <Alert
            type="error"
            message={error}
            showIcon
          />
        )}
        {elements.map(a => (
          <FormElement
            key={a.name}
            workflowElement={a}
          />
        ))}
        <FormActions>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              disabled={submitted}
            >{workflowElement.submitButtonText}</Button>
          </Form.Item>
        </FormActions>
      </Form >
    </>
  );
};

export default QuestionsFormWorkflowElement;
