import 'rollbar';
import QualieAPI from 'lib/modules/qualieApi';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';
import reportWebVitals from './reportWebVitals';

declare global {
  interface Window {
    olark: (action: string) => void;
    _wq?: any;
    Wistia?: any;
    CameraTag?: {
      setup: () => void;
      observe: (id: string, event: string, cb: (evt: any) => void) => void,
      stopObserving: (id: string, event: string, cb: (evt: any) => void) => void,
    };
    hj: (event: string, actionName: string) => void;
  }

  namespace JSX {
    interface IntrinsicElements {
      camera: React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
    }
  }
}

QualieAPI.init({
  hosts: {
    gateway: process.env.REACT_APP_GATEWAY_HOST!,
  },
});

ReactDOM.render(
  <App />,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
