import { IWorkflowFormElement } from 'lib/modules/qualieApi/entities/workflow';
import React from 'react';
import IWorkflowElementProps from '../../../IWorkflowElementProps';
import DetailsFormWorkflowElement from './details';
import QuestionsOpinionActivityFormWorkflowElement from './questionsOpinionActivity';
import QuestionsFormWorkflowElement from './questions';
import RankingOpinionFormWorkflowElement from './rankingOpinion';
import RankingOpinionMultiFormWorkflowElement from './rankingOpinionMulti';
import ReviewVideoOpinionFormWorkflowElement from './reviewVideoOpinion';
import ThankYouFormWorkflowElement from './thankYou';
import RankingNpsOpinionFormWorkflowElement from './rankingNpsOpinion';
import ScaleOpinionActivityFormWorkflowElement from './scaleOpinionActivity';
import ScaleOpinionMultiFormWorkflowElement from './scaleOpinionMulti';
import QuestionOpinionActivityFormWorkflowElement from './questionOpinionActivity';
import ReviewQuestionOpinionFormWorkflowElement from './reviewQuestionOpinion';
import QuestionsMatrixFormWorkflowElement from './questionsMatrix';
import ReviewVideoV2OpinionFormWorkflowElement from './reviewVideoV2Opinion';
import PreferenceOpinionDragFormWorkflowElement from './preferenceOpinionDrag';
import PreferenceOpinionDragMultiFormWorkflowElement from './preferenceOpinionDragMulti';
import PreferenceOpinionActivityFormWorkflowElement from './preferenceOpinionActivity';
import RankQuestionOpinionFormWorkflowElement from './rankQuestionOpinion';
import PreferenceOpinionMultiActivityFormWorkflowElement from './preferenceOpinionMultiActivity';
import RecordOpinionQcFormWorkflowElement from './recordOpinionQc';
import RecordOpinionCtFormWorkflowElement from './recordOpinionCt';
import { Dictionary } from 'lib/types';
import RankQuestionOpinionMultiFormWorkflowElement from './rankQuestionOpinionMulti';
import InitiativeQuestionsOpinionActivityFormWorkflowElement from './initiativeQuestionsOpinionActivity';
import ImageOpinionActivityFormWorkflowElement from './imageOpinionActivity';
import ReviewImageOpinionFormWorkflowElement from './reviewImageOpinion';
import UploadVideoOpinionFormWorkflowElement from './uploadVideoOpinion';

enum WORKFLOW_ELEMENT_FORM_TYPES {
  Details = 'DETAILS',
  RankingOpinion = 'RANKING_OPINION_ACTIVITY',
  RankingOpinionMulti = 'RANKING_OPINION_MULTI_ACTIVITY',
  ThankYou = 'THANK_YOU',
  ReviewVideoOpinion = 'REVIEW_VIDEO_OPINION',
  ReviewVideoV2Opinion = 'REVIEW_VIDEO_V2_OPINION',
  Questions = 'QUESTIONS',
  QuestionsOpinionActivity = 'QUESTIONS_OPINION_ACTIVITY',
  QuestionOpinionActivity = 'QUESTION_OPINION_ACTIVITY',
  RankingNpsOpinion = 'RANKING_NPS_OPINION',
  ScaleOpinionActivity = 'SCALE_OPINION_ACTIVITY',
  ScaleOpinionMulti = 'SCALE_OPINION_MULTI_ACTIVITY',
  ReviewQuestionOpinion = 'REVIEW_QUESTION_OPINION',
  QuestionsMatrix = 'QUESTIONS_MATRIX',
  PreferenceOpinionDrag = 'PREFERENCE_OPINION_DRAG',
  PreferenceOpinionDragMulti = 'PREFERENCE_OPINION_DRAG_MULTI',
  PreferenceOpinionActivity = 'PREFERENCE_OPINION_ACTIVITY',
  PreferenceOpinionMultiActivity = 'PREFERENCE_OPINION_MULTI_ACTIVITY',
  RankQuestionOpinion = 'RANK_QUESTION_OPINION_ACTIVITY',
  RankQuestionOpinionMulti = 'RANK_QUESTION_OPINION_MULTI_ACTIVITY',
  RecordOpinionCt = 'RECORD_OPINION_CT_ACTIVITY',
  RecordOpinionQc = 'RECORD_OPINION_QC_ACTIVITY',
  InitiativeQuestionsOpinionActivity = 'INITIATIVE_QUESTIONS_OPINION_ACTIVITY',
  ImageOpinionActivity = 'IMAGE_OPINION_ACTIVITY',
  ReviewImageOpinion = 'REVIEW_IMAGE_OPINION',
  UploadVideoOpinion = 'UPLOAD_VIDEO_OPINION_ACTIVITY',
};

const WORKFLOW_ELEMENT_FORM_TYPE_COMPONENTS: Dictionary<React.FunctionComponent<IWorkflowElementProps<IWorkflowFormElement>>> = {
  [WORKFLOW_ELEMENT_FORM_TYPES.Details]: DetailsFormWorkflowElement,
  [WORKFLOW_ELEMENT_FORM_TYPES.InitiativeQuestionsOpinionActivity]: InitiativeQuestionsOpinionActivityFormWorkflowElement,
  [WORKFLOW_ELEMENT_FORM_TYPES.RankingOpinion]: RankingOpinionFormWorkflowElement,
  [WORKFLOW_ELEMENT_FORM_TYPES.RankingOpinionMulti]: RankingOpinionMultiFormWorkflowElement,
  [WORKFLOW_ELEMENT_FORM_TYPES.ThankYou]: ThankYouFormWorkflowElement,
  [WORKFLOW_ELEMENT_FORM_TYPES.ReviewVideoOpinion]: ReviewVideoOpinionFormWorkflowElement,
  [WORKFLOW_ELEMENT_FORM_TYPES.ReviewVideoV2Opinion]: ReviewVideoV2OpinionFormWorkflowElement,
  [WORKFLOW_ELEMENT_FORM_TYPES.Questions]: QuestionsFormWorkflowElement,
  [WORKFLOW_ELEMENT_FORM_TYPES.QuestionsOpinionActivity]: QuestionsOpinionActivityFormWorkflowElement,
  [WORKFLOW_ELEMENT_FORM_TYPES.QuestionOpinionActivity]: QuestionOpinionActivityFormWorkflowElement,
  [WORKFLOW_ELEMENT_FORM_TYPES.RankingNpsOpinion]: RankingNpsOpinionFormWorkflowElement,
  [WORKFLOW_ELEMENT_FORM_TYPES.ScaleOpinionActivity]: ScaleOpinionActivityFormWorkflowElement,
  [WORKFLOW_ELEMENT_FORM_TYPES.ScaleOpinionMulti]: ScaleOpinionMultiFormWorkflowElement,
  [WORKFLOW_ELEMENT_FORM_TYPES.ReviewQuestionOpinion]: ReviewQuestionOpinionFormWorkflowElement,
  [WORKFLOW_ELEMENT_FORM_TYPES.QuestionsMatrix]: QuestionsMatrixFormWorkflowElement,
  [WORKFLOW_ELEMENT_FORM_TYPES.PreferenceOpinionDrag]: PreferenceOpinionDragFormWorkflowElement,
  [WORKFLOW_ELEMENT_FORM_TYPES.PreferenceOpinionDragMulti]: PreferenceOpinionDragMultiFormWorkflowElement,
  [WORKFLOW_ELEMENT_FORM_TYPES.PreferenceOpinionActivity]: PreferenceOpinionActivityFormWorkflowElement,
  [WORKFLOW_ELEMENT_FORM_TYPES.PreferenceOpinionMultiActivity]: PreferenceOpinionMultiActivityFormWorkflowElement,
  [WORKFLOW_ELEMENT_FORM_TYPES.RankQuestionOpinion]: RankQuestionOpinionFormWorkflowElement,
  [WORKFLOW_ELEMENT_FORM_TYPES.RankQuestionOpinionMulti]: RankQuestionOpinionMultiFormWorkflowElement,
  [WORKFLOW_ELEMENT_FORM_TYPES.RecordOpinionCt]: RecordOpinionCtFormWorkflowElement,
  [WORKFLOW_ELEMENT_FORM_TYPES.RecordOpinionQc]: RecordOpinionQcFormWorkflowElement,
  [WORKFLOW_ELEMENT_FORM_TYPES.ImageOpinionActivity]: ImageOpinionActivityFormWorkflowElement,
  [WORKFLOW_ELEMENT_FORM_TYPES.ReviewImageOpinion]: ReviewImageOpinionFormWorkflowElement,
  [WORKFLOW_ELEMENT_FORM_TYPES.UploadVideoOpinion]: UploadVideoOpinionFormWorkflowElement,
};

export {
  WORKFLOW_ELEMENT_FORM_TYPES,
  WORKFLOW_ELEMENT_FORM_TYPE_COMPONENTS,
};
