import _ from 'lodash/fp';
import { IWorkflowFormElementElement } from 'lib/modules/qualieApi/entities/workflow';
import React, { useContext, useMemo } from 'react';
import { IWorkflowFormElementProps } from './types';
import { Form, Radio } from 'antd';
import { CommencedWorkflowContext } from 'app/modules/pux/contexts';
import cx from 'classnames';
import ErrorMessage from '../../../errorMessage';
import { QuestionLabel } from 'app/modules/pux/components/content';

const OVERSIZE_THRESHOLD = 11;
const NpsRadioFormElement: React.FunctionComponent<IWorkflowFormElementProps<IWorkflowFormElementElement>> = (props) => {
  const { workflowElement, path } = props;
  const sortedOptions = useMemo(() => _.sortBy('position', workflowElement.options), [workflowElement.options]);
  const frontLabel = useMemo(() => sortedOptions[0].label, [sortedOptions]);
  const endLabel = useMemo(() => sortedOptions[sortedOptions.length - 1].label, [sortedOptions]);
  const options = useMemo(() => sortedOptions.map((option, i) => {
    const isFirst = i === 0;
    const isLast = i === sortedOptions!.length - 1;

    return {
      ...option,
      label: isFirst ? '0' : isLast ? i.toString() : option.label,
    };
  }), [sortedOptions]);
  const name = useMemo(() => path ? [...path, workflowElement.name] : [workflowElement.name], [path, workflowElement]);
  const { uiTexts } = useContext(CommencedWorkflowContext);
  const className = useMemo(() => cx({
    'nps-radio-form-element': true,
    'oversized': options.length > OVERSIZE_THRESHOLD,
  }), [options]);

  return (
    <Form.Item
      label={<QuestionLabel>{workflowElement.label}</QuestionLabel>}
      className={className}
      name={name}
      rules={[
        {
          required: workflowElement.required,
          message: <ErrorMessage message={uiTexts?.NEED_ANSWER?.value} />,
        },
      ]}
      extra={(
        <div className="nps-radio-form-labels">
          <div className="nps-radio-form-label">{frontLabel}</div>
          <div className="nps-radio-form-label">{endLabel}</div>
        </div>
      )}
    >
      <Radio.Group>
        {options.map(option => (
          <Radio.Button
            key={option.value}
            value={option.key}
          >{option.label}</Radio.Button>
        ))}
      </Radio.Group>
    </Form.Item>
  );
};

export default NpsRadioFormElement;
