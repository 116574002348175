import React from 'react';
import ReactMarkdown from 'react-markdown';

const WorkflowActivitySubtitleSmall: React.FunctionComponent = ({ children }) => (
  <h3 className="workflow-activity-subtitle-sm">
    <ReactMarkdown>{children as string}</ReactMarkdown>
  </h3>
);

export default WorkflowActivitySubtitleSmall;
