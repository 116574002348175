import React from 'react';

const SplitWorkflowFormFilled: React.FunctionComponent = (props) => {
  const { children } = props;

  return (
    <div className="split-workflow-form-container-filled">
      <div className="split-workflow-form-container-content-container">
        <div className="split-workflow-form-container-content">
          {children}
        </div>
      </div>
    </div>
  );
};

export default SplitWorkflowFormFilled;